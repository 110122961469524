import React, { memo, useCallback, useEffect, useState, VFC } from "react";
import TransitionModal from "../../atoms/TransitionModal";
import {
  PieChart,
  Pie,
  Sector,
  ResponsiveContainer,
  Cell,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Legend,
} from "recharts";
import ColorGradation from "../../../functions/ColorGradation";
import { useApiGetWithCache } from "../../../hooks/api/useApiGetWithCache";
import { Box, Grid, Typography } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import CentralizedLoading from "../../atoms/CentralizedLoading";

interface Props {
  open: boolean;
  handleClose: () => void;
  headerID: number;
}

const StandardDeviationModel: VFC<Props> = memo((props) => {
  const [stdevData, setStdevData] = useState<
    Paths.GetStdevResults.Responses.$200["std_dev_results"]
  >([]);
  const [amount, setAmount] = useState<
    Paths.GetStdevResults.Responses.$200["total_amount"]
  >({ before: 0, after: 0 });
  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = useCallback(
    (_: any, index: number) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );
  const { data, loading } =
    useApiGetWithCache<Paths.GetStdevResults.Responses.$200>(
      "stm",
      "std_devs",
      {},
      String(props.headerID)
    );
  useEffect(() => {
    if (data) {
      setStdevData(data.std_dev_results ?? []);
      setAmount(data.total_amount);
    }
  }, [data]);

  const colors = ColorGradation(stdevData.length);
  const pieData = stdevData.map((v, i) => ({
    ...v,
    color: colors[i],
    name: (v.ratio / 100).toFixed(2),
  }));

  const columns: GridColDef[] = [
    {
      field: "item",
      type: "string",
      headerName: "Item",
      sortable: false,
      headerAlign: "center",
      width: 120,
    },
    {
      field: "description",
      type: "string",
      headerName: "Description",
      sortable: false,
      headerAlign: "center",
      width: 320,
    },
    {
      field: "ratio",
      type: "string",
      headerName: "Ratio",
      sortable: false,
      width: 70,
      align: "center",
      valueGetter: (value) => (Number(value) / 100).toFixed(2),
      headerAlign: "center",
    },
    {
      field: "qty",
      type: "string",
      headerName: "Qty",
      sortable: false,
      width: 120,
      align: "center",
      valueGetter: (value, row) =>
        `${row.qty_before} → ${row.qty_after}`,
      headerAlign: "center",
    },
    {
      field: "price",
      type: "string",
      headerName: "Price",
      sortable: false,
      width: 70,
      align: "center",
      valueGetter: (value) => Number(value).toFixed(2),
      headerAlign: "center",
    },
    {
      field: "amount_difference",
      type: "string",
      headerName: "Amount",
      sortable: false,
      width: 100,
      align: "center",
      valueGetter: (value) =>
        Number(value).toLocaleString("en-US", {
          minimumFractionDigits: 2,
        }),
      headerAlign: "center",
    },
  ];

  return (
    <TransitionModal
      open={props.open}
      handleClose={props.handleClose}
      title={"Standard Deviation Analysis"}
    >
      {loading ? (
        <CentralizedLoading />
      ) : (
        <>
          <Grid
            justifyContent="center"
            sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
          >
            <Typography align="center" fontSize={32}>
              Total Amount Difference{" "}
              {(amount.after - amount.before).toLocaleString()}
            </Typography>
            {amount.after - amount.before > 0 ? (
              <>
                <Typography color="rgba(0,204,102,0.8)">
                  (
                  {(
                    ((amount.after - amount.before) / amount.before) *
                    100
                  ).toFixed(2)}
                  %)
                </Typography>
                <ArrowUpwardIcon sx={{ color: "rgba(0,204,102,0.8)" }} />
              </>
            ) : (
              <>
                <Typography fontSize={24} color="rgba(244,10,10,0.8)">
                  (
                  {(
                    (Math.abs(amount.after - amount.before) / amount.before) *
                    100
                  ).toFixed(2)}
                  %)
                </Typography>
                <ArrowDownwardIcon sx={{ color: "rgba(244,10,10,0.8)" }} />
              </>
            )}
          </Grid>
          <Typography align="center" color="gray" paddingTop={0}>
            {amount.before.toLocaleString()} → {amount.after.toLocaleString()}
          </Typography>
          <Grid container direction="row" minHeight="50vh">
            <Grid item md={12} minHeight={500}>
              <ResponsiveContainer width="100%" height="100%">
                <PieChart width={500} height={500}>
                  <Pie
                    activeIndex={activeIndex}
                    activeShape={renderActiveShape}
                    data={pieData}
                    cx="50%"
                    cy="50%"
                    innerRadius={120}
                    outerRadius={160}
                    dataKey="count"
                    onMouseEnter={onPieEnter}
                  >
                    {pieData.map((_, index) => (
                      <Cell key={`cell-${index}`} fill={colors[index]} />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </Grid>
            <Grid item md={12} height={400}>
              <Typography>Ratio Histogram</Typography>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  width={500}
                  height={300}
                  data={pieData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 30,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="count" fill="rgba(0,140,162,0.8)" name="Count">
                    {pieData.map((_, index) => (
                      <Cell key={`cell-${index}`} fill={colors[index]} />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Grid>
          </Grid>
          <Box height="40vh" py={2}>
            <Typography>Effective 10 Items</Typography>
            <DataGridPro
              columns={columns}
              rows={data?.effective_items ?? []}
              getRowId={(row) => row.item}
              disableColumnMenu
              hideFooter
              loading={loading}
            />
          </Box>
        </>
      )}
    </TransitionModal>
  );
});

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    payload,
    value,
    amount_difference,
    percent,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text
        x={cx}
        y={cy}
        dy={8}
        textAnchor="middle"
        fill={payload.color}
        fontSize={32}
      >
        Ratio {(payload.ratio / 100).toFixed(2)}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={payload.color}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={payload.color}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={payload.color}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={payload.color} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill={payload.color}
      >{`Count ${value}(${(percent * 100).toFixed(2)}%)`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill={payload.color}
      >
        {`Amt Diff ${amount_difference.toLocaleString()}`}
      </text>
    </g>
  );
};
export default StandardDeviationModel;
