import { memo, VFC, useState, useEffect } from "react";
import {
  Bar,
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
interface Props {
  air: number;
  sea: number;
  detail: Paths.GetStockMovementDetails.Responses.$200["details"][0];
  monthHeaders: string[];
}

type ModalStockChartType = {
  month: string;
  sales: number;
  purchase?: number;
  additionalPurchase?: number;
  stock?: number;
  stockMonth?: number;
};

const ModalStockChart: VFC<Props> = memo((props) => {
  const { monthHeaders, detail } = props;
  const currentMonth = monthHeaders[0];
  const [chartData, setChartData] = useState<ModalStockChartType[]>([]);
  useEffect(() => {
    setChartData(createChartData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail.sales_forecast, detail.purchase_forecast, props.air, props.sea]);
  const createChartData = () => {
    const forecast: ModalStockChartType[] = detail.sales_forecast?.map(
      (s, i) => {
        const stockForecast = detail.stock_forecast.map((s, i) =>
          i >= detail.ltm_sea
            ? s + props.air + props.sea
            : i >= detail.ltm_air
            ? s + props.air
            : s
        );
        return {
          month: monthHeaders[i],
          sales: s,
          purchase: detail.purchase_forecast[i],
          additionalPurchase:
            i === detail.ltm_air
              ? props.air
              : i === detail.ltm_sea
              ? props.sea
              : undefined,
          stock: detail.standard_stock[i],
          stockMonth:
            Math.round((stockForecast[i] / detail.standard_stock[i]) * 10) / 10,
        };
      }
    );
    return forecast;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };
  return (
    <ModalStockChartPresentation
      data={chartData}
      minimumStockMonth={detail.msm_sea || 0}
      currentMonth={currentMonth}
    />
  );
});

interface PresentationProps {
  data: ModalStockChartType[];
  minimumStockMonth: number;
  currentMonth: string;
}

const ModalStockChartPresentation: VFC<PresentationProps> = (props) => {
  return (
    <>
      <ResponsiveContainer width="100%" minHeight={300}>
        <ComposedChart
          data={props.data}
          margin={{
            top: 5,
            right: 5,
            left: 0,
            bottom: 15,
          }}
          syncId="stock2"
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" xAxisId={0} />
          <XAxis dataKey="month" xAxisId={1} hide />
          <YAxis />
          <Tooltip
            formatter={(value: number) =>
              new Intl.NumberFormat(undefined, {
                maximumFractionDigits: 0,
              }).format(+value)
            }
          />
          <Bar
            xAxisId={0}
            name="Stock"
            dataKey="stock"
            barSize={25}
            fill="#0930B0"
            opacity="0.8"
          />
          <Bar
            xAxisId={1}
            stackId="s"
            name="Purchase"
            dataKey="purchase"
            barSize={25}
            fill="#94BBD6"
          />
          <Bar
            xAxisId={1}
            stackId="s"
            name="Additional Purchase"
            dataKey="additionalPurchase"
            barSize={25}
            fill="#ED911A"
            opacity="0.4"
          />
          <Line
            xAxisId={0}
            name="Sales"
            type="linear"
            dataKey="sales"
            stroke="#E0385D"
            strokeWidth={3}
            activeDot={{ r: 9 }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </>
  );
};

export default ModalStockChart;
