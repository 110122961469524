import { Box, CardContent, Grid, Typography } from "@mui/material";
import React, { memo, VFC } from "react";
interface Props {
  header: Paths.GetStockMovementDetails.Responses.$200["header"];
}
interface headerCard {
  fields: field[];
}

interface field {
  label: string;
  values: value[];
  hide: boolean;
}

interface value {
  value: string | number;
  subLabel?: string;
  align?: "right" | "left";
}

const HeaderInformation: VFC<Props> = memo((props) => {
  const headerModels = headerToModel(props.header);

  return (
    <Grid container spacing={0} marginBottom={-3} marginTop={-3}>
      {headerModels.map((card, i) => (
        <Grid item xs={6} md={4} key={`header-grid-${i}`}>
          <CardContent sx={{ p: 0 }}>
            {card.fields.map((field, j) =>
              field.hide ? (
                <React.Fragment key={`card-${i}-${j}`}></React.Fragment>
              ) : (
                <React.Fragment key={`card-${i}-${j}`}>
                  <Box
                    sx={{
                      borderRadius: "5px",
                      p: 1,
                      m: 1,
                      boxShadow: 2,
                      borderLeft: 1,
                      borderWidth: 10,
                      borderColor: "primary.main",
                    }}
                  >
                    <Typography
                      fontWeight="bold"
                      sx={{ textDecoration: "underline" }}
                    >
                      {field.label}
                    </Typography>
                    {field.values.map((value, k) => (
                      <React.Fragment key={`field-${i}-${j}-${k}`}>
                        {value.subLabel && (
                          <Typography
                            ml={1}
                            color="#3D332C"
                            sx={{ fontSize: 12 }}
                          >
                            {value.subLabel}
                          </Typography>
                        )}
                        <Typography
                          ml={2}
                          noWrap
                          sx={{ fontSize: 14 }}
                          fontWeight="bold"
                          align={value.align}
                        >
                          {value.value}
                        </Typography>
                      </React.Fragment>
                    ))}
                  </Box>
                </React.Fragment>
              )
            )}
          </CardContent>
        </Grid>
      ))}
    </Grid>
  );
});

const headerToModel = (
  header: Paths.GetStockMovementDetails.Responses.$200["header"]
): headerCard[] => {
  return [
    {
      fields: [
        {
          label: "Planner Code",
          values: [
            {
              value: header.planner_code,
            },
          ],
          hide: false,
        },
        {
          label: "Vendor",
          values: [
            {
              value: header.vendor,
            },
          ],
          hide: false,
        },
        {
          label: "Only SEA",
          values: [
            {
              value: header.only_sea ? "Yes" : "No",
            },
          ],
          hide: false,
        },
        {
          label: "Item",
          values: [
            {
              value: header.item || "-",
            },
          ],
          hide: false,
        },
        {
          label: "Only BO",
          values: [
            {
              value: header.only_bo ? "Yes" : "No",
            },
          ],
          hide: false,
        },
      ],
    },
    {
      fields: [
        {
          label: "Alternate",
          values: [
            {
              value: header.alternates ? "Yes" : "No",
            },
          ],
          hide: false,
        },
        {
          label: "Price Range",
          values: [
            {
              subLabel: "From",
              value: header.price_from || "-",
            },
            {
              subLabel: "To",
              value: header.price_to || "-",
            },
          ],
          hide: false,
        },
        {
          label: "3M vs 6M Difference",
          values: [
            {
              value: header.sales_ratio ? header.sales_ratio + "%" : "-",
            },
          ],
          hide: false,
        },
        {
          label: "Standard Deviation",
          values: [
            {
              value: header.standard_deviation ? "Yes" : "No",
            },
          ],
          hide: false,
        },
      ],
    },
    {
      fields: [
        {
          label: "AIR Weight",
          values: [
            {
              value:
                header.air_weight_summary.toLocaleString(undefined, {
                  minimumFractionDigits: 3,
                }) || 0,
              align: "right",
            },
          ],
          hide: !!header.only_sea,
        },
        {
          label: "AIR Amount",
          values: [
            {
              value:
                header.air_amount_summary.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                }) || 0,
              align: "right",
            },
          ],
          hide: !!header.only_sea,
        },
        {
          label: "SEA Amount",
          values: [
            {
              value:
                header.sea_amount_summary.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                }) || 0,
              align: "right",
            },
          ],
          hide: false,
        },
        {
          label: "Total Amount",
          values: [
            {
              value:
                (
                  header.sea_amount_summary + header.air_amount_summary
                ).toLocaleString(undefined, { minimumFractionDigits: 2 }) || 0,
              align: "right",
            },
          ],
          hide: false,
        },
      ],
    },
  ];
};

export default HeaderInformation;
