import { useApiPost } from "./useApiPost";

export interface OcfForecast {
  air?: number;
  sea?: number;
}

export const useUpdateOcfForecast = (id: string) => {
  const postRequestAsync = useApiPost<Paths.UpdateOcfForecast.Responses.$200>(
    "ocf_forecast",
    id
  );

  const UpdateOcfForecastAsync = async (
    body: Paths.UpdateOcfForecast.Parameters.OcfForecast
  ): Promise<Paths.UpdateOcfForecast.Responses.$200> => {
    return postRequestAsync({ body });
  };
  return UpdateOcfForecastAsync;
};
