import React from "react";
import { Fab, Grid } from "@mui/material";
import { useMemo, useState } from "react";
import { useApiGetWithCache } from "../../hooks/api/useApiGetWithCache";
import { useSWRConfig } from "swr";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import AlternateTable from "../organisms/AlternateMaintenance/AlternateTable";
import UploadAlternate from "../organisms/AlternateMaintenance/UploadAlternate";
import AlternateForm from "../organisms/AlternateMaintenance/AlternateForm";

const AlternateMaintenance = () => {
  const [alternateId, setAlternateId] = useState(0);
  const { data, loading } = useApiGetWithCache<Paths.GetAlternates.Responses.$200>(
    "stm",
    "alternates",
    { swrOption: { refreshInterval: 4000 } }
  );
  const targetAlternate = useMemo(
    () =>
      data?.alternates?.find((f) => f.id === alternateId) || {
        id: 0,
        from_item: "",
        from_qty: 1,
        to_item: "",
        to_qty: 1,
        created: "",
      },
    [data, alternateId]
  );
  const [mode, setMode] = useState<"update" | "create">("update");
  const { mutate } = useSWRConfig();
  const onClickRow = (id: number) => {
    setAlternateId(id);
    setMode("update");
  };
  const onClickCreate = () => {
    setAlternateId(0);
    setMode("create");
  };
  const handleRefresh = () => {
    mutate("/stm/alternates");
  };
  return (
    <>
      <UploadAlternate />
      <Grid container spacing={1}>
        <Grid item xs={12} md={7} maxHeight={788}>
          <AlternateTable alternates={data?.alternates} loading={loading} onClickRow={onClickRow} handleRefresh={handleRefresh}/>
        </Grid>
        <Grid item xs={12} md={5}>
          <AlternateForm mode={mode} alternate={targetAlternate} alternates={data?.alternates} handleRefresh={handleRefresh}/>
        </Grid>
      </Grid>
      <Fab
        color="primary"
        aria-label="new"
        sx={{
          position: "fixed",
          bottom: "96px",
          right: "16px",
        }}
        onClick={() => onClickCreate()}
      >
        <AddBusinessIcon />
      </Fab>
    </>
  );
};

export default AlternateMaintenance;
