import {
  DataGridPro,
  GridToolbar,
  GridActionsCellItem,
  GridRowParams,
  GridRowsProp,
  GridColDef,
  GridCellParams,
} from "@mui/x-data-grid-pro";
import React, { memo, useCallback, useMemo, useState, VFC } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteUserDialog from "../../molecules/UserMaintenance/DeleteUserDialog";
import { Box } from "@mui/material";
import clsx from "clsx";
import { useRecoilState } from "recoil";
import { UserState } from "../../../stores/UserState";
import { CustomGridStringOperators } from "../../atoms/CustomDataGridOperators";

interface Props {
  users: Paths.GetMasterdata.Responses.$200["users"];
  onClickRow: (sub: string) => void;
  handleRefresh: () => void;
}

interface dialog {
  open: boolean;
  user: {
    sub: string;
    givenName: string;
    familyName: string;
  };
}

const UserList: VFC<Props> = memo(({ users, onClickRow, handleRefresh }) => {
  const [deleteDialog, setDeleteDialog] = useState<dialog>({
    open: false,
    user: { sub: "", givenName: "", familyName: "" },
  });
  const [user] = useRecoilState(UserState);
  const handleDelete = (dialog: dialog) => {
    setDeleteDialog(dialog);
  };
  const handleClose = () =>
    setDeleteDialog((prev) => {
      const next = { ...prev };
      next.open = false;
      return next;
    });
  const rows: GridRowsProp = useMemo(() => users, [users]);
  const columns: GridColDef[] = useMemo(
    () => [
      { field: "sub", hideable: false, disableExport: true },
      {
        field: "company_code",
        headerName: "Company",
        headerAlign: "left",
        align: "center",
        width: 75,
        filterOperators: CustomGridStringOperators,
      },
      {
        field: "is_user_update",
        headerName: "MJ",
        headerAlign: "center",
        align: "center",
        width: 30,
        valueGetter: (value, row) =>
          row.is_user_update ? "Y" : "",
        cellClassName: (params: GridCellParams<any, string>) =>
          clsx("is-alert", {
            positive:
              params.row.is_user_update && params.row.company_code !== "MJ",
          }),
      },
      {
        field: "name",
        headerName: "Name",
        valueGetter: (value, row): string =>
          `${row.given_name} ${row.family_name}`,
        minWidth: 250,
        filterOperators: CustomGridStringOperators,
      },
      { field: "email", headerName: "Email", minWidth: 220, filterOperators: CustomGridStringOperators, },
      {
        field: "regions",
        headerAlign: "left",
        headerName: "Regions",
        width: 75,
        filterOperators: CustomGridStringOperators,
      },
      {
        field: "primary_region",
        headerAlign: "left",
        headerName: "P.Region",
        width: 75,
        filterOperators: CustomGridStringOperators,
      },
      {
        field: "actions",
        type: "actions",
        width: 40,
        disableExport: true,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() =>
              handleDelete({
                open: true,
                user: {
                  sub: params.id.toString(),
                  givenName: params.row.given_name,
                  familyName: params.row.family_name,
                },
              })
            }
          />,
        ],
      },
    ],
    []
  );
  const handleRowClick = useCallback((id: GridRowParams["id"]) => {
    onClickRow(id.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DeleteUserDialog
        open={deleteDialog.open}
        handleClose={handleClose}
        user={deleteDialog.user}
        handleRefresh={handleRefresh}
      />
      <Box
        style={{ height: "100%", width: "100%" }}
        sx={{
          // Cell color setting
          "& .is-alert.positive": {
            fontWeight: "bold",
            color:"rgb(255, 0, 0)",
          },
          "& .is-login-user": {
            backgroundColor: "rgba(255, 99, 71, 0.1)",
            fontWeight: "bold",
          },
        }}
      >
        <DataGridPro
          style={{ fontSize: 11 }}
          rows={rows}
          rowHeight={30}
          columns={columns}
          hideFooter={true}
          getRowId={(row) => row.sub}
          onRowClick={(params: GridRowParams) => handleRowClick(params.id)}
          slots={{
            toolbar: GridToolbar,
          }}
          density="compact"
          initialState={{
            columns: {
              columnVisibilityModel: { sub: false },
            },
            filter: {
              filterModel: {
                items: [{ field: "company_code", operator: "equals", value: "" }],
              },
            },
          }}
          getRowClassName={(params) =>
            user?.sub === params.row.sub ? "is-login-user" : ""
          }
        />
      </Box>
    </>
  );
});

export default UserList;
