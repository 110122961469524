
import { atom } from "recoil";

export type SelectRow = {
  rowId: number;
  field: string;
};

export const SelectRowState = atom<SelectRow>({
  key: "SelectRowState",
  default: { rowId:1, field: "vendor" },
});
