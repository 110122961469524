import { Grid, InputAdornment, TextField } from "@mui/material";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import ConstructionIcon from "@mui/icons-material/Construction";
import React, { VFC } from "react";
import { useApiGetWithCache } from "../../../hooks/api/useApiGetWithCache";
import SuggestQtyLogs from "./SuggestQtyLogs";
import PaperTooltip from "../../atoms/PaperTooltip";

interface suggestQty {
  qty: number;
  dirty: boolean;
}

interface Props {
  headerId: string;
  vendor: string;
  item: string;
  id: number;
  air: suggestQty;
  setAir: (v: suggestQty) => void;
  sea: suggestQty;
  setSea: (v: suggestQty) => void;
  inputSale: string;
  setInputSaleWrapper: (v: string) => void;
  price: number;
  isOnlySea: boolean;
}

const ModalSuggestQtyForm: VFC<Props> = (props) => {
  const { data } = useApiGetWithCache<Paths.GetSuggestQtyLogs.Responses.$200>(
    "stm",
    "suggest_quantities",
    {},
    `${props.headerId}/${props.vendor}/${props.item}`,
    props.vendor !== undefined && props.item !== undefined
  );
  return (
    <Grid paddingX={2} container direction="column">
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <TextField
            key={props.id}
            type="number"
            label="Sales"
            value={props.inputSale}
            onChange={(e) => props.setInputSaleWrapper(e.target.value)}
            onClick={(e) => e.stopPropagation()}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <ConstructionIcon sx={{ color: "rgba(128,128,128,0.25)" }} />
                </InputAdornment>
              ),
            }}
            inputProps={{
              min: 0,
              style: {
                textAlign: "right",
              },
            }}
            variant="standard"
          />
        </Grid>
        <Grid item xs={4}>
          <PaperTooltip
            title={<SuggestQtyLogs logs={data} type={"AIR"} />}
            arrow
            placement="left"
          >
            <TextField
              key={props.id}
              type="number"
              label="AIR Suggested Qty"
              value={props.air.qty}
              disabled={props.isOnlySea}
              onChange={(e) =>
                props.setAir({ qty: parseInt(e.target.value), dirty: true })
              }
              onClick={(e) => e.stopPropagation()}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AirplanemodeActiveIcon
                      sx={{ color: "rgba(0,0,200,0.2)" }}
                    />
                  </InputAdornment>
                ),
              }}
              inputProps={{
                min: 0,
                style: {
                  textAlign: "right",
                },
              }}
              variant="standard"
            />
          </PaperTooltip>
        </Grid>

        <Grid item xs={4}>
          <PaperTooltip
            title={<SuggestQtyLogs logs={data} type={"SEA"} />}
            arrow
            placement="right"
          >
            <TextField
              key={props.id}
              type="number"
              label="SEA Suggested Qty"
              value={props.sea.qty}
              onChange={(e) =>
                props.setSea({ qty: parseInt(e.target.value), dirty: true })
              }
              onClick={(e) => e.stopPropagation()}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <DirectionsBoatIcon sx={{ color: "rgba(0,200,0,0.25)" }} />
                  </InputAdornment>
                ),
              }}
              inputProps={{
                min: 0,
                style: {
                  textAlign: "right",
                },
              }}
              variant="standard"
            />
          </PaperTooltip>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ModalSuggestQtyForm;
