import { VFC, memo } from "react";
import {
  LineChart,
  lineElementClasses,
  markElementClasses,
} from "@mui/x-charts/LineChart";

interface Props {
  month: string[];
  qty: number[];
}

const LineChartCell: VFC<Props> = memo(({ month, qty }) => {
  // qtyが全て０の場合はfalse
  const isQtyZero = qty.every((v) => v === 0);
  return (
    <LineChart
      xAxis={[{ scaleType: "point", data: month }]}
      yAxis={[
        {
          min: 0,
          max: isQtyZero ? 1 : Math.max(...qty),
          scaleType: "linear",
        },
      ]}
      series={[
        {
          data: qty,
          label: "qty",
        },
      ]}
      grid={{ horizontal: true }}
      margin={{ left: 40, right: 10, top: 20, bottom: 20 }}
      legend={{ hidden: true }}
      sx={{
        [`& .${lineElementClasses.root}`]: {
          stroke: "#047d95",
          strokeWidth: 1,
        },
        [`& .${markElementClasses.root}`]: {
          stroke: "#047d95",
          scale: "0.5",
          fill: "#fff",
          strokeWidth: 1,
        },
      }}
    />
  );
});

export default LineChartCell;
