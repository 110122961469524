
import { LocalStorageGet } from "./LocalStorageGet";
import { LocalStorageSet } from "./LocalStorageSet";
import { LocalStorageDelete } from "./LocalStorageDelete";

const key = "web-stm-detailKeys-storage213vfv2";

export const StmDetailKeysSet = (keys:string[]) => {
  LocalStorageSet({ key: key, obj: keys });
};

export const StmDetailKeysGet = () => {
  return LocalStorageGet<string[]>(key);
};

export const StmDetailKeysReset = () => {
  LocalStorageDelete(key);
}