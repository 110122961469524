import { Box } from "@mui/material";
import { memo, ReactNode, useEffect, VFC } from "react";
import { useRecoilState } from "recoil";
import { useApiGetWithCache } from "../../hooks/api/useApiGetWithCache";
import { SnackbarState } from "../../stores/SnackbarState";
import { UserState } from "../../stores/UserState";
import ResponseSnackBar from "../atoms/ResponseSnackBar";
import AppBar from "../organisms/AppBar";

type Props = {
  children: ReactNode;
};

export const HeaderLayout: VFC<Props> = memo((props) => {
  const { data } = useApiGetWithCache<Paths.GetCurrentUser.Responses.$200>(
    "stm",
    "user",
    {}
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setUser] = useRecoilState(UserState);
  useEffect(() => {
    setUser(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const [snackbar] = useRecoilState(SnackbarState);

  return (
    <>
      <AppBar />
      <Box>
        <Box
          sx={{
            mx: "auto",
            mt: 10,
            width: "95%",
            height: "calc(100vh - 150px)",
          }}
        >
          {props.children}
        </Box>
      </Box>
      <ResponseSnackBar {...snackbar} />
    </>
  );
});
