import React, { VFC } from "react";
import {
  AmplifyProvider,
  View,
  withAuthenticator,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { Router } from "./router/Router";
import { RecoilRoot } from "recoil";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { CssBaseline } from "@mui/material";
import { ReactComponent as Logo } from "./svg/makita_logo.svg";

LicenseInfo.setLicenseKey(
  "5e5cf57e2eac04edb5c48551af26ad2eTz03NzI0OCxFPTE3Mjk3MzAzMTIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

const theme = createTheme({
  palette: {
    background: {
      default: "#FAFAFA",
    },
    primary: {
      main: "#008CA2",
    },
  },
});

const App: VFC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AmplifyProvider>
        <RecoilRoot>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </RecoilRoot>
      </AmplifyProvider>
    </ThemeProvider>
  );
};

const components = {
  SignIn: {
    Header() {
      return (
        <View textAlign="center">
          <Logo />
        </View>
      );
    },
  },
};

export default withAuthenticator(App, { components });
