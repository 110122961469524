import { Box } from "@mui/system";
import S1 from "../../manuals/JP/スライド1.png";
import S2 from "../../manuals/JP/スライド2.png";
import S3 from "../../manuals/JP/スライド3.png";
import S4 from "../../manuals/JP/スライド4.png";
import S5 from "../../manuals/JP/スライド5.png";
import S6 from "../../manuals/JP/スライド6.png";
import S7 from "../../manuals/JP/スライド7.png";
import S8 from "../../manuals/JP/スライド8.png";
import S9 from "../../manuals/JP/スライド9.png";
import S10 from "../../manuals/JP/スライド10.png";
import S11 from "../../manuals/JP/スライド11.png";
import S12 from "../../manuals/JP/スライド12.png";
import S13 from "../../manuals/JP/スライド13.png";
import S14 from "../../manuals/JP/スライド14.png";
import S15 from "../../manuals/JP/スライド15.png";
import S16 from "../../manuals/JP/スライド16.png";
import S17 from "../../manuals/JP/スライド17.png";
import S18 from "../../manuals/JP/スライド18.png";
import S19 from "../../manuals/JP/スライド19.png";
import S20 from "../../manuals/JP/スライド20.png";
import S21 from "../../manuals/JP/スライド21.png";
import S22 from "../../manuals/JP/スライド22.png";
import S23 from "../../manuals/JP/スライド23.png";
import S24 from "../../manuals/JP/スライド24.png";
import S25 from "../../manuals/JP/スライド25.png";
import S26 from "../../manuals/JP/スライド26.png";
import S27 from "../../manuals/JP/スライド27.png";
import S28 from "../../manuals/JP/スライド28.png";
import S29 from "../../manuals/JP/スライド29.png";
import S30 from "../../manuals/JP/スライド30.png";
import S31 from "../../manuals/JP/スライド31.png";
import S32 from "../../manuals/JP/スライド32.png";
import S33 from "../../manuals/JP/スライド33.png";
import S34 from "../../manuals/JP/スライド34.png";
import S35 from "../../manuals/JP/スライド35.png";
import S36 from "../../manuals/JP/スライド36.png";
import S37 from "../../manuals/JP/スライド37.png";
import S38 from "../../manuals/JP/スライド38.png";
import S39 from "../../manuals/JP/スライド39.png";
import S40 from "../../manuals/JP/スライド40.png";
import S41 from "../../manuals/JP/スライド41.png";
import S42 from "../../manuals/JP/スライド42.png";
import S43 from "../../manuals/JP/スライド43.png";
import S44 from "../../manuals/JP/スライド44.png";
import S45 from "../../manuals/JP/スライド45.png";
import S46 from "../../manuals/JP/スライド46.png";
import S47 from "../../manuals/JP/スライド47.png";
import S48 from "../../manuals/JP/スライド48.png";
import S49 from "../../manuals/JP/スライド49.png";

const ManualJP = () => {
  const imagesList = [
    {
      id: 1,
      src: S1,
      alt: "s1",
    },
    {
      id: 2,
      src: S2,
      alt: "s2",
    },
    {
      id: 3,
      src: S3,
      alt: "s3",
    },
    {
      id: 4,
      src: S4,
      alt: "s4",
    },
    {
      id: 5,
      src: S5,
      alt: "s5",
    },
    {
      id: 6,
      src: S6,
      alt: "s6",
    },
    {
      id: 7,
      src: S7,
      alt: "s7",
    },
    {
      id: 8,
      src: S8,
      alt: "s8",
    },
    {
      id: 9,
      src: S9,
      alt: "s9",
    },
    {
      id: 10,
      src: S10,
      alt: "s10",
    },
    {
      id: 11,
      src: S11,
      alt: "s11",
    },
    {
      id: 12,
      src: S12,
      alt: "s12",
    },
    {
      id: 13,
      src: S13,
      alt: "s13",
    },
    {
      id: 14,
      src: S14,
      alt: "s14",
    },
    {
      id: 15,
      src: S15,
      alt: "s15",
    },
    {
      id: 16,
      src: S16,
      alt: "s16",
    },
    {
      id: 17,
      src: S17,
      alt: "s17",
    },
    {
      id: 18,
      src: S18,
      alt: "s18",
    },
    {
      id: 19,
      src: S19,
      alt: "s19",
    },
    {
      id: 20,
      src: S20,
      alt: "s20",
    },
    {
      id: 21,
      src: S21,
      alt: "s21",
    },
    {
      id: 22,
      src: S22,
      alt: "s22",
    },
    {
      id: 23,
      src: S23,
      alt: "s23",
    },
    {
      id: 24,
      src: S24,
      alt: "s24",
    },
    {
      id: 25,
      src: S25,
      alt: "s25",
    },
    {
      id: 26,
      src: S26,
      alt: "s26",
    },
    {
      id: 27,
      src: S27,
      alt: "s27",
    },
    {
      id: 28,
      src: S28,
      alt: "s28",
    },
    {
      id: 29,
      src: S29,
      alt: "s29",
    },
    {
      id: 30,
      src: S30,
      alt: "s30",
    },
    {
      id: 31,
      src: S31,
      alt: "s31",
    },
    {
      id: 32,
      src: S32,
      alt: "s32",
    },
    {
      id: 33,
      src: S33,
      alt: "s33",
    },
    {
      id: 34,
      src: S34,
      alt: "s34",
    },
    {
      id: 35,
      src: S35,
      alt: "s35",
    },
    {
      id: 36,
      src: S36,
      alt: "s36",
    },
    {
      id: 37,
      src: S37,
      alt: "s37",
    },
    {
      id: 38,
      src: S38,
      alt: "s38",
    },
    {
      id: 39,
      src: S39,
      alt: "s39",
    },
    {
      id: 40,
      src: S40,
      alt: "s40",
    },
    {
      id: 41,
      src: S41,
      alt: "s41",
    },
    {
      id: 42,
      src: S42,
      alt: "s42",
    },
    {
      id: 43,
      src: S43,
      alt: "s43",
    },
    {
      id: 44,
      src: S44,
      alt: "s44",
    },
    {
      id: 45,
      src: S45,
      alt: "s45",
    },
    {
      id: 46,
      src: S46,
      alt: "s46",
    },
    {
      id: 47,
      src: S47,
      alt: "s47",
    },
    {
      id: 48,
      src: S48,
      alt: "s48",
    },
    {
      id: 49,
      src: S49,
      alt: "s49",
    },
  ];
  return (
    <>
      {imagesList.map((image) => (
        <Box textAlign={"center"}>
          <img key={image.id} src={image.src} alt={image.alt} />
        </Box>
      ))}
      ;
    </>
  );
};

export default ManualJP;
