import {
  DataGridPro,
  GridColDef,
  GridRowParams,
  GridRowsProp,
} from "@mui/x-data-grid-pro";
import { memo, useCallback, useMemo, VFC } from "react";

interface Props {
  companies: Paths.GetMasterdata.Responses.$200["companies"];
  onClickRow: (sub: string) => void;
}
const CompanyList: VFC<Props> = memo(({ companies, onClickRow }) => {
  const rows: GridRowsProp = useMemo(() => companies!, [companies]);
  const columns: GridColDef[] = [
    { field: "code", headerName: "Company Code", minWidth: 150 },
    { field: "name", headerName: "Name", minWidth: 200 },
    {
      field: "is_admin",
      headerName: "Administrator",
      type: "boolean",
      minWidth: 120,
      valueGetter: (value) => (value ? "○" : ""),
    },
  ];
  const handleRowClick = useCallback((id: GridRowParams["id"]) => {
    onClickRow(id.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DataGridPro
        rows={rows}
        rowHeight={30}
        columns={columns}
        hideFooter={true}
        getRowId={(row) => row.code}
        onRowClick={(params: GridRowParams) => handleRowClick(params.id)}
      />
    </>
  );
});

export default CompanyList;
