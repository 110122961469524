import { Alert, Slide, SlideProps, Snackbar } from "@mui/material";
import React, { VFC } from "react";
import { useRecoilState } from "recoil";
import { SnackbarModel } from "../../models/util/snackbar_model";
import { SnackbarState } from "../../stores/SnackbarState";

type TransitionProps = Omit<SlideProps, "direction">;
const Transition = (props: TransitionProps) => {
  return <Slide {...props} direction="up" />;
};

const ResponseSnackBar: VFC<SnackbarModel> = (props) => {
  const [snackbar, setSnackbar] = useRecoilState(SnackbarState);
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };
  return (
    <Snackbar
      open={props.open}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      TransitionComponent={Transition}
      onClose={handleClose}
    >
      <Alert severity={props.severity} sx={{ width: "100%" }}>
        {props.message}
      </Alert>
    </Snackbar>
  );
};

export default ResponseSnackBar;
