import { Fab, Grid } from "@mui/material";
import { useMemo, useState } from "react";
import { useApiGetWithCache } from "../../hooks/api/useApiGetWithCache";
import LoadingBackdrop from "../molecules/LoadingBackdrop";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import CompanyList from "../organisms/CompanyMaintenance/CompanyList";
import CompanyForm from "../organisms/CompanyMaintenance/CompanyForm";

const CompanyMaintenance = () => {
  const { data, loading } =
    useApiGetWithCache<Paths.GetMasterdata.Responses.$200>(
      "stm",
      "master_data",
      {}
    );
  const [code, setCode] = useState("");
  const [mode, setMode] = useState<"update" | "create">("update");
  const targetCompany = useMemo(
    () =>
      data?.companies?.find((f) => f.code === code) || {
        code: "",
        name: "",
        is_admin: false,
      },
    [data, code]
  );
  const onClickRow = (code: string) => {
    setCode(code);
    setMode("update");
  };
  const onClickCreate = () => {
    setCode("");
    setMode("create");
  };
  return loading || !data ? (
    <LoadingBackdrop loading={loading} />
  ) : (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6} minHeight={800}>
          <CompanyList companies={data!.companies} onClickRow={onClickRow} />
        </Grid>
        <Grid item xs={12} md={6}>
          <CompanyForm mode={mode} company={targetCompany} />
        </Grid>
      </Grid>
      <Fab
        color="primary"
        aria-label="new"
        sx={{
          position: "fixed",
          bottom: "96px",
          right: "16px",
        }}
        onClick={() => onClickCreate()}
      >
        <AddBusinessIcon />
      </Fab>
    </>
  );
};

export default CompanyMaintenance;
