import { API } from "aws-amplify";
import { ApiRequest } from "../../models/util/api_request_model";

type request = Omit<ApiRequest, "swrOption">;

const API_NAME = "apie413e623";

export const useApiGetAsync = <T>(
  basePath: "stm" | "makmate",
  path:
    | "create_po"
    | "stockmovement_details_report"
    | "parts"
    | "models"
    | "create_stm_excel"
) => {
  const fetchAsync = async (
    request: request,
    pathParameters?: string
  ): Promise<T> => {
    const url = pathParameters
      ? `/${basePath}/${path}/${pathParameters}`
      : `/${basePath}/${path}`;
    return (await API.get(API_NAME, url, request)) as Promise<T>;
  };
  return fetchAsync;
};
