import {
  GridColumnVisibilityModel,
  GridDensity,
  GridInitialState,
  GridRowId,
  GridScrollParams,
} from "@mui/x-data-grid-pro";
import { atom } from "recoil";

interface GridColumnWidth {
  field: string;
  width: number;
}

export type FilteredDetails = {
  visibleRowId: GridRowId[];
  gridState?: GridInitialState;
  scrollState?: GridScrollParams;
  columnVisibility?: GridColumnVisibilityModel;
  columnOrder?: { field: string; index: number }[];
  gridColumnWidth?: GridColumnWidth[];
  gridDensity?: GridDensity;
};

export const FilteredDetailsState = atom<FilteredDetails>({
  key: "FilteredDetails",
  default: { visibleRowId: [] },
});
