import { API } from "aws-amplify";
import { useCallback } from "react";
import { ApiRequest } from "../../models/util/api_request_model";

const API_NAME = "apie413e623";
const BASE_PATH = "stm";

export const useApiPut = <T>(path: "user" | "weight" | "alternate" , pathParameter?: string) => {
  const url = pathParameter
    ? `/${BASE_PATH}/${path}/${pathParameter}`
    : `/${BASE_PATH}/${path}`;

  const putRequestAsync = useCallback(
    async (request: ApiRequest) => {
      return API.put(API_NAME, url, request) as Promise<T>;
    },
    [url]
  );
  return putRequestAsync;
};
