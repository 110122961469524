import { Grid, Typography } from "@mui/material";
import React, { memo, useState, FC } from "react";
import LoadingBackdrop from "../../molecules/LoadingBackdrop";
import AirplaneIcon from "../../atoms/AirplaneIcon";
import DownloadPOForm from "../../molecules/StockMovementDetail/DownloadPOForm";
import BoatIcon from "../../atoms/BoatIcon";

interface Props {
  id: string;
  vendors: string[];
  isOnlySea: boolean;
}

const DownloadPO: FC<Props> = memo((props) => {
  const [loading, setLoading] = useState(false);
  const vendors = ["ALL", ...props.vendors];

  return (
    <>
      <Grid container spacing={0}  marginTop={-2} direction="column">
        <Typography fontWeight={"bold"}>Purchase Order Sheet</Typography>
        {/* only sea */}
        {!props.isOnlySea ? (
          <>
            <DownloadPOForm
              headerId={props.id}
              type="AIR"
              vendors={vendors}
              icon={<AirplaneIcon />}
              setLoading={setLoading}
            />
            <br />
          </>
        ) : (
          <></>
        )}
        <DownloadPOForm
          headerId={props.id}
          type="SEA"
          vendors={vendors}
          icon={<BoatIcon />}
          setLoading={setLoading}
        />
      </Grid>
      <LoadingBackdrop loading={loading} />
    </>
  );
});

export default DownloadPO;
