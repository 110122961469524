import { Grid } from "@mui/material";
import StandardDeviationCard from "../organisms/ParameterMaintenance/StandardDeviationCard";
import MachineSalesGrowthCard from "../organisms/ParameterMaintenance/MachineSalesGrowthCard";
import SalesAverageDifferenceCard from "../organisms/ParameterMaintenance/SalesAverageDifferenceCard";

const ParameterMaintenance = () => {
  return (
    <Grid container spacing={1}>
      <Grid item md={6} minHeight="50vh">
        <StandardDeviationCard />
      </Grid>
      <Grid item md={6} minHeight="50vh">
        <MachineSalesGrowthCard />
        <SalesAverageDifferenceCard />
      </Grid>
    </Grid>
  );
};

export default ParameterMaintenance;
