import React, { memo, useCallback, useState, FC } from "react";
import { useRecoilState } from "recoil";
import { useApiDelete } from "../../../hooks/api/useApiDelete";
import { SnackbarState } from "../../../stores/SnackbarState";
import LoadingBackdrop from "../LoadingBackdrop";
import SlideInDialog from "../SlideInDialog";

interface Props {
  open: boolean;
  handleClose: () => void;
  weight: weight;
  handleRefresh: () => void;
}
interface weight {
  weight_id: number;
  item: string;
}

const DeleteWeightDialog: FC<Props> = memo(({ open, handleClose, weight, handleRefresh }) => {
  const deleteWeightAsync = useApiDelete<Paths.DeleteWeight.Responses.$200>("weight", String(weight.weight_id));
  const [, setSnackbar] = useRecoilState(SnackbarState);
  const [loading, setLoading] = useState(false);
  const handleOk = useCallback(async () => {
    setLoading(true);
    handleClose();
    try {
      const res = await deleteWeightAsync({});
      setSnackbar({
        open: true,
        message: res.message,
        severity: res.code === 200 ? "success" : "error",
      });
    } catch (error) {
      console.error(error);
      setSnackbar({
        open: true,
        message: "[ERROR]Failed to delete weight...",
        severity: "error",
      });
      handleRefresh();
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weight.weight_id]);

  return (
    <>
      <LoadingBackdrop loading={loading} />
      <SlideInDialog
        open={open}
        handleClose={handleClose}
        title="Delete Weight for Item"
        content={`Are you sure want to delete weight for item ${weight.item} ?`}
        onClickCancel={handleClose}
        onClickOk={handleOk}
      />
    </>
  );
});

export default DeleteWeightDialog;
