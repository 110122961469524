import { 
    GridFilterOperator,
    getGridStringOperators,
    GridFilterInputValue,
    GridFilterItem,
    GridColDef
} from "@mui/x-data-grid-pro";


const escapeRegExp = (value: string): string => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }
const operationsOrder = ["contains", "notContains", "equals", "notEquals", "startsWith", "endsWith", "isEmpty", "isNotEmpty", "isAnyOf"];

const customGridStringOperators: GridFilterOperator<any, number | string | null, any>[] = [
    ...getGridStringOperators(),
    {
      label: 'not contains',
      value: 'notContains',
      getApplyFilterFn: (
        filterItem: GridFilterItem,
        column: GridColDef
      ) => {
        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
            return null;
          }
          const filterRegex = new RegExp(escapeRegExp(filterItem.value.trim()), 'i');
          return (value): boolean => {
            return value != null ? !filterRegex.test(String(value)) : false;
          };
      },
      InputComponent: GridFilterInputValue,
    },
    {
        label: 'not equals',
        value: 'notEquals',
        getApplyFilterFn: (filterItem: GridFilterItem) => {
          if (!filterItem.value) {
            return null;
          }
          const collator = new Intl.Collator(undefined, { sensitivity: 'base', usage: 'search' });
          return (value): boolean => {
            return value != null ? collator.compare(filterItem.value.trim(), value.toString()) !== 0 : false;
          };
        },
        InputComponent: GridFilterInputValue,
      },
  ];

  export const CustomGridStringOperators: GridFilterOperator<any, number | string | null, any>[] = customGridStringOperators.sort((x, y) => {
    return operationsOrder.indexOf(x.value) - operationsOrder.indexOf(y.value);
  });