import HeaderTable from "../organisms/StockMovementList/HeaderTable";
import Memorandum from "../organisms/StockMovementList/Memorandum";

const StockMovementList = () => {
  return (
    <>
      <Memorandum />
      <HeaderTable />
    </>
  );
};

export default StockMovementList;
