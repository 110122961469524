import { Grid, InputAdornment, TextField } from "@mui/material";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import React, { VFC } from "react";
import { useApiGetWithCache } from "../../../hooks/api/useApiGetWithCache";
import SuggestQtyLogs from "./SuggestQtyLogs";
import PaperTooltip from "../../atoms/PaperTooltip";
import { useRecoilState } from "recoil";
import { UserState } from "../../../stores/UserState";
import HasUpdateAuthority from "../../../functions/HasUpdateAuthority";

interface suggestQty {
  qty: number;
  dirty: boolean;
}
interface Props {
  headerId: string;
  vendor: string;
  item: string;
  id: number;
  air: suggestQty;
  setAir: (v: suggestQty) => void;
  savedAir: number;
  sea: suggestQty;
  setSea: (v: suggestQty) => void;
  savedSea: number;
  price: number;
  isOnlySea: boolean;
  isApplyGrowth: boolean;
}
const SuggestQtyForm: VFC<Props> = (props) => {
  const { data } = useApiGetWithCache<Paths.GetSuggestQtyLogs.Responses.$200>(
    "stm",
    "suggest_quantities",
    {},
    `${props.headerId}/${props.vendor}/${props.item}`,
    props.vendor !== undefined && props.item !== undefined
  );

  const [user] = useRecoilState(UserState);
  const hasUpdateAuth = HasUpdateAuthority(
    user?.is_user_update,
    user?.company_code
  );
  return (
    <Grid paddingX={2} container direction="column">
      <Grid container spacing={3}>
        <Grid item xs={4} md={2}>
          <PaperTooltip
            title={<SuggestQtyLogs logs={data} type={"AIR"} />}
            arrow
          >
            <TextField
              key={props.id}
              type="number"
              label="AIR Suggested Qty"
              value={props.air.qty}
              onChange={(e) =>
                props.setAir({ qty: parseInt(e.target.value), dirty: parseInt(e.target.value) !== props.savedAir })
              }
              disabled={props.isOnlySea}
              onClick={(e) => e.stopPropagation()}
              onKeyDown={(e) => e.stopPropagation()}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AirplanemodeActiveIcon
                      sx={{ color: "rgba(0,0,200,0.2)" }}
                    />
                  </InputAdornment>
                ),
                readOnly: hasUpdateAuth,
              }}
              inputProps={{
                min: 0,
                style: {
                  textAlign: "right",
                },
              }}
              //variant="standard"
              variant={hasUpdateAuth ? "filled" : "standard"}
            />
          </PaperTooltip>
        </Grid>

        <Grid item xs={4} md={2}>
          <TextField
            value={(props.air.qty * props.price).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}
            label="AIR Amount"
            InputProps={{
              readOnly: true,
            }}
            inputProps={{
              style: {
                textAlign: "right",
              },
            }}
            variant="filled"
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <PaperTooltip
            title={<SuggestQtyLogs logs={data} type={"SEA"} />}
            arrow
          >
            <TextField
              key={props.id}
              type="number"
              label="SEA Suggested Qty"
              value={props.sea.qty}
              onChange={(e) =>
                props.setSea({ qty: parseInt(e.target.value), dirty: parseInt(e.target.value) !== props.savedSea })
              }
              onClick={(e) => e.stopPropagation()}
              onKeyDown={(e) => e.stopPropagation()}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <DirectionsBoatIcon sx={{ color: "rgba(0,200,0,0.25)" }} />
                  </InputAdornment>
                ),
                readOnly: hasUpdateAuth,
              }}
              inputProps={{
                min: 0,
                style: {
                  textAlign: "right",
                },
              }}
              variant={hasUpdateAuth ? "filled" : "standard"}
            />
          </PaperTooltip>
        </Grid>
        <Grid item xs={4} md={2}>
          <TextField
            value={(props.sea.qty * props.price).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}
            label="SEA Amount"
            InputProps={{
              readOnly: true,
            }}
            inputProps={{
              style: {
                textAlign: "right",
              },
            }}
            variant="filled"
          />
        </Grid>
        <Grid item xs={2} md={1}></Grid>
        <Grid item xs={4} md={2}>
          <TextField
            value={(
              (props.sea.qty + props.air.qty) *
              props.price
            ).toLocaleString(undefined, { maximumFractionDigits: 2 })}
            label="Total Amount"
            InputProps={{
              readOnly: true,
            }}
            inputProps={{
              style: {
                textAlign: "right",
              },
            }}
            variant="filled"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default SuggestQtyForm;
