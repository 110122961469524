import { Box, Button, FormControl, Stack, TextField } from "@mui/material";
import { memo, useEffect, useState, VFC } from "react";
import { SubmitHandler, useForm, useFormState } from "react-hook-form";
import { useRecoilState } from "recoil";
import { useSWRConfig } from "swr";
import { useApiPost } from "../../../hooks/api/useApiPost";
import { SnackbarState } from "../../../stores/SnackbarState";

type vendorType = Paths.GetVendors.Responses.$200["vendors"][0];

interface Props {
  vendor: vendorType | undefined;
  mode?: "update" | "create";
}

const VendorForm: VFC<Props> = memo((props) => {
  const [vendor, setVendor] = useState<vendorType>();
  const { register, handleSubmit, reset, control } = useForm<vendorType>({
    mode: "onChange",
    defaultValues: vendor,
  });
  const { isDirty } = useFormState({ control });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSnackbar] = useRecoilState(SnackbarState);
  const { mutate } = useSWRConfig();
  useEffect(() => {
    setVendor(props.vendor);
    reset(props.vendor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.vendor]);
  const mode = props.mode || "update";
  const postRequestAsync =
    useApiPost<Paths.CreateVendor.Responses.$200>("vendor");
  const readOnly = mode === "update";
  const onSubmit: SubmitHandler<vendorType> = (data) => {
    if (mode === "create") {
      const body: Paths.CreateVendor.Parameters.CreateRequest = {
        vendor: data.vendor,
        name: data.name,
      };
      postRequestAsync({ body })
        .then((res) => {
          setSnackbar({
            open: true,
            message: res.message,
            severity: res.code === 200 ? "success" : "error",
          });
          mutate("/stm/vendor");
          setVendor({ company: "", vendor: "", name: "" });
        })
        .catch(() => {
          setSnackbar({
            open: true,
            severity: "error",
            message: "[ERROR]Vendor is not created.",
          });
        });
    }
  };
  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      padding={8}
      boxShadow={1}
      borderRadius={1}
      bgcolor="paper"
    >
      <Stack spacing={3}>
        <FormControl>
          <TextField
            required
            type="text"
            disabled={readOnly}
            label="Vendor"
            {...register("vendor")}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>

        <FormControl>
          <TextField
            required
            disabled={readOnly}
            label="Name"
            {...register("name")}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 2, mb: 2 }}
          disabled={!isDirty}
        >
          Create
        </Button>
      </Stack>
    </Box>
  );
});

export default VendorForm;
