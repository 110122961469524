import { Box } from "@mui/material";
import axios from "axios";
import React, { memo, useEffect, useState, VFC } from "react";

interface Props {
  url: string;
  drawingNo: number;
  color?: string;
}

const DrawingModal: VFC<Props> = memo(({ url, drawingNo, color }) => {
  const [svgString, setSVGString] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    setLoading(true);
    axios
      .get(url || "")
      .then((response) => {
        setSVGString(response.data);
      })
      .catch(() => setError(true))
      .then(() => setLoading(false));
  }, [url]);
  return loading || error ? (
    <></>
  ) : (
    <Box
      sx={{
        position: "absolute",
        zIndex: 2,
        [`& [data-id="${drawingNo}"]`]: {
          stroke: "red",
          strokeWidth: 0.5,
          fill: color || "#008CA2",
          fillOpacity: 0.6,
        },
      }}
      width="95%"
      dangerouslySetInnerHTML={{ __html: svgString }}
    />
  );
});

export default DrawingModal;
