import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { memo, useEffect, useState, VFC } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useApiGetMakmateAsync } from "../../../hooks/api/useApiGetMakmateAsync";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  no: string;
  path: string;
}
const EngineeringChangeDialog: VFC<Props> = memo((props) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };
  const fetchEngineeringChangeAsync =
    useApiGetMakmateAsync<Paths.GetDocuments.Responses.$200>("documents");

  useEffect(() => {
    try {
      setUrl("");
      setLoading(true);
      fetchEngineeringChangeAsync({}, props.path).then((r) =>
        setUrl(r.presigned_url)
      );
    } catch (error) {
      console.error(error);
      setError(true);
    } finally {
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.no]);

  const handleClose = () => {
    setPageNumber(1);
    props.setOpen(false);
  };
  const decrementPage = () => setPageNumber((prev) => prev - 1);
  const inclementPage = () => setPageNumber((prev) => prev + 1);

  return (
    <Dialog
      open={props.isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-describedby="alert-dialog-slide-description"
      fullWidth={true}
      maxWidth="lg"
    >
      <DialogTitle>{props.no}</DialogTitle>
      <DialogContent sx={{ height: "90vh" }}>
        {error ? (
          <Typography>Error</Typography>
        ) : loading || url === "" ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <IconButton
                color="primary"
                onClick={decrementPage}
                disabled={pageNumber === 1}
              >
                <ArrowBackIosIcon />
              </IconButton>
              <Typography>
                {pageNumber}/{numPages}
              </Typography>
              <IconButton
                onClick={inclementPage}
                color="primary"
                disabled={(numPages || 1) === pageNumber}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
                <Page
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  pageNumber={pageNumber}
                  width={1000}
                />
              </Document>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <IconButton
                color="primary"
                onClick={decrementPage}
                disabled={pageNumber === 1}
              >
                <ArrowBackIosIcon />
              </IconButton>
              <Typography>
                {pageNumber}/{numPages}
              </Typography>
              <IconButton
                onClick={inclementPage}
                color="primary"
                disabled={(numPages || 1) === pageNumber}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </Box>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
});

export default EngineeringChangeDialog;
