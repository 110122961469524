import {
  Button,
  CardActions,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { useEffect, useState, useMemo } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import PaperCard from "../../atoms/PaperCard";
import DeleteIcon from "@mui/icons-material/Delete";
import { FieldError, Resolver, SubmitHandler, useForm } from "react-hook-form";
import { useApiGetWithCache } from "../../../hooks/api/useApiGetWithCache";
import { useApiPost } from "../../../hooks/api/useApiPost";
import { useRecoilState } from "recoil";
import { SnackbarState } from "../../../stores/SnackbarState";
import LoadingBackdrop from "../../molecules/LoadingBackdrop";
import CentralizedLoading from "../../atoms/CentralizedLoading";
import { Decimal } from "decimal.js-light"

type form_type = Paths.GetStdDevs.Responses.$200["std_devs"][0];

const StandardDeviationCard = () => {
  const { data, loading, mutate } =
    useApiGetWithCache<Paths.GetStdDevs.Responses.$200>("stm", "std_devs", {});
  const [params, setParams] = useState<
    Paths.GetStdDevs.Responses.$200["std_devs"]
  >([]);
  const postRequestAsync =
    useApiPost<Paths.PostStdDevs.Responses.$200>("std_devs");
   const apiRef = useGridApiRef();
  const [, setSnackbar] = useRecoilState(SnackbarState);
  const [isDirty, setIsDirty] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const schema = yup
    .object({
      min: yup.number().min(0).integer().nullable(),
      max: yup
        .number()
        .transform((currentValue, originalValue) => {
          return originalValue === "" ? null : currentValue;
        })
        .nullable(),
      value: yup.number().required(),
    })
    .test("min_duplicated_test", "", (obj) => {
      if (!params.map((m) => m.min).includes(obj.min || 0)) {
        return true;
      }
      return new yup.ValidationError("Min is duplicated", null, "min");
    })
    .test("max_duplicated_test", "", (obj) => {
      if (!params.map((m) => m.max).includes(obj?.max || undefined)) {
        return true;
      }
      return new yup.ValidationError("Max is duplicated", null, "max");
    })
    .test("max_range_test", "", (obj) => {
      if (params.length === 0) {
        return true;
      }
      if (obj.min && obj.min >= (obj.max ?? Number.MAX_SAFE_INTEGER)) {
        return new yup.ValidationError("Invalid range", null, "max");
      }
      const prevRow = params.findLast((f) => f.min <= (obj.min || 0));
      if (prevRow) {
        if ((prevRow.max ?? Number.MAX_SAFE_INTEGER) > (obj.min || 0)) {
          return new yup.ValidationError("Invalid range", null, "min");
        }
        if ((prevRow.max ?? Number.MAX_SAFE_INTEGER) > (obj.max ?? Number.MAX_SAFE_INTEGER)) {
          return new yup.ValidationError("Invalid range", null, "max");
        }
      }
      const nextRow = params.find((f) => f.min > (obj.min || 0));
      if (nextRow) {
        if ((obj.max || 0) > nextRow.min) {
          return new yup.ValidationError("Invalid range", null, "max");
        }
      } else {
        const lastRow = params.find((f) => f.min === Math.max(...params.map((m) => m.min)));
        if (lastRow) {
          if (lastRow.min <=  (obj.min || 0) && (lastRow.max ?? Number.MAX_SAFE_INTEGER) > (obj.min || 0)) {
            return new yup.ValidationError("Invalid range", null, "min");
          }
          if ((obj.max ?? Number.MAX_SAFE_INTEGER) <=(lastRow?.max ?? Number.MAX_SAFE_INTEGER)) {
            return new yup.ValidationError("Invalid range", null, "max");
          }

        }
      }
      return true;
    });
  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, errors },
  } = useForm<form_type>({
    resolver: yupResolver(schema) as Resolver<form_type, object>,
    mode: "onChange",
  });

  useEffect(() => {
    setParams(
      data?.std_devs.length === 0
        ? [{ min: 0, value: 100 }]
        : data?.std_devs ?? []
    );
  }, [data]);

  const handleDelete = (min: number) => {
    setParams((prev) => prev.filter((row) => row.min !== min));
    setIsDirty(true);
  };
  const handleAdd: SubmitHandler<form_type> = (data) => {
    const value = new Decimal(data.value);
    const multiplier = new Decimal(100);
    data.value = Math.floor(value.times(multiplier).toNumber())
    setParams((prev) => [...prev, data].sort((a, b) => a.min - b.min));
    reset();
    setIsDirty(true);
  };

  const handleSave = () => {
    setRequestLoading(true);
    const body: Paths.PostStdDevs.Parameters.StdDevs = {
      std_devs: params,
    };
    postRequestAsync({ body })
      .then((res) => {
        setSnackbar({
          open: true,
          message: res.message,
          severity: res.code === 200 ? "success" : "error",
        });
        if (res.code === 200) {
          mutate({ std_devs: [...params] });
        }
      })
      .catch(() => {
        setSnackbar({
          open: true,
          severity: "error",
          message: "[ERROR]Updating parameter is failed.",
        });
      })
      .finally(() => {
        setIsDirty(false);
        setRequestLoading(false);
      });
  };
  const rowHeight = 30;
  const headerHeight = 40;
  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "min",
        type: "number",
        headerName: "Min",
        sortable: false,
        headerAlign: "center",
        align: "center",
        width: 120,
      },
      {
        field: "max",
        type: "string",
        headerName: "Max",
        sortable: false,
        headerAlign: "center",
        align: "center",
        width: 120,
        valueGetter: (value) => value ?? "MAX",
      },
      {
        field: "value",
        type: "string",
        headerName: "Value",
        sortable: false,
        headerAlign: "center",
        align: "center",
        width: 120,
        valueGetter: (value) => (Number(value) / 100).toFixed(2),
      },
      {
        field: "actions",
        type: "actions",
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => handleDelete(Number(params.id))}
          />,
        ],
      },
    ],
    []
  );
  return (
    <PaperCard>
      <LoadingBackdrop loading={requestLoading} />
      <CardContent>
        <Typography fontWeight="bold" fontSize={24}>
          Standard Deviation
        </Typography>
        <Grid
          mt={4}
          container
          component="form"
          onSubmit={handleSubmit(handleAdd)}
          spacing={1}
        >
          <Grid item md={3}>
            <TextField
              required
              {...register("min")}
              label="Min"
              size="small"
              error={"min" in errors}
              helperText={(errors["min"] as FieldError)?.message}
              type="number"
              inputProps={{ step: "1", min: 0 }}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              helperText={(errors["max"] as FieldError)?.message}
              {...register("max")}
              label="Max"
              size="small"
              error={"max" in errors}
              type="number"
              inputProps={{ step: "1", min: 0 }}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              required
              {...register("value")}
              label="Value"
              size="small"
              type="number"
              inputProps={{ step: "0.01", min: 0, max: 2.55 }}
            />
          </Grid>
          <Grid item md={1}>
            <Button type="submit" fullWidth variant="contained" disabled={!isValid}>
              Add
            </Button>
          </Grid>
        </Grid>
        <Box pt={2}>
        {loading ? (
          <CentralizedLoading />
        ) : (
          <DataGridPro
            apiRef={apiRef}
            columns={columns}
            rows={params}
            rowHeight={30}
            getRowId={(row) => row.min}
            disableColumnMenu
            hideFooter
            loading={loading}
            sx={{ overflowY: "auto", maxHeight: headerHeight + rowHeight * 8 }}
          />
        )}
        </Box>
      </CardContent>
      <CardActions
        sx={{ display: "flex", justifyContent: "right", paddingRight: 2 }}
      >
        <Button
          color="primary"
          variant="contained"
          disabled={!isDirty}
          onClick={handleSave}
        >
          Save
        </Button>
      </CardActions>
    </PaperCard>
  );
};

export default StandardDeviationCard;
