import { IconButton, Tooltip } from "@mui/material";
import React, { memo, useState, VFC } from "react";
import { useRecoilState } from "recoil";
import DeleteIcon from "@mui/icons-material/Delete";
import { useApiPost } from "../../../hooks/api/useApiPost";
import { SnackbarState } from "../../../stores/SnackbarState";
import LoadingBackdrop from "../LoadingBackdrop";
import SlideInDialog from "../SlideInDialog";

interface Props {
  headerId: number;
  disabled?: boolean;
}

const DeleteButton: VFC<Props> = memo(({ headerId, disabled }) => {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSnackbar] = useRecoilState(SnackbarState);
  const postRequestAsync =
    useApiPost<Paths.UpdateStockmovementStatus.Responses.$200>(
      "stockmovement_status"
    );

  const handleModalOpen = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setOpenModal(true);
  };
  const handleModalClose = () => setOpenModal(false);
  const updateStatus = (headerId: number, status: "Completed" | "Deleted") => {
    const body: Paths.UpdateStockmovementStatus.Parameters.UpdateStatusRequest =
      { id: headerId, status: status };
    setLoading(true);
    postRequestAsync({ body })
      .then((res) => {
        setSnackbar({
          open: true,
          message: res.message,
          severity: res.code === 200 ? "success" : "error",
        });
      })
      .catch(() => {
        setSnackbar({
          open: true,
          message: "Request is failed.",
          severity: "error",
        });
      })
      .finally(() => setLoading(false));
  };
  const onClickDeleteConfirmation = () => {
    handleModalClose();
    updateStatus(headerId, "Deleted");
  };

  return (
    <>
      <LoadingBackdrop loading={loading} />
      <SlideInDialog
        open={openModal}
        handleClose={handleModalClose}
        title="Delete StockMovement"
        content="Are you sure want to delete this StockMovement?"
        onClickCancel={handleModalClose}
        onClickOk={onClickDeleteConfirmation}
      />
      <Tooltip title="Delete" arrow={true}>
        <IconButton disabled={disabled} onClick={handleModalOpen}>
          <DeleteIcon color={disabled ? "disabled" : "primary"} />
        </IconButton>
      </Tooltip>
    </>
  );
});

export default DeleteButton;
