import React, { memo, useCallback, useState, FC } from "react";
import { useRecoilState } from "recoil";
import { useApiDelete } from "../../../hooks/api/useApiDelete";
import { SnackbarState } from "../../../stores/SnackbarState";
import LoadingBackdrop from "../LoadingBackdrop";
import SlideInDialog from "../SlideInDialog";

interface Props {
  open: boolean;
  handleClose: () => void;
  alternate: alternate;
  handleRefresh: () => void;
}
interface alternate {
  alternate_id: number;
  fromItem: string;
  toItem: string;
}

const DeleteAlternateDialog: FC<Props> = memo(({ open, handleClose, alternate, handleRefresh }) => {
  const deleteAlternateAsync = useApiDelete<Paths.DeleteAlternate.Responses.$200>("alternate", String(alternate.alternate_id));
  const [, setSnackbar] = useRecoilState(SnackbarState);
  const [loading, setLoading] = useState(false);
  const handleOk = useCallback(async () => {
    setLoading(true);
    handleClose();
    try {
      const res = await deleteAlternateAsync({});
      setSnackbar({
        open: true,
        message: res.message,
        severity: res.code === 200 ? "success" : "error",
      });
    } catch (error) {
      console.error(error);
      setSnackbar({
        open: true,
        message: "[ERROR]Failed to delete alternate item...",
        severity: "error",
      });
      handleRefresh();
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alternate.alternate_id]);

  return (
    <>
      <LoadingBackdrop loading={loading} />
      <SlideInDialog
        open={open}
        handleClose={handleClose}
        title="Delete Alternate"
        content={`Are you sure want to delete alternate From Item: ${alternate.fromItem} To Item: ${alternate.toItem} ?`}
        onClickCancel={handleClose}
        onClickOk={handleOk}
      />
    </>
  );
});

export default DeleteAlternateDialog;
