import {
  Backdrop,
  Box,
  Button,
  Fade,
  Grid,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { VFC, useState, useMemo, useEffect } from "react";
import CalcForecast from "../../../functions/CalcForecast";
import ModalForecastStockMonthTableRow from "../../organisms/ItemDetail/ModalForecastStockMonthTableRow";
import ModalForecastTableRow from "../../organisms/ItemDetail/ModalForecastTableRow";
import ModalStockChart from "../../organisms/ItemDetail/ModalStockChart";
import ModalSuggestQty from "../../organisms/ItemDetail/ModalSuggestQty";
import { useRecoilState } from "recoil";
import { UserState } from "../../../stores/UserState";
import HasUpdateAuthority from "../../../functions/HasUpdateAuthority";

interface suggestQty {
  qty: number;
  dirty: boolean;
}

interface Props {
  air: number;
  sea: number;
  detail: Paths.GetStockMovementDetails.Responses.$200["details"][0];
  monthHeaders: string[];
  open: boolean;
  handleClose: () => void;
  handleCloseSave: (
    v1: { qty: number; dirty: boolean },
    v2: { qty: number; dirty: boolean },
    v3: Paths.GetStockMovementDetails.Responses.$200["details"][0]
  ) => void;
  stmId: string;
  itemId: string;
  setItemDetailAir: (v: suggestQty) => void;
  setItemDetailSea: (v: suggestQty) => void;
  isOnlySea: boolean;
}

const SimulationModal: VFC<Props> = (props) => {
  const { detail, isOnlySea } = props;
  const [suggestAir, setAir] = useState({
    qty: detail.suggest_air || 0,
    dirty: false,
  });
  const [suggestSea, setSea] = useState({
    qty: detail.suggest_sea || 0,
    dirty: false,
  });
  const [sales, setSales] = useState(detail.sales_forecast || 0);
  const [inputSale, setInputSale] = useState("");
  const [purchase, setPurchase] = useState(detail.purchase_forecast || 0);
  const [user] = useRecoilState(UserState);
  const hasUpdateAuth = HasUpdateAuthority(
    user?.is_user_update,
    user?.company_code
  );
  useEffect(() => {
    setAir({ qty: detail.suggest_air || 0, dirty: false });
    setSea({ qty: detail.suggest_sea || 0, dirty: false });
    setSales(detail.sales_forecast || 0);
    setPurchase(detail.purchase_forecast || 0);
    setInputSaleWrapper("");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail.item, detail.suggest_air, detail.suggest_sea]);

  const alternate = detail.alternate || "";
  const isDisconItem =
    (alternate && alternate.includes("[To]")) || detail.status === "D";
  const forecast = useMemo(
    () => {
      return CalcForecast({
        isOnlySea: isOnlySea,
        bo: detail.back_order || 0,
        mtdSales: detail.mtd_sales || 0,
        stock: detail.stock || 0,
        salesHistory: detail.sales,
        ltmAIR: detail.ltm_air,
        ltmSEA: detail.ltm_sea,
        msmSea: detail.msm_sea || 1,
        moq: detail.moq || 1,
        ocfForecast: [
          detail.ocf_forecast1 || 0,
          detail.ocf_forecast2 || 0,
          detail.ocf_forecast3 || 0,
          detail.ocf_forecast4 || 0,
          detail.ocf_forecast5 || 0,
        ],
        isDiscon: isDisconItem,
        layer: detail.layer || 1,
        seasonalityCode: detail.seasonality_code || 12,
        // 可変
        suggestAIR: suggestAir.qty,
        suggestSEA: suggestSea.qty,
        purchase: purchase,
        sales: sales,
      });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [purchase, sales, suggestAir, suggestSea, props.itemId, detail]
  );

  const setSalesWrapper = (i: number, v: number) => {
    setSales((prev) => [...prev.slice(0, i), v, ...prev.slice(i + 1)]);
  };

  const setPurchaseWrapper = (i: number, v: number) => {
    setPurchase((prev) => [...prev.slice(0, i), v, ...prev.slice(i + 1)]);
  };

  const setInputSaleWrapper = (v: string) => {
    setInputSale(v);
    if (v === "") return;
    setSales((prev) => Array(prev.length).fill(parseInt(v)));
  };

  const handleCloseClearData = () => {
    props.handleClose();
    setAir({ qty: detail.suggest_air, dirty: false });
    setSea({ qty: detail.suggest_sea, dirty: false });
    setSales(detail.sales_forecast);
    setPurchase(detail.purchase_forecast);
    setInputSale("");
  };

  const handleCloseUpdateData = () => {
    props.handleClose();
    props.setItemDetailAir({ qty: suggestAir.qty, dirty: true });
    props.setItemDetailSea({ qty: suggestSea.qty, dirty: true });
    setSales(detail.sales_forecast);
    setPurchase(detail.purchase_forecast);
    setInputSale("");
    props.handleCloseSave(
      { qty: suggestAir.qty, dirty: true },
      { qty: suggestSea.qty, dirty: true },
      detail
    );
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onKeyDown={(e) => e.stopPropagation()}
        onClose={handleCloseClearData}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box
            sx={{
              position: "absolute" as "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 1100,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 3,
              alignContent: "center",
            }}
          >
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <ModalStockChart
                  air={suggestAir.qty}
                  sea={suggestSea.qty}
                  detail={{
                    ...detail,
                    sales_forecast: sales,
                    purchase_forecast: purchase,
                    standard_stock: forecast.stock,
                  }}
                  monthHeaders={props.monthHeaders}
                />
              </Grid>
              <Grid item sm={12}>
                <Table padding="none" size="small" aria-label="mini table">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      {props.monthHeaders.map((v) => (
                        <TableCell align="left" key={v}>
                          {v}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <ModalForecastTableRow
                      rowId={detail.id}
                      rowHeader="Sales"
                      values={sales}
                      editable={true}
                      onChange={setSalesWrapper}
                    />
                    <ModalForecastTableRow
                      rowId={detail.id}
                      rowHeader="Purchase"
                      values={purchase}
                      editable={true}
                      onChange={setPurchaseWrapper}
                    />
                    <ModalForecastTableRow
                      rowId={detail.id}
                      rowHeader="Additional"
                      values={forecast.additionalPurchase}
                      ltm_air={
                        detail.ltm_air === undefined ? 3 : detail.ltm_air
                      }
                      ltm_sea={
                        detail.ltm_sea === undefined ? 6 : detail.ltm_sea
                      }
                      editable={false}
                      onChange={() => ""}
                      isOnlySea={isOnlySea}
                    />
                    <ModalForecastTableRow
                      rowId={detail.id}
                      rowHeader="Stock"
                      values={forecast.stock}
                      editable={false}
                      onChange={() => ""}
                    />
                    <ModalForecastStockMonthTableRow
                      rowId={detail.id}
                      values={forecast.month}
                      minimumStockMonth={{
                        air: detail.msm_air || 0,
                        sea: detail.msm_sea || 0,
                      }}
                      leadTimeMonth={{
                        air: detail.ltm_air,
                        sea: detail.ltm_sea,
                      }}
                    />
                  </TableBody>
                </Table>
              </Grid>
              <Grid item sm={12}>
                {hasUpdateAuth || (
                  <ModalSuggestQty
                    headerId={props.stmId}
                    id={detail.id}
                    vendor={detail.vendor}
                    item={detail.item}
                    air={suggestAir}
                    sea={suggestSea}
                    setAir={setAir}
                    setSea={setSea}
                    inputSale={inputSale}
                    setInputSaleWrapper={setInputSaleWrapper}
                    price={detail.price || 0}
                    isOnlySea={isOnlySea}
                  />
                )}
              </Grid>
            </Grid>
            <Grid container paddingTop={2} justifyContent="end">
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button variant="outlined" onClick={handleCloseClearData}>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" onClick={handleCloseUpdateData}>
                      OK
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default SimulationModal;
