import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from "@mui/material";
import { Breakpoint } from '@mui/system';
import { TransitionProps } from "@mui/material/transitions";
import React, { memo, VFC } from "react";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  open: boolean;
  handleClose: () => void;
  title: string;
  children: React.ReactNode;
  fullWidth?: boolean;
  height?: string;
  maxWidth?: Breakpoint;
  actions?: () => void;
}

const TransitionModal: VFC<Props> = memo(
  ({
    open,
    handleClose,
    title,
    children,
    fullWidth = true,
    maxWidth = "md",
    height = "80vh",
    actions,
  }) => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent sx={{ height: height }}>{children}</DialogContent>
        {actions && (
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        )}
      </Dialog>
    );
  }
);

export default TransitionModal;
