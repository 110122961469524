import { memo, FC } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpIcon from "@mui/icons-material/Help";
import DownloadSTMExcel from "./DownloadSTMExcel";
import DownloadPO from "./DownloadPO";
import HeaderInformation from "./HeaderInformation";
import PurchaseOrderLogs from "./PurchaseOrderLogs";

interface Props {
  headerId: string;
  header: Paths.GetStockMovementDetails.Responses.$200["header"];
  excelPrint: Paths.GetCreateExcelProgress.Responses.$200 | undefined;
  purchaseOrders: Paths.GetStockMovementDetails.Responses.$200["purchase_orders"];
  vendors: string[];
}

const HeaderAccordion: FC<Props> = memo(
  ({ headerId, header, purchaseOrders, vendors, excelPrint }) => {
    const excelPrintInfo = excelPrint?.print;

    const headerInfo = header;
    return headerInfo ? (
      <Accordion sx={{ background: "#FAFAFA" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography fontWeight={"bold"}>{header.no}</Typography>
          <Tooltip
            title={
              <Box>
                <Typography fontSize="13px">
                  MTD Sales: Month to Date Sales
                  <br />
                  MOQ: Minimum Order Qty
                  <br />
                  SMB: Static Minimum Balance
                  <br />
                  DMB: Dynamic Minimum Balance
                  <br />
                  LTM: Lead Time Month
                  <br />
                  MSM: Minimum Stock Month
                </Typography>
              </Box>
            }
          >
            <HelpIcon
              fontSize="medium"
              color="disabled"
              sx={{ alignItems: "flex-end", paddingLeft: "6px" }}
            />
          </Tooltip>
        </AccordionSummary>
        <br />
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <HeaderInformation header={headerInfo} />
            </Grid>
            <Grid item xs={6} md={3}>
              <DownloadPO
                id={headerId}
                vendors={vendors}
                isOnlySea={!!headerInfo.only_sea}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <PurchaseOrderLogs
                headerId={headerId}
                purchaseOrders={purchaseOrders}
              />
              <br />
              {excelPrintInfo ? (
                <DownloadSTMExcel
                  id={headerId}
                  status={excelPrintInfo.print_excel_status || ""}
                  update_at={
                    excelPrintInfo.s3_key ? excelPrintInfo.update_at || "" : ""
                  }
                  header_no={header.no}
                  s3Key={excelPrintInfo.s3_key || "Outputs not found"}
                  user_sub={excelPrintInfo.user_sub || ""}
                  user_name={excelPrintInfo.user_name || ""}
                  progressPercent={excelPrintInfo.print_excel_progress || 0}
                />
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    ) : (
      <Accordion>
        <AccordionDetails>
          <Typography>data not found</Typography>
        </AccordionDetails>
      </Accordion>
    );
  }
);

export default HeaderAccordion;
