import React, { memo, useEffect, useMemo, useState, VFC } from "react";
import { Fab, Grow, Tooltip } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import PushPinIcon from "@mui/icons-material/PushPin";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import UpdatedListModal from "../../molecules/StockMovementDetail/UpdatedListModal";
import PieChartIcon from "@mui/icons-material/PieChart";
import StandardDeviationModel from "../../molecules/StockMovementDetail/StandardDeviationModal";
import PrintIcon from "@mui/icons-material/Print";
import CircularProgress from "@mui/material/CircularProgress";
import { useRecoilState } from "recoil";
import { SnackbarState } from "../../../stores/SnackbarState";
import SlideInDialog from "../../molecules/SlideInDialog";
import { useApiPost } from "../../../hooks/api/useApiPost";

interface Props {
  excelRequest: Paths.PostPrintExcel.Parameters.PrintExcelRequest;
  companyCode: string;
  excelPrintStatus: string;
  excelPrintDate: string;
  header: {
    headerId: number;
    plannerCode: string;
  };
  details: {
    id: number;
    vendor: string;
    item: string;
    is_apply_growth: boolean | undefined;
  }[];
  rowCount: number;
  saveFilter?: () => void;
}

interface fab {
  key: string;
  tooltip: string;
  icon: React.ReactElement;
  onClick: () => void;
  isAction?: boolean;
  isDownLoading?: boolean;
  isAppling?: boolean;
}

const DetailFab: VFC<Props> = memo((props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [visibleRowsCount, setVisibleRowsCount] = useState(0);
  const [, setSnackbar] = useRecoilState(SnackbarState);
  const [openSlideDialog, setOpenSlideDialog] = useState(false);
  const [openSuggestQtyModal, setOpenSuggestQtyModal] = useState(false);
  const [openStdevModal, setOpenStdevModal] = useState(false);
  const [isExpandFab, setIsExpandFab] = useState(false);
  const [isDling, setIsDling] = useState(false);

  const postRequestAsync = useApiPost<Paths.PostGrowth.Responses.$200>(
    "print",
    "",
    true
  );

  const handleFab = () => setIsExpandFab((prev) => !prev);
  const handleStdevClose = () => setOpenStdevModal(false);
  const handleClosePrintExcelDialog = () => setOpenSlideDialog(false);
  const handleOK = async () => {
    setIsDling(true);
    setSnackbar({ open: false, message: "" });
    handleClosePrintExcelDialog();

    const body: Paths.PostPrintExcel.Parameters.PrintExcelRequest = {
      headerId: props.header.headerId,
      companyCode: props.companyCode,
      plannerCode: props.header.plannerCode,
      // TODO Debug
      // filterType: props.excelRequest.filterType,
      // filterInfo: props.excelRequest.filterInfo,
      // sortInfo: props.excelRequest.sortInfo,
      filterType: "",
      filterInfo: [],
      sortInfo: [],
    };

    postRequestAsync({ body })
      .then((res) => {
        setSnackbar({
          open: true,
          message: "It may take a few minutes.",
          severity: res.code === 200 ? "success" : "error",
        });
      })
      .catch(() => {
        setSnackbar({
          open: true,
          message: "Request is failed.",
          severity: "error",
        });
      });
  };

  useEffect(() => {
    setIsDling(props.excelPrintStatus === "Processing");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.excelPrintDate, props.excelPrintStatus]);

  const fabs: fab[] = useMemo(
    () => [
      {
        key: "updated_list",
        tooltip: "Update Log",
        icon: <FactCheckIcon />,
        onClick: () => setOpenSuggestQtyModal(true),
      },
      {
        key: "standard_dev_analysis",
        tooltip: "Standard Deviation",
        icon: <PieChartIcon />,
        onClick: () => setOpenStdevModal(true),
      },
      {
        key: "save_filter",
        tooltip: "Save filter",
        icon: <PushPinIcon />,
        onClick: props.saveFilter ?? (() => console.log("invalid save filter")),
      },
      {
        key: "print_excel",
        tooltip: "Print Excel",
        icon: <PrintIcon />,
        onClick: () => {
          setOpenSlideDialog(true);
        },
        isDownLoading: isDling || props.excelPrintStatus === "Processing",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDling]
  );

  return isExpandFab ? (
    <>
      <UpdatedListModal
        open={openSuggestQtyModal}
        setOpen={setOpenSuggestQtyModal}
        headerID={props.header.headerId}
        details={props.details}
      />
      <StandardDeviationModel
        open={openStdevModal}
        handleClose={handleStdevClose}
        headerID={props.header.headerId}
      />
      <SlideInDialog
        open={openSlideDialog}
        handleClose={handleClosePrintExcelDialog}
        onClickCancel={handleClosePrintExcelDialog}
        onClickOk={handleOK}
        title="Print STM Excel"
        content="Are you sure want to print Excel?"
      />
      {fabs.map((fab, i) => (
        <Grow
          key={fab.key}
          in={isExpandFab}
          style={{ transformOrigin: "0 0 0" }}
          {...(isExpandFab ? { timeout: i * 150 + 150 } : {})}
        >
          <Fab
            sx={{
              opacity: "0.8",
              position: "fixed",
              bottom: `${i * 64 + 96}px`,
              right: "16px",
              zIndex: 200,
            }}
            color={fab.isAction ? "default" : "primary"}
            onClick={fab.onClick}
            disabled={
              fab.key === "print_excel" &&
              (fab.isDownLoading || props.details.length > 20000)
              // TODO
              // (fab.isDownLoading || props.rowCount > 20000)
            }
          >
            <Tooltip title={fab.tooltip} arrow={true}>
              {fab.icon}
            </Tooltip>
            {fab.isDownLoading && (
              <CircularProgress
                size={68}
                sx={{
                  position: "absolute",
                  top: -6,
                  left: -6,
                  zIndex: 1,
                }}
              />
            )}
          </Fab>
        </Grow>
      ))}
      <Fab
        color="primary"
        sx={{
          opacity: "0.8",
          position: "fixed",
          bottom: "32px",
          right: "16px",
          zIndex: 200,
        }}
        onClick={handleFab}
      >
        <ExpandMoreIcon />
      </Fab>
    </>
  ) : (
    <Fab
      sx={{
        opacity: "0.4",
        position: "fixed",
        bottom: "32px",
        right: "16px",
        zIndex: 200,
      }}
      onClick={handleFab}
    >
      <ExpandLessIcon color="primary" />
    </Fab>
  );
});

export default DetailFab;
