import {
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { memo, useState, VFC } from "react";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import UserAvatar from "../../molecules/UserAvatar";
import { useApiGetAsync } from "../../../hooks/api/useApiGetAsync";
import DownloadWithPresignUrl from "../../../functions/DownloadWithPresignUrl";
import { useRecoilState } from "recoil";
import { SnackbarState } from "../../../stores/SnackbarState";
import LoadingBackdrop from "../../molecules/LoadingBackdrop";
import AirplaneIcon from "../../atoms/AirplaneIcon";
import BoatIcon from "../../atoms/BoatIcon";

type purchaseOrders =
  Paths.GetStockMovementDetails.Responses.$200["purchase_orders"];

interface Props {
  headerId: string;
  purchaseOrders: purchaseOrders;
}

const PurchaseOrderLogs: VFC<Props> = memo((props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSnackbar] = useRecoilState(SnackbarState);
  const [loading, setLoading] = useState(false);
  const fetchAsync = useApiGetAsync<Paths.GetCreatedPO.Responses.$200>(
    "stm",
    "create_po"
  );
  const onClickDownload = (headerId: string, poId: number, poName: string) => {
    const query: Paths.GetCreatedPO.QueryParameters = { po_id: poId };
    setLoading(true);
    fetchAsync({ queryStringParameters: query }, headerId)
      .then((res) => {
        DownloadWithPresignUrl(res.presigned_url, `${poName}.csv`);
      })
      .catch((err) => {
        console.log(err);
        setSnackbar({
          open: true,
          message: "cannot download purchase order.",
        });
        return;
      })
      .finally(() => setLoading(false));
  };
  return (
    <>
      <Grid container spacing={0} marginTop={-2} direction="column">
        <Typography fontWeight={"bold"}>Issued Purchase Orders</Typography>
        <List
          sx={{
            width: "100%",
            maxWidth: 360,
            bgcolor: "background.default",
            position: "relative",
            overflow: "auto",
            maxHeight: 190,
            "& ul": { padding: 0 },
          }}
        >
          {props.purchaseOrders.map((po) => (
            <Box key={po.id}>
              <ListItem
                alignItems="flex-start"
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="download"
                    onClick={() =>
                      onClickDownload(props.headerId, po.id, po.name)
                    }
                  >
                    <CloudDownloadIcon color="primary" />
                  </IconButton>
                }
                style={{ paddingTop: 0, paddingBottom: 0, margin: 0 }}
              >
                <ListItemAvatar>
                  <UserAvatar
                    userSub={po.create.user.sub}
                    userName={po.create.user.name}
                    size={35}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Grid
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "noWrap",
                      }}
                    >
                      <Typography paddingRight={1} fontSize={15}>
                        {po.vendor}
                      </Typography>
                      {po.type === "AIR" ? <AirplaneIcon /> : <BoatIcon />}
                      <Tooltip title={po.name} arrow>
                        <Typography paddingLeft={1} noWrap fontSize={15}>
                          {po.name}
                        </Typography>
                      </Tooltip>
                    </Grid>
                  }
                  secondary={
                    <Typography
                      variant="caption"
                      display="block"
                      color={"gray"}
                    >
                      {new Date(po.create.date).toLocaleDateString(
                        navigator.language,
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        }
                      )}
                    </Typography>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </Box>
          ))}
        </List>
        <LoadingBackdrop loading={loading} />
      </Grid>
    </>
  );
});

export default PurchaseOrderLogs;
