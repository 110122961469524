import React, { memo, useCallback, useState, VFC } from "react";
import { useRecoilState } from "recoil";
import { useApiDelete } from "../../../hooks/api/useApiDelete";
import { SnackbarState } from "../../../stores/SnackbarState";
import LoadingBackdrop from "../LoadingBackdrop";
import SlideInDialog from "../SlideInDialog";

interface Props {
  open: boolean;
  handleClose: () => void;
  user: user;
  handleRefresh: () => void;
}
interface user {
  sub: string;
  givenName: string;
  familyName: string;
}

const DeleteUserDialog: VFC<Props> = memo(({ open, handleClose, user, handleRefresh }) => {
  const deleteUserAsync = useApiDelete<Paths.DeleteUser.Responses.$200>("user");
  const [, setSnackbar] = useRecoilState(SnackbarState);
  const [loading, setLoading] = useState(false);
  const handleOk = useCallback(async () => {
    setLoading(true);
    handleClose();
    try {
      const body: Paths.DeleteUser.Parameters.DeleteUserRequest = {
        sub: user.sub,
        given_name: user.givenName,
        family_name: user.familyName,
        email: "",
        company_code: "",
        primary_region: "",
        regions: [],
      };
      const res = await deleteUserAsync({ body });
      setSnackbar({
        open: true,
        message: res.message,
        severity: res.code === 200 ? "success" : "error",
      });
      handleRefresh();
    } catch (error) {
      console.error(error);
      setSnackbar({
        open: true,
        message: "[ERROR]Failed to delete user...",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.sub]);

  return (
    <>
      <LoadingBackdrop loading={loading} />
      <SlideInDialog
        open={open}
        handleClose={handleClose}
        title="Delete User"
        content={`Are you sure want to delete user ${user.givenName} ${user.familyName}?`}
        onClickCancel={handleClose}
        onClickOk={handleOk}
      />
    </>
  );
});

export default DeleteUserDialog;
