/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { Grid, ListItemIcon, Modal } from "@mui/material";
import { Logout, Settings } from "@mui/icons-material";
import { useSignOut } from "../../hooks/auth/useSignOut";
import { useNavigate } from "react-router-dom";
import MenuDrawer from "../molecules/MenuDrawer";
import { useRecoilState } from "recoil";
import { UserState } from "../../stores/UserState";
import IconSettings from "../molecules/IconSettings";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import UserAvatar from "../molecules/UserAvatar";
import { ReactComponent as Logo } from "../../svg/stm_logo.svg";
import { Alert, AlertTitle } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";

const MenuAppBar = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [userCountry, setUserCountry] = useState<JSX.Element | null>(null);
  const navigate = useNavigate();
  const [user] = useRecoilState(UserState);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const getCountryFlag = (company_code: string | undefined) => {
    if (!company_code) {
      return null;
    }

    return (
      <Typography
        component="span"
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <img
          src={`${process.env.PUBLIC_URL}/${company_code}.png`}
          alt={`${company_code} flag`}
          style={{ width: "1.18cm", height: "0.8cm" }}
        />
      </Typography>
    );
  };

  useEffect(() => {
    setUserCountry(getCountryFlag(user?.company_code));
  }, [user?.company_code]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <MenuDrawer open={openDrawer} closeDrawer={() => setOpenDrawer(false)} />
      <AppBar data-e2e="app-bar">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => setOpenDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              "&:hover": {
                cursor: "pointer",
              },
              maxHeight: "100px",
            }}
            onClick={() => navigate("/")}
          >
            <Logo />
          </Box>
          <Alert icon={<WarningIcon fontSize="inherit" />} severity="warning">
            <AlertTitle>Notice : </AlertTitle>
            This site has been updated as of 10th Sep. Please access the new
            site. ー{" "}
            <strong>
              <a
                href="https://stm.latam-makita.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here
              </a>
            </strong>
          </Alert>
          <Typography
            variant="subtitle1"
            component="div"
            sx={{
              flexGrow: 50,
              textAlign: "right",
              fontStyle: "italic",
            }}
          >
            Bienvenido!
          </Typography>
          <Typography
            variant="subtitle1"
            component="div"
            sx={{
              flexGrow: 1,
              textAlign: "right",
            }}
          >
            {user?.company_code}
          </Typography>
          <Typography
            component="div"
            sx={{
              flexGrow: 1,
              textAlign: "center",
            }}
          >
            {userCountry}
          </Typography>
          <Typography
            variant="subtitle1"
            component="div"
            sx={{
              flexGrow: 1,
              textAlign: "right",
            }}
          >
            {user?.name}
          </Typography>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appBar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <UserAvatar userSub={user?.sub || ""} userName={user?.name || ""} />
          </IconButton>
          <Menu
            id="menu-appBar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleModal}>
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              Settings
            </MenuItem>
            <MenuItem
              onClick={() => {
                useSignOut().then(() => {
                  // useDispatchAuthState(AuthState.SignedOut);
                  navigate("/", { replace: true });
                });
              }}
            >
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Sign Out
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Grid
            sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
          >
            <PhotoCameraIcon />
            <Typography>Profile picture</Typography>
          </Grid>
          <Grid container justifyContent="center">
            <IconSettings
              userName={user?.name || ""}
              userSub={user?.sub || ""}
            />
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
};

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export default MenuAppBar;
