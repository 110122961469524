import { TableCell, TableRow, TextField } from "@mui/material";
import React, { VFC } from "react";

interface Props {
  rowId: number;
  rowHeader: string;
  values: number[];
  ltm_air?: number;
  ltm_sea?: number;
  editable: boolean;
  onChange: (i: number, v: number) => void;
  isOnlySea?: boolean;
}

const ModalForecastTableRow: VFC<Props> = (props) => {
  return (
    <TableRow>
      <TableCell
        width="55"
        height="28"
        padding="none"
        size="small"
        component="th"
        scope="row"
      >
        {props.rowHeader}
      </TableCell>
      {props.values.map((v, i) => (
        <TableCell
          width={60}
          padding="none"
          size="small"
          align="left"
          key={`${props.rowHeader}-${props.rowId}-${i}`}
          sx={{
            bgcolor:
              i === props.ltm_air
                ? props.isOnlySea
                  ? ""
                  : "rgba(0,0,200,0.1)"
                : i === props.ltm_sea
                ? "rgba(0,200,0,0.12)"
                : "",
            fontWeight: props.isOnlySea
              ? i === props.ltm_sea
                ? "bold"
                : ""
              : i === props.ltm_air || i === props.ltm_sea
              ? "bold"
              : "",
          }}
        >
          {props.editable ? (
            <TextField
              type="number"
              value={v}
              onChange={(e) => props.onChange(i, Number(e.target.value))}
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
              inputProps={{
                min: 0,
                style: { textAlign: "left", fontSize: "0.875rem" },
              }}
            />
          ) : (
            v.toLocaleString()
          )}
        </TableCell>
      ))}
    </TableRow>
  );
};

export default ModalForecastTableRow;
