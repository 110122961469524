const DecimalFormat = {
  minimumFractionDigits: 0, // 最小桁数
  maximumFractionDigits: 2, // 最大桁数
};

const GridNumberFormatter = (value: any): string => {
  if (typeof value === "number") {
    return value.toLocaleString(undefined, DecimalFormat);
  }
  return "";
};

export default GridNumberFormatter;
