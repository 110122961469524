import {
  Button,
  CardActions,
  CardContent,
  Grid,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";
import { useState, useEffect } from "react";
import PaperCardHalf from "../../atoms/PaperCardHalf";
import { FieldError, SubmitHandler, useForm } from "react-hook-form";
import { useApiGetWithCache } from "../../../hooks/api/useApiGetWithCache";
import { useApiPost } from "../../../hooks/api/useApiPost";
import { useRecoilState } from "recoil";
import { SnackbarState } from "../../../stores/SnackbarState";
import LoadingBackdrop from "../../molecules/LoadingBackdrop";
import CentralizedLoading from "../../atoms/CentralizedLoading";

const salesAverage: string[] = ["Av3M", "Av6M", "Av12M"];
type form_type = Paths.GetSalesAverageDifference.Responses.$200;

const SalesAverageDifferenceCard = () => {
  const { data, loading, mutate } =
    useApiGetWithCache<Paths.GetSalesAverageDifference.Responses.$200>(
      "stm",
      "sales_average_difference",
      {}
    );
  const postRequestAsync =
    useApiPost<Paths.PostSalesAverageDifference.Responses.$200>(
      "sales_average_difference"
    );

  const [, setSnackbar] = useRecoilState(SnackbarState);
  const [requestLoading, setRequestLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<form_type>({
    mode: "onChange",
  });
  const [ratio, setRatio] = useState(data?.ratio || 0);
  const [average1, setAverage1] = useState(data?.sales_average_1 || "");
  const [average2, setAverage2] = useState(data?.sales_average_2 || "");
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (data) {
      setRatio(data.ratio);
      setAverage1(data.sales_average_1);
      setAverage2(data.sales_average_2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setIsDirty(
      !average1 || !average2  ||
      average1 === average2 ||
        (average1 === data?.sales_average_1 &&
          average2 === data?.sales_average_2 &&
          ratio === data?.ratio)
    );
  }, [average1, average2, ratio, data]);

  const handleSave: SubmitHandler<form_type> = async (data) => {
    setRequestLoading(true);
    try {
      const body: Paths.PostSalesAverageDifference.Parameters.SalesAverageDifference =
        {
          sales_average_1: average1,
          sales_average_2: average2,
          ratio: Math.round(ratio), // ToNumber
        };

      const res = await postRequestAsync({ body });
      setSnackbar({
        open: true,
        message: res.message,
        severity: res.code === 200 ? "success" : "error",
      });
      if (res.code === 200) {
        mutate({ ...body });
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        open: true,
        severity: "error",
        message: "[ERROR]Updating parameter is failed.",
      });
    } finally {
      setRequestLoading(false);
    }
  };

  return (
    <PaperCardHalf>
      <LoadingBackdrop loading={requestLoading} />
      <CardContent>
        <Typography fontWeight="bold" fontSize={24}>
          Sales Average Difference Ratio
        </Typography>
        {loading ? (
          <CentralizedLoading />
        ) : (
          <Grid
            mt={4}
            container
            component="form"
            onSubmit={handleSubmit(handleSave)}
            spacing={1}
          >
            <Grid item md={3}>
              <Autocomplete
                {...register("sales_average_1")}
                id="sales-Average1"
                value={
                  average1 !== ""
                    ? salesAverage[salesAverage.indexOf(average1)]
                    : null
                }
                onChange={(_, newValue) => {
                  setAverage1(newValue || "");
                }}
                size="small"
                options={salesAverage}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    helperText={
                      (errors["sales_average_1"] as FieldError)?.message
                    }
                    {...register("sales_average_1")}
                    label="Sales Average"
                  />
                )}
              />
            </Grid>
            <Grid item md={3}>
              <Autocomplete
                {...register("sales_average_2")}
                id="sales-Average2"
                value={
                  average2 !== ""
                    ? salesAverage[salesAverage.indexOf(average2)]
                    : null
                }
                onChange={(_, newValue) => {
                  setAverage2(newValue || "");
                }}
                size="small"
                options={salesAverage}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    helperText={
                      (errors["sales_average_2"] as FieldError)?.message
                    }
                    {...register("sales_average_2")}
                    label="Sales Average"
                  />
                )}
              />
            </Grid>
            <Grid item md={3} marginLeft={2}>
              <TextField
                helperText={(errors["ratio"] as FieldError)?.message}
                {...register("ratio")}
                label="Ratio (%)"
                size="small"
                error={"DifferenceRatio" in errors}
                type="number"
                inputProps={{ step: "1", min: 0, max: 100 }}
                value={ratio}
                sx={{ width: 100 }}
                onChange={(e) => setRatio(Number(e.target.value))}
              />
            </Grid>
          </Grid>
        )}
      </CardContent>
      <CardActions
        sx={{ display: "flex", justifyContent: "right", paddingRight: 2 }}
      >
        <Button
          color="primary"
          variant="contained"
          disabled={isDirty}
          type="submit"
          onClick={handleSubmit(handleSave)}
        >
          Save
        </Button>
      </CardActions>
    </PaperCardHalf>
  );
};

export default SalesAverageDifferenceCard;
