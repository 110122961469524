import { TableCell, TableRow } from "@mui/material";
import React, { VFC } from "react";

interface Props {
  rowId: number;
  values: number[];
  leadTimeMonth: Carrier;
  minimumStockMonth: Carrier;
  isOnlySea: boolean;
}

interface Carrier {
  air: number;
  sea: number;
}

const getMonthBgColor = (props: Props, cellValue: number, index: number) => {
  // back order
  if (cellValue < 0) return "red";
  const { isOnlySea, minimumStockMonth, leadTimeMonth } = props;
  const isAfterLtmAir = index > leadTimeMonth.air;
  return isOnlySea
    ? cellValue < minimumStockMonth.sea
      ? "orange"
      : ""
    : isAfterLtmAir
    ? cellValue < minimumStockMonth.sea
      ? "orange"
      : ""
    : cellValue < minimumStockMonth.air
    ? "orange"
    : "";
};

const ForecastStockMonthTableRow: VFC<Props> = (props) => {
  return (
    <TableRow>
      <TableCell padding="none" size="small" component="th" scope="row">
        Month
      </TableCell>
      {props.values.map((v, i) => (
        <TableCell
          width={60}
          padding="none"
          size="small"
          align="center"
          key={`stk-${props.rowId}-${i}`}
          sx={{
            backgroundColor: getMonthBgColor(props, v, i),
          }}
        >
          {Number(v.toFixed(1)).toLocaleString(undefined, {
            minimumFractionDigits: 1,
            maximumFractionDigits: 2,
          })}
        </TableCell>
      ))}
    </TableRow>
  );
};

export default ForecastStockMonthTableRow;
