import {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from "react";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import {
  Box,
  IconButton,
  Paper,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { useApiGetWithCache } from "../../../hooks/api/useApiGetWithCache";
import useDownloadFile from "../../../hooks/storage/useDownloadFile";
import LoadingBackdrop from "../../molecules/LoadingBackdrop";
import DeleteButton from "../../molecules/StockMovementList/DeleteButton";
import ApplyGrowthAllButton from "../../molecules/StockMovementList/ApplyGrowthAllButton";
import UserGrid from "../../atoms/UserGrid";
import CentralizedLoading from "../../atoms/CentralizedLoading";
import { useRecoilState } from "recoil";
import { UserState } from "../../../stores/UserState";
import HasUpdateAuthority from "../../../functions/HasUpdateAuthority";
import { SortStateReset } from "../../../functions/storage/SortParameters";
import { StmDetailKeysReset } from "../../../functions/storage/StmDetailKeys";
import { useSWRConfig } from "swr";
import {
  DataGridPro,
  GridCellParams,
  GridColDef,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { useNavigate } from "react-router-dom";

type rows = Paths.GetStockMovementList.Responses.$200[0];
type properties = keyof rows;

const HeaderTable = memo(() => {
  const { cache, mutate } = useSWRConfig();
  const [user] = useRecoilState(UserState);
  const hasUpdateAuth = HasUpdateAuthority(
    user?.is_user_update,
    user?.company_code
  );
  const { data, loading } =
    useApiGetWithCache<Paths.GetStockMovementList.Responses.$200>(
      "stm",
      "stockmovements",
      { swrOption: { refreshInterval: 2000 } }
    );

  const [includePOIssued, setIncludePOIssued] = useState(true);
  const rows = useMemo(
    () =>
      includePOIssued ? data : data?.filter((h) => h.status !== "PO Issued"),
    [includePOIssued, data]
  );

  const { downloadAsync } = useDownloadFile();
  const onClickDownload = useCallback(async (fileKey: string) => {
    await downloadAsync(fileKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const navigate = useNavigate();

  const renderGridCreateUser = renderUser("create");
  const renderGridUpdateUser = renderUser("update");
  function renderUser(field: properties) {
    return (
      params: GridCellParams<any, Paths.GetStockMovementList.Responses.$200[0]>
    ): ReactNode => {
      const { user, date } = params.row[field] as rows["create" | "update"];
      return (
        <UserGrid
          user={user.name.trim().length ? user : { name: "deleted", sub: "" }}
          date={date}
          fontsize={"12px"}
        />
      );
    };
  }

  const renderGridActions = (
    params: GridCellParams<any, Paths.GetStockMovementList.Responses.$200[0]>
  ) => {
    const row = params.row;
    return row.status === "Processing" ? (
      <Box display="flex" flexDirection="row">
        <Box display="flex" flexDirection="row">
          <CentralizedLoading size={20} />
        </Box>
        <DeleteButton headerId={row.id} />
      </Box>
    ) : (
      <Box display="flex" flexDirection="row">
        <ApplyGrowthAllButton
          headerId={row.id}
          companyCode={user?.company_code || ""}
          plannerCode={row.planner_code || ""}
          disabled={
            hasUpdateAuth ||
            row.planner_code !== "P" ||
            row.apply_growth_status !== "Not Applied" ||
            row.status === "Error"
          }
          status={row.apply_growth_status}
        />
        <Tooltip title="Download master data">
          <IconButton onClick={() => onClickDownload(row.master_data_key)}>
            <SimCardDownloadIcon color="primary" />
          </IconButton>
        </Tooltip>
        <DeleteButton headerId={row.id} disabled={hasUpdateAuth} />
      </Box>
    );
  };

  const VisiblePoIssuedButton = () => {
    return (
      <>
        <Typography color="#008CA2" fontSize="small">
          PO Issued
        </Typography>
        <Switch
          checked={includePOIssued}
          onChange={(e) => {
            setIncludePOIssued(e.target.checked);
          }}
        />
      </>
    );
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <Typography>|</Typography>
        <VisiblePoIssuedButton />
      </GridToolbarContainer>
    );
  };

  const gridColumns: GridColDef[] = [
    {
      field: "no",
      headerName: "No.",
      minWidth: 95,
    },
    {
      field: "planner_code",
      headerName: "P.Code",
      align: "center",
      width: 60,
    },
    {
      field: "vendor",
      headerName: "Vendor",
      width: 150,
    },
    {
      field: "note",
      headerName: "Note",
      width: 380,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
    },
    {
      field: "create",
      headerName: "Create",
      minWidth: 150,
      type: "date",
      valueGetter: (params: any) => new Date(params.date),
      renderCell: renderGridCreateUser,
    },
    {
      field: "update",
      headerName: "Update",
      minWidth: 150,
      type: "date",
      valueGetter: (params: any) => new Date(params.date),
      renderCell: renderGridUpdateUser,
    },
    {
      field: "master_data_key",
      headerName: "Actions",
      renderCell: renderGridActions,
      minWidth: 130,
      maxWidth: 130,
      sortable: false,
      filterable: false,
    },
  ];

  useEffect(() => {
    SortStateReset();
    StmDetailKeysReset();
    for (const key of cache.keys()) {
      if (key.indexOf("/stm/stockmovement_details/") === 0) {
        mutate(key, undefined, { revalidate: false });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <LoadingBackdrop loading={loading} />
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        {rows?.length ? (
          <DataGridPro
            pagination
            pageSizeOptions={[10]}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 10, page: 0 },
              },
            }}
            slots={{
              toolbar: CustomToolbar,
            }}
            rows={rows}
            columns={gridColumns}
            density="compact"
            onRowDoubleClick={(param: any) => {
              if (
                !(
                  param.row.status === "Processing" ||
                  param.row.apply_growth_status === "Processing"
                )
              ) {
                navigate(`stockmovement/${param.row.id}`);
              }
            }}
          />
        ) : (
          <Typography>Database is stopped.</Typography>
        )}
      </Paper>
    </>
  );
});

export default HeaderTable;
