import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  Switch,
  Stack,
  Tooltip,
  RadioGroup,
  Radio,
  Autocomplete,
} from "@mui/material";
import { useForm, SubmitHandler, Resolver, FieldError } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import UploadFileButton from "../organisms/UploadFileButton";
import { useCallback, useState, useEffect } from "react";
import CreateFormTextField from "../molecules/CreateFormTextField";
import { useApiPost } from "../../hooks/api/useApiPost";
import LoadingBackdrop from "../molecules/LoadingBackdrop";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { SnackbarState } from "../../stores/SnackbarState";
import HelpIcon from "@mui/icons-material/Help";
import { ReactComponent as Logo } from "../../svg/makita_logo.svg";
import { useApiGetWithCache } from "../../hooks/api/useApiGetWithCache";

type request = Paths.CreateStockmovement.Parameters.CreateFormRequest;
type properties = keyof request;

const plannerCodes: string[] = ["M", "A", "P", "O"];

const validatePrice = function (this: any) {
  const { price_from, price_to } = this.parent;
  if (price_from != null && price_to != null) {
    return price_from <= price_to;
  } else {
    return true;
  }
};
const schema = yup.object().shape(
  {
    planner_code: yup.string().required("This field is required."),
    item: yup.string(),
    only_bo: yup.bool(),
    in_alternates: yup.bool(),
    price_from: yup
      .number()
      .nullable()
      .min(0)
      .transform((value, originalValue) =>
        String(originalValue).trim() === "" ? null : value
      )
      .test(
        "price_validation",
        "Enter a value smaller than price_to for price_from.",
        validatePrice
      ),
    price_to: yup
      .number()
      .nullable()
      .min(0)
      .transform((value, originalValue) =>
        String(originalValue).trim() === "" ? null : value
      )
      .test(
        "price_validation",
        "Enter a value greater than price_from for price_to.",
        validatePrice
      ),
    sales_ratio: yup
      .number()
      .min(0)
      .nullable()
      .transform((value, originalValue) =>
        String(originalValue).trim() === "" ? null : value
      ),
    master_data_key: yup.string(),
    alternate_data_key: yup.string(),
  },
  [["price_from", "price_to"]]
);

const CreateStockMovement = () => {
  const [loading, setLoading] = useState(false);
  const postRequestAsync =
    useApiPost<Paths.CreateStockmovement.Responses.$200>("create");

  // Get Vendors info
  const { data } = useApiGetWithCache<Paths.GetVendors.Responses.$200>(
    "stm",
    "vendor",
    {}
  );
  type Options = {
    label: string;
    disabled: boolean;
  };
  
  const [optionVendors, setOptionVendors] = useState<Options[]>([]);
  const [selectedVendorVal, setSelectedVendorVal] = useState<Options[]>([]);
  const [masterDataKey, setMasterDataKey] = useState("");
  const [updated, setUpdated] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSnackbar] = useRecoilState(SnackbarState);
  const navigate = useNavigate();
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<request>({
    resolver: yupResolver(schema) as unknown as Resolver<request, object>,
    mode: "onChange",
  });

  // When master data is uploaded, this function is called.
  const setKey = useCallback((key: string, updatedDone: boolean): void => {
    setMasterDataKey(key);
    setUpdated(updatedDone);
  }, []);

  const textField = (
    id: properties,
    label: string,
    required: boolean = false,
    type: React.HTMLInputTypeAttribute = "text"
  ) => {
    return (
      <CreateFormTextField
        id={id}
        label={label}
        register={register(id)}
        required={required}
        error={id in errors}
        type={type}
        helperText={(errors[id] as FieldError)?.message}
      />
    );
  };

  const onSubmit: SubmitHandler<request> = (data) => {
    // vendor array to string
    data.vendor = selectedVendorVal.map((v) => v.label).join(",");
    const body = { ...data, master_data_key: masterDataKey };
    setLoading(true);
    postRequestAsync({ body })
      .then((res) => {
        setSnackbar({
          open: true,
          message: res.message,
          severity: res.code === 200 ? "success" : "error",
        });
        if (res.code === 200) {
          navigate("/");
        }
      })
      .catch((e) => {
        if (e.name === "AbortError") {
          setSnackbar({
            open: true,
            message: "Request is failed.",
            severity: "error",
          });
        }
      })
      .finally(() => setLoading(false));
  };

  const [onlySea, setOnlySea] = useState(false);
  const [isProduct, setIsProduct] = useState(false);
  const [alternate, setAlternate] = useState(true);
  const setBool = (o: boolean, a: boolean, b: boolean) => {
    setOnlySea(o);
    setValue("only_sea", o);
    setAlternate(a);
    setValue("in_alternates", a);
    setIsProduct(b); // set visibility only Sea
  };

  const handleOnChange = (e:React.SyntheticEvent<Element, Event>, valueArr:Options[]) => {
    setSelectedVendorVal(valueArr);
    if (valueArr.length === 0) {
      const resetOptions = [...optionVendors].map((opt) => {
        opt.disabled = false;
        return opt;
      });
      setOptionVendors(resetOptions);
    } else {
      const isAllSelected = valueArr.find((item) => item.label === "ALL");
      if (isAllSelected) {
        const updatedOptions = [...optionVendors].map((opt) => {
          if (opt.label !== "ALL") opt.disabled = true;
          else opt.disabled = false;
          return opt;
        });
        setOptionVendors(updatedOptions);
        const vendors = valueArr.filter((item) => item.label === "ALL");
        setSelectedVendorVal(vendors);
      }
    }
  };
  useEffect(() => {
    const getOptionVendors = ():Options[] => {
      const vendors: Options[] = data === undefined ? [] : data.vendors.map((vendor) => ({label: vendor.vendor, disabled: false}));
      const allVendors = [...vendors, {label: "ALL", disabled: false}];
      return allVendors;
    }
    setOptionVendors(getOptionVendors());
  },[data]);

  return (
    <>
      <LoadingBackdrop loading={loading} />
      <Grid container spacing={1} alignItems="flex-end">
        <Grid item xs={12} sm={4}>
          <Stack
            direction="column"
            justifyContent="space-around"
            alignItems="flex-end"
          >
            <Box
              sx={{
                backgroundColor: "white",
                maxWidth: "md",
                width: "100%",
                p: 3,
                m: "auto",
                boxShadow: 2,
              }}
            >
              <Box textAlign="left">
                <Logo />
              </Box>
              <Typography
                id="create_stm_report"
                variant="h4"
                fontFamily={"sans-serif"}
                color="primary"
                textAlign="center"
              >
                Create STM Report
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: "white",
                maxWidth: "md",
                width: "100%",
                p: 3,
                boxShadow: 2,
                m: "auto",
                mt: 2,
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    id="master_data_key"
                    label="Choose Masterdata File"
                    variant="standard"
                    value={masterDataKey.replace(/^.*[\\/]/, "")}
                    {...register("master_data_key")}
                    error={"master_data_key" in errors}
                    helperText={errors.master_data_key?.message}
                  />
                </Grid>
                <Grid item xs={4} mt={2}>
                  <UploadFileButton
                    message="Browse"
                    id="upload_master"
                    setKey={setKey}
                  ></UploadFileButton>
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Box
            sx={{
              backgroundColor: "white",
              maxWidth: "xs",
              width: "100%",
              p: 1,
              pl: 3,
              pr: 3,
              boxShadow: 2,
              m: "auto",
              mt: 1,
            }}
          >
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
              sx={{ mt: 1 }}
            >
              <Grid container spacing={1}>
                <Grid item xs={3} mt={1}>
                  <Typography id="planner_code">Planner Code *</Typography>
                </Grid>
                <Grid item xs={9}>
                  <FormControl
                    error={"planner_code" in errors}
                    required
                    component="fieldset"
                    variant="standard"
                    fullWidth
                  >
                    <RadioGroup row defaultValue={"P"}>
                      <Grid container>
                        {plannerCodes.map((v) => (
                          <Grid item xs={2} sm={2} key={v}>
                            <FormControlLabel
                              value={v}
                              label={v}
                              control={
                                <Radio
                                  {...register("planner_code")}
                                  onClick={() =>
                                    v === "M"
                                      ? setBool(true, false, true)
                                      : v === "P"
                                      ? setBool(false, true, false)
                                      : v === "A"
                                      ? setBool(true, false, false)
                                      : setBool(false, false, false)
                                  }
                                />
                              }
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </RadioGroup>
                    <FormHelperText>
                      {errors.planner_code?.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={3} mt={3}>
                  <Typography id="vendors">Vendors *</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Stack sx={{ width: "40vw" }}>
                    <Autocomplete
                      {...register("vendor")}
                      id="tags-standard"
                      multiple
                      disableClearable
                      disablePortal
                      disableCloseOnSelect
                      filterSelectedOptions
                      getOptionLabel={(option) => option.label}
                      getOptionDisabled={(option) => !!option.disabled}
                      onChange={(event, value) => handleOnChange(event, value)}
                      value={selectedVendorVal}
                      options={[...optionVendors].sort((a, b) => a.label.localeCompare(b.label))}
                      isOptionEqualToValue={(option, value) => option.label === value.label}
 
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Choose Vendor"
                        />
                      )}
                    />
                  </Stack>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={4} mt={2}>
                  <FormControlLabel
                    control={
                      <Switch
                        {...register("only_sea")}
                        checked={onlySea}
                        onClick={() => setOnlySea(!onlySea)}
                        disabled={isProduct}
                      />
                    }
                    label="Only SEA"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  {textField("item", "Item")}
                </Grid>
              </Grid>
              <Grid container spacing={1} mt={2}>
                <Grid item xs={12} sm={12} md={4}>
                  <FormControlLabel
                    control={<Switch {...register("only_bo")} />}
                    label="Only B/O"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        {...register("in_alternates")}
                        checked={alternate}
                        onClick={() => setAlternate(!alternate)}
                      />
                    }
                    label={
                      <>
                        Alternate
                        <Tooltip
                          arrow
                          title="Configure the alternate data in advance."
                        >
                          <HelpIcon fontSize="small" color="disabled" />
                        </Tooltip>
                      </>
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <FormControlLabel
                    control={<Switch {...register("apply_stdev")} />}
                    label={
                      <>
                        Standard Deviation
                        <Tooltip
                          arrow
                          title="Configure the standard deviation in advance."
                        >
                          <HelpIcon fontSize="small" color="disabled" />
                        </Tooltip>
                      </>
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={3} sm={3} mt={3}>
                  <Typography id="price_range">Price Range</Typography>
                </Grid>
                <Grid item xs={3} sm={2}>
                  {textField("price_from", "From $", false, "number")}
                </Grid>
                <Typography id="from" variant="h5" mt={3}>
                  -
                </Typography>
                <Grid item xs={3} sm={2}>
                  {textField("price_to", "To $", false, "number")}
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={3}>
                  <Typography id="3m_6m" gutterBottom mt={3}>
                    3M vs 6M Difference
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  {textField("sales_ratio", "Ratio", false, "number")}
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography id="%" gutterBottom mt={3}>
                    %
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={3}>
                  <Typography id="note" gutterBottom mt={2}>
                    Note
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9} mt={1}>
                  <TextField
                    id="standard-multiline-static"
                    multiline
                    fullWidth
                    rows={1}
                    variant="standard"
                    {...register("note")}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 1 }}
                disabled={!(isValid && updated && selectedVendorVal.length)}
              >
                CREATE STM
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default CreateStockMovement;
