import { Badge, CircularProgress, IconButton } from "@mui/material";
import React, { memo, useEffect, useRef, useState, VFC } from "react";
import UserAvatar from "./UserAvatar";
import EditIcon from "@mui/icons-material/Edit";
import ResizeImage from "../../functions/ResizeImage";
import { Storage } from "aws-amplify";
import { useSWRConfig } from "swr";

interface Props {
  userSub: string;
  userName: string;
}

const IconSettings: VFC<Props> = memo(({ userSub, userName }) => {
  const [loading, setLoading] = useState(false);
  const [s3Key, setS3Key] = useState("");
  const uploadInputRef = useRef<HTMLInputElement>(null);
  const { mutate } = useSWRConfig();
  const onFileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.item(0)!;
    const resized = await ResizeImage(file, 512, 512);
    const s3Key = `icon/${userSub}.png`;
    try {
      setLoading(true);
      Storage.put(s3Key, resized, {}).then((res) => {
        setLoading(false);
        setS3Key(res.key);
      });
    } catch (error) {
      console.log(`cannot upload image ${s3Key}`);
      setLoading(false);
    }
  };
  // Firstly mutate method is called in put callback, but it does not working correctly
  useEffect(() => {
    mutate(s3Key);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s3Key]);

  const onClickIcon = () => {
    uploadInputRef.current && uploadInputRef.current.click();
  };
  return loading ? (
    <CircularProgress />
  ) : (
    <>
      <input
        ref={uploadInputRef}
        type="file"
        style={{ display: "none" }}
        accept="image/png, image/jpeg"
        onChange={onFileInputChange}
      />
      <IconButton onClick={onClickIcon}>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          badgeContent={<EditIcon fontSize="large" color="primary" />}
        >
          <UserAvatar userSub={userSub} userName={userName} size={256} />
        </Badge>
      </IconButton>
    </>
  );
});

export default IconSettings;
