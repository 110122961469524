import { useApiPost } from "./useApiPost";

export interface SuggestQty {
  air?: number;
  sea?: number;
}

export const useUpdateSuggestQty = (id: string) => {
  const postRequestAsync = useApiPost<Paths.UpdateSuggestQty.Responses.$200>(
    "suggest_qty",
    id
  );

  const UpdateSuggestQtyAsync = async (
    body: Paths.UpdateSuggestQty.Parameters.SuggestQty
  ): Promise<Paths.UpdateSuggestQty.Responses.$200> => {
    return postRequestAsync({ body });
  };
  return UpdateSuggestQtyAsync;
};
