import {
  Button,
  CardActions,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material"
import { useEffect, useState } from "react"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import PaperCardHalf from "../../atoms/PaperCardHalf"
import { FieldError, Resolver, SubmitHandler, useForm } from "react-hook-form"
import { useApiGetWithCache } from "../../../hooks/api/useApiGetWithCache"
import { useApiPost } from "../../../hooks/api/useApiPost"
import { useRecoilState } from "recoil"
import { SnackbarState } from "../../../stores/SnackbarState"
import LoadingBackdrop from "../../molecules/LoadingBackdrop"
import CentralizedLoading from "../../atoms/CentralizedLoading"

type form_type = Paths.GetMachineSalesGrowth.Responses.$200

const MachineSalesGrowthCard = () => {
  const { data, loading, mutate } =
    useApiGetWithCache<Paths.GetMachineSalesGrowth.Responses.$200>(
      "stm",
      "machine_sales_growth",
      {}
    )
  const postRequestAsync =
    useApiPost<Paths.PostMachineSalesGrowth.Responses.$200>(
      "machine_sales_growth"
    )
  const [, setSnackbar] = useRecoilState(SnackbarState)
  const [requestLoading, setRequestLoading] = useState(false)
  const schema = yup
    .object({
      min: yup.number().min(0).required(),
      max: yup.number().max(3).required(),
    })
    .test("max_gt_min", "", (obj) => {
      if ((obj.max ?? 0) < (obj.min ?? 0) || (obj.max ?? 0) === (obj.min ?? 0)) {
        return new yup.ValidationError(
          "Max have to be greater than min",
          null,
          "max"
        )
      }
      return true
    })
    .test("min_or_max_equal_init", "", (obj) => {
      return (obj.min ?? 0) * 100 !== data?.min || (obj.max ?? 0) * 100 !== data?.max
    })
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm<form_type>({
    resolver: yupResolver(schema) as Resolver<form_type, object>,
    mode: "onChange",
    // defaultValues: { min: data ? data.min / 100 :undefined, max: data ? data.max / 100 : undefined},
  })

  useEffect(() => {
    if (data) {
      setValue("max", data.max / 100, {shouldValidate:true})
      setValue("min", data.min / 100, {shouldValidate:true})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleSave: SubmitHandler<form_type> = async (data) => {
    setRequestLoading(true)
    try {
      const body: Paths.PostMachineSalesGrowth.Parameters.MachineSalesGrowth = {
        min: Math.round(data.min * 100),
        max: Math.round(data.max * 100),
      }
      const res = await postRequestAsync({ body })
      setSnackbar({
        open: true,
        message: res.message,
        severity: res.code === 200 ? "success" : "error",
      })
      if (res.code === 200) {
        mutate({ ...body })
      }
    } catch (error) {
      console.error(error)
      setSnackbar({
        open: true,
        severity: "error",
        message: "[ERROR]Updating parameter is failed.",
      })
    } finally {
      setRequestLoading(false)
    }
  }
  return (
    <PaperCardHalf>
      <LoadingBackdrop loading={requestLoading} />
      <CardContent>
        <Typography fontWeight="bold" fontSize={24}>
          Machine Sales Growth
        </Typography>
        {loading ? (
          <CentralizedLoading />
        ) : (
          <Grid
            mt={4}
            container
            component="form"
            onSubmit={handleSubmit(handleSave)}
            spacing={1}
          >
            <Grid item md={3}>
              <TextField
                required
                {...register("min")}
                label="Min"
                size="small"
                error={"min" in errors}
                helperText={(errors["min"] as FieldError)?.message}
                type="number"
                fullWidth
                inputProps={{ step: "1", min: 0, max: 3}}
              />
            </Grid>
            <Grid item md={3}>
              <TextField
                helperText={(errors["max"] as FieldError)?.message}
                {...register("max")}
                label="Max"
                size="small"
                error={"max" in errors}
                type="number"
                fullWidth
                inputProps={{ step: "1", min: 0, max: 3}}
              />
            </Grid>
          </Grid>
        )}
      </CardContent>
      <CardActions
        sx={{ display: "flex", justifyContent: "right", paddingRight: 2 }}
      >
        <Button
          color="primary"
          variant="contained"
          disabled={!isValid}
          type="submit"
          onClick={handleSubmit(handleSave)}
        >
          Save
        </Button>
      </CardActions>
    </PaperCardHalf>
  )
}

export default MachineSalesGrowthCard
