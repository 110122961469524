import React, { VFC } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useRecoilState } from "recoil";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import BusinessIcon from "@mui/icons-material/Business";
// import FactoryIcon from "@mui/icons-material/Factory";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import ScaleIcon from "@mui/icons-material/Scale";
import InsightsIcon from "@mui/icons-material/Insights";
import { useNavigate } from "react-router-dom";
import { UserState } from "../../stores/UserState";
import FactoryIcon from "@mui/icons-material/Factory";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import HasUpdateAuthority from "../../functions/HasUpdateAuthority";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import { useApiPost } from "../../hooks/api/useApiPost";
import { SnackbarState } from "../../stores/SnackbarState";
import { useState } from "react";
import LoadingBackdrop from "../molecules/LoadingBackdrop";
import { useSWRConfig } from "swr";

type Props = {
  drawerWidth?: number;
  open: boolean;
  closeDrawer: () => void;
};

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

interface Menu {
  text: string;
  path: string;
  icon: React.ReactNode;
  disabled: boolean;
}

const handleOpenNewTab = (path: string) => {
  window.open(path, "_blank");
};

const MenuDrawer: VFC<Props> = ({ open, drawerWidth = 300, closeDrawer }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [user] = useRecoilState(UserState);
  // フラグつきのMJ以外のユーザ→MJからCompnayを変えたユーザである
  const hasUpdateAuth = HasUpdateAuthority(
    user?.is_user_update,
    user?.company_code
  );

  const [loading, setLoading] = useState(false);

  const MpeGroup = ["MPE", "MBO"];
  const isMpeGroupUser =
    MpeGroup.includes(user?.company_code || "") && user?.is_user_update_mpe;
  const isAdminLvUser = user?.is_admin || user?.is_user_update;

  const [, setSnackbar] = useRecoilState(SnackbarState);
  const putRequestAsync = useApiPost<
    Paths.UpdateUser.Responses.$200 | Paths.UpdateUser.Responses.Default
  >("user");

  var company_code = user?.company_code === "MPE" ? "MBO" : "MPE";
  var data = {
    sub: user?.sub, // required
    regions: user?.regions, // required
    primary_region: user?.primary_region, // required
    company_code: company_code,
  };

  const { mutate } = useSWRConfig();
  const handleOnClickMPEGroup = () => {
    setLoading(true);
    putRequestAsync({ body: data })
      .then((res) => {
        setSnackbar({
          open: true,
          message:
            res.code === 200 ? "Success!" : "Error! Reload and try again",
          severity: res.code === 200 ? "success" : "error",
        });
      })
      .then(() => {
        mutate("/stm/user");
        mutate("/stm/memorandum");
        setLoading(false);
      });
  };

  const menuCompanyLevel: Menu[] = [
    {
      text: "Create STM",
      path: "/create",
      icon: <AddCircleIcon />,
      disabled: hasUpdateAuth,
    },
    {
      text: "Vendor Maintenance",
      path: "/vendor_maintenance",
      icon: <FactoryIcon />,
      disabled: hasUpdateAuth,
    },
    {
      text: "Alternate Maintenance",
      path: "/alternate_maintenance",
      icon: <SettingsSuggestIcon />,
      disabled: hasUpdateAuth,
    },
    {
      text: "Weight Maintenance",
      path: "/weight_maintenance",
      icon: <ScaleIcon />,
      disabled: hasUpdateAuth,
    },
    {
      text: "Parameter Maintenance",
      path: "/parameter_maintenance",
      icon: <InsightsIcon />,
      disabled: hasUpdateAuth,
    },
  ];
  const menuAdminLevel: Menu[] = [
    {
      text: "User Maintenance",
      path: "/user_maintenance",
      icon: <ManageAccountsIcon />,
      disabled: false,
    },
    {
      text: "Company Maintenance",
      path: "/company_maintenance",
      icon: <BusinessIcon />,
      disabled: false,
    },
  ];
  const menuDocuments: Menu[] = [
    {
      text: "How to Use(Japanese)",
      path: "/manual/jp",
      icon: <MenuBookIcon />,
      disabled: false,
    },
    {
      text: "How to Use(Spanish)",
      path: "/manual/es",
      icon: <MenuBookIcon />,
      disabled: false,
    },
  ];
  const menuMPEGroup: Menu[] = [
    {
      text: "Switch Company",
      path: "/",
      icon: <SwitchAccountIcon />,
      disabled: false,
    },
  ];

  return (
    <>
      <LoadingBackdrop loading={loading} />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        anchor="left"
        open={open}
        onClose={closeDrawer}
      >
        <DrawerHeader>
          <IconButton onClick={closeDrawer}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menuCompanyLevel.map((m) => (
            <ListItemButton
              key={m.path}
              onClick={() => {
                navigate(m.path);
                closeDrawer();
              }}
              disabled={m.disabled}
            >
              <ListItemIcon>{m.icon}</ListItemIcon>
              <ListItemText primary={m.text} sx={{ cursor: "pointer" }} />
            </ListItemButton>
          ))}
        </List>
        {isAdminLvUser && (
          <>
            <Divider />
            <List>
              {menuAdminLevel.map((m) => (
                <ListItemButton
                  key={m.path}
                  onClick={() => {
                    navigate(m.path);
                    closeDrawer();
                  }}
                  disabled={m.disabled}
                >
                  <ListItemIcon>{m.icon}</ListItemIcon>
                  <ListItemText primary={m.text} sx={{ cursor: "pointer" }} />
                </ListItemButton>
              ))}
            </List>
          </>
        )}
        <Divider />
        <List>
          {/* Open new tab */}
          {menuDocuments.map((m) => (
            <ListItemButton
              key={m.path}
              onClick={() => {
                handleOpenNewTab(m.path);
                closeDrawer();
              }}
              disabled={m.disabled}
            >
              <ListItemIcon>{m.icon}</ListItemIcon>
              <ListItemText primary={m.text} sx={{ cursor: "pointer" }} />
            </ListItemButton>
          ))}
        </List>
        {isMpeGroupUser && (
          <>
            <Divider />
            <List>
              {menuMPEGroup.map((m) => (
                <ListItemButton
                  key={m.path}
                  onClick={() => {
                    navigate(m.path);
                    handleOnClickMPEGroup();
                  }}
                  disabled={m.disabled || loading}
                >
                  <ListItemIcon>{m.icon}</ListItemIcon>
                  <ListItemText primary={m.text} sx={{ cursor: "pointer" }} />
                </ListItemButton>
              ))}
            </List>
          </>
        )}
      </Drawer>
    </>
  );
};

export default MenuDrawer;
