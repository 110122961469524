import { Box, CircularProgress } from "@mui/material";

const CentralizedLoading = ({ size = 40 }) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", margin: "auto 8px" }}>
      <CircularProgress size={size} color="primary" />
    </Box>
  );
};

export default CentralizedLoading;
