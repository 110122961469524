import { Grid, Tooltip, Typography } from "@mui/material";
import { memo } from "react";
import UserAvatar from "../molecules/UserAvatar";

interface Props {
  user: { name: string; sub: string };
  date: string;
  size?: number;
  fontsize?: string;
}

const UserGrid: React.FC<Props> = memo(
  ({ user, date, size = 24, fontsize = "16px" }) => {
    const dateInstance = new Date(date);
    return (
      <>
        <Grid
          sx={{
            display: "flex",
            alignItems: "left",
            flexWrap: "wrap",
            pt: 0.25,
          }}
        >
          <UserAvatar userName={user.name} userSub={user.sub} size={size} />
          <Tooltip
            title={dateInstance.toLocaleDateString(navigator.language, {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
            arrow={true}
          >
            <Typography
              paddingLeft={1}
              sx={{ fontSize: fontsize, margin: "auto" }}
            >
              {dateInstance.toLocaleDateString(navigator.language, {
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
            </Typography>
          </Tooltip>
        </Grid>
      </>
    );
  }
);

export default UserGrid;
