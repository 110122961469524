import React, { VFC } from "react";
import { Storage } from "aws-amplify";
import { Avatar, Tooltip } from "@mui/material";
import useSWR from "swr";

interface Props {
  userSub: string;
  userName: string;
  size?: number;
}

const UserAvatar: VFC<Props> = ({ userSub, userName, size = 32 }) => {
  const s3Key = `icon/${userSub}.png`;
  const fetcher = () => {
    return Storage.get(s3Key, {}).then((url) => url);
  };
  const { data } = useSWR(userSub ? s3Key : null, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 900000, //Set interval as 15min, because the expire limit of pre-sign url is 15min.
  });
  return (
    <Tooltip title={userName || ""} arrow={true}>
      <Avatar
        alt={userName || "avatar"}
        src={data}
        sx={{ width: size, height: size }}
      />
    </Tooltip>
  );
};

export default UserAvatar;
