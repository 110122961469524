import { useCallback, useState } from "react";
import { Storage } from "aws-amplify";

const useDownloadFile = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const downloadAsync = useCallback(async (fileKey: string) => {
    try {
      setLoading(true);
      const key = fileKey.startsWith("public")
        ? fileKey.replace("public/", "")
        : fileKey;
      const result = await Storage.get(key, { download: true });
      downloadBlob(result.Body, fileKey.replace(/^.*[\\/]/, ""));
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);
  return { loading, error, downloadAsync };
};

const downloadBlob = (blob: any, filename: string) => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename || "download";
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener("click", clickHandler);
    }, 150);
  };
  a.addEventListener("click", clickHandler, false);
  a.click();
  return a;
};

export default useDownloadFile;
