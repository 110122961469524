import { TableCell, TableRow } from "@mui/material";
import React, { VFC } from "react";

interface Props {
  rowId: number;
  values: number[];
  leadTimeMonth: Carrier;
  minimumStockMonth: Carrier;
}

interface Carrier {
  air: number;
  sea: number;
}

const ModalForecastStockMonthTableRow: VFC<Props> = (props) => {
  return (
    <TableRow>
      <TableCell
        height="28"
        width="55"
        padding="none"
        size="small"
        component="th"
        scope="row"
      >
        Month
      </TableCell>
      {props.values.map((v, i) => (
        <TableCell
          width={60}
          padding="none"
          size="small"
          align="left"
          key={`stk-${props.rowId}-${i}`}
          sx={{
            backgroundColor:
              v < 0
                ? "red"
                : (i <= props.leadTimeMonth.air &&
                    v < props.minimumStockMonth.air) ||
                  (props.leadTimeMonth.air < i &&
                    v < props.minimumStockMonth.sea)
                ? "orange"
                : "",
          }}
        >
          {Number(v.toFixed(1)).toLocaleString(undefined, {
            minimumFractionDigits: 1,
            maximumFractionDigits: 2,
          })}
        </TableCell>
      ))}
    </TableRow>
  );
};

export default ModalForecastStockMonthTableRow;
