import React, { memo, VFC } from "react";
import ModalSuggestQtyForm from "../../molecules/ItemDetail/ModalSuggestQtyForm";

interface suggestQty {
  qty: number;
  dirty: boolean;
}
interface Props {
  headerId: string;
  id: number;
  vendor: string;
  item: string;
  air: suggestQty;
  setAir: (v: suggestQty) => void;
  sea: suggestQty;
  setSea: (v: suggestQty) => void;
  inputSale: string;
  setInputSaleWrapper: (v: string) => void;
  price: number;
  isOnlySea: boolean;
}
const ModalSuggestQty: VFC<Props> = memo((props) => {
  return <ModalSuggestQtyForm {...props} />;
});

export default ModalSuggestQty;
