import {
  DataGridPro,
  GridColDef,
  GridToolbar,
  GridRowParams,
  GridRowsProp,
  GridActionsCellItem,
} from "@mui/x-data-grid-pro";
import React, { memo, useState, useCallback, useMemo, FC } from "react";
import DeleteWeightDialog from "../../molecules/WeightMaintenance/DeleteWeightDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import LoadingBackdrop from "../../molecules/LoadingBackdrop";
import { CustomGridStringOperators } from "../../atoms/CustomDataGridOperators";
interface Props {
  weights?: Paths.GetWeights.Responses.$200["weights"];
  loading: boolean;
  onClickRow: (id: number) => void;
  handleRefresh: () => void;
}
interface dialog {
  open: boolean;
  weight: {
    weight_id: number;
    item: string;
  };
}

const WeightTable: FC<Props> = memo((props) => {
  const rows: GridRowsProp = useMemo(
    () => props.weights || [],
    [props.weights]
  );
  const [deleteDialog, setDeleteDialog] = useState<dialog>({
    open: false,
    weight: { weight_id: 0, item: "" },
  });
  const handleDelete = (dialog: dialog) => {
    setDeleteDialog(dialog);
  };
  const handleClose = () =>
    setDeleteDialog((prev) => {
      const next = { ...prev };
      next.open = false;
      return next;
    });
  const columns: GridColDef[] = [
    {
      field: "item",
      headerName: "Item",
      width: 300,
      filterOperators: CustomGridStringOperators,
    },
    {
      field: "weight",
      headerName: "Weight",
      width: 120,
      type: "number",
    },
    {
      field: "created",
      headerName: "Created",
      valueGetter: (value): string =>
        new Date(value).toLocaleDateString(navigator.language, {
          year: "numeric",
          month: "short",
          day: "numeric",
        }),
      width: 130,
      headerAlign: "right",
      align: "right",
      disableExport: true,
    },
    {
      field: "actions",
      type: "actions",
      width: 40,
      disableExport: true,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() =>
            handleDelete({
              open: true,
              weight: {
                weight_id: Number(params.id),
                item: params.row.item,
              },
            })
          }
        />,
      ],
    },
  ];
  const handleRowClick = useCallback((row: GridRowParams["row"]) => {
    props.onClickRow(row.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return props.loading ? (
    <LoadingBackdrop loading={props.loading} />
  ) : (
    <>
      <DeleteWeightDialog
        open={deleteDialog.open}
        handleClose={handleClose}
        weight={deleteDialog.weight}
        handleRefresh={props.handleRefresh}
      />
      <DataGridPro
        rows={rows}
        rowHeight={30}
        columns={columns}
        slots={{
          toolbar: GridToolbar,
        }}
        density="compact"
        onRowClick={(params: GridRowParams) => handleRowClick(params)}
      />
    </>
  );
});

export default WeightTable;
