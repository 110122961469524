import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Stack,
  TextField,
} from "@mui/material";
import { memo, useEffect, useState, VFC } from "react";
import { SubmitHandler, useForm, useFormState } from "react-hook-form";
import { useRecoilState } from "recoil";
import { useSWRConfig } from "swr";
import { useApiPost } from "../../../hooks/api/useApiPost";
import { SnackbarState } from "../../../stores/SnackbarState";

type companyType = Paths.GetMasterdata.Responses.$200["companies"][0];

interface Props {
  company: companyType | undefined;
  mode?: "update" | "create";
}

const CompanyForm: VFC<Props> = memo((props) => {
  const [company, setCompany] = useState<companyType>();
  const { register, handleSubmit, reset, control } = useForm<companyType>({
    mode: "onChange",
    defaultValues: company,
  });
  const { isDirty } = useFormState({ control });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSnackbar] = useRecoilState(SnackbarState);
  const { mutate } = useSWRConfig();

  useEffect(() => {
    setCompany(props.company);
    reset(props.company);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.company]);

  const mode = props.mode || "update";
  const readOnly = mode === "update";

  const postRequestAsync =
    useApiPost<Paths.CreateCompany.Responses.$200>("company");

  const onSubmit: SubmitHandler<companyType> = (body) => {
    postRequestAsync({ body })
      .then((res) => {
        setSnackbar({
          open: true,
          message: res.message,
          severity: res.code === 200 ? "success" : "error",
        });
        mutate("/stm/master_data");
      })
      .catch((e) => console.log(e));
  };
  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      padding={8}
      boxShadow={1}
      borderRadius={1}
      bgcolor="paper"
    >
      <Stack spacing={3}>
        <FormControl>
          <TextField
            required
            type="text"
            label="Code"
            {...register("code")}
            disabled={readOnly}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>

        <FormControl>
          <TextField
            required
            label="Name"
            {...register("name")}
            disabled={readOnly}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <FormControl>
          <FormControlLabel
            control={<Checkbox {...register("is_admin")} />}
            label="Administrator"
          />
        </FormControl>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 2, mb: 2 }}
          disabled={mode === "update" || !isDirty}
        >
          {mode === "update" ? "Update" : "Create"}
        </Button>
      </Stack>
    </Box>
  );
});

export default CompanyForm;
