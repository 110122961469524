import { Fab, Grid } from "@mui/material";
import { useMemo, useState } from "react";
import { useApiGetWithCache } from "../../hooks/api/useApiGetWithCache";
import LoadingBackdrop from "../molecules/LoadingBackdrop";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import VendorList from "../organisms/VendorMaintenance/VendorList";
import VendorForm from "../organisms/VendorMaintenance/VendorForm";

const VendorMaintenance = () => {
  const [vendor, setVendor] = useState({ vendor: "", name: "" });
  const { data, loading, } =
    useApiGetWithCache<Paths.GetVendors.Responses.$200>("stm", "vendor", {});
  const targetVendor = useMemo(
    () =>
      data?.vendors?.find((f) => f.vendor === vendor.vendor) || {
        company: "",
        vendor: "",
        name: "",
      },
    [data, vendor]
  );
  const [mode, setMode] = useState<"update" | "create">("update");
  const onClickRow = (vendor: string) => {
    setVendor({ vendor: vendor, name: "" });
    setMode("update");
  };
  const onClickCreate = () => {
    setVendor({ vendor: "", name: "" });
    setMode("create");
  };
  return (
    <>
      <LoadingBackdrop loading={loading} />
      <Grid container spacing={1}>
        <Grid item xs={12} md={6} minHeight={800}>
          <VendorList vendors={data?.vendors} onClickRow={onClickRow} />
        </Grid>
        <Grid item xs={12} md={6}>
          <VendorForm mode={mode} vendor={targetVendor} />
        </Grid>
      </Grid>
      <Fab
        color="primary"
        aria-label="new"
        sx={{
          position: "fixed",
          bottom: "96px",
          right: "16px",
        }}
        onClick={() => onClickCreate()}
      >
        <AddBusinessIcon />
      </Fab>
    </>
  );
};

export default VendorMaintenance;
