import LoadingButton from "@mui/lab/LoadingButton";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import React, { memo, useRef, VFC } from "react";
import useUploadFile from "../../hooks/storage/useUploadFile";
import { Grid } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";

interface Props {
  message: string;
  id: string;
  setKey?: (arg: string, updatedDone: boolean) => void;
  accept?: string;
}
const UploadFileButton: VFC<Props> = memo((props) => {
  const uploadInputRef = useRef<HTMLInputElement>(null);
  const { error, loading, progress, uploadDataAsync } = useUploadFile();

  const onFileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    uploadDataAsync(event.target.files?.item(0)!).then((s3key) => {
      if (props.setKey) {
        if (s3key === "err") {
          props.setKey("Sorry, Please Reload and Try again.", false);
        } else {
          props.setKey(s3key, !error && !loading);
        }
      }
    });
  };

  return (
    <>
      <Grid container direction="column">
        <input
          ref={uploadInputRef}
          type="file"
          style={{ display: "none" }}
          id={props.id}
          accept={props.accept || ".xlsx, .csv"}
          onChange={onFileInputChange}
        />
        <LoadingButton
          onClick={() =>
            uploadInputRef.current && uploadInputRef.current.click()
          }
          endIcon={<FileUploadIcon />}
          loading={loading}
          loadingPosition="end"
          variant="contained"
        >
          {props.message}
        </LoadingButton>
        {loading && <LinearProgress variant="determinate" value={progress} />}
      </Grid>
    </>
  );
});

export default UploadFileButton;
