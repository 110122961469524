const CalcPalletQty = (suggestQty: number, pallet: number): number => {
  const palletSuggestQty = suggestQty / pallet;
  if (suggestQty <= 0) {
    return 0;
  } else if (suggestQty < pallet) {
    return pallet;
  } else {
    return suggestQty % (pallet || 1) === 0
      ? suggestQty
      : Math.round(palletSuggestQty) * pallet;
  }
};

export default CalcPalletQty;
