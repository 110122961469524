import CalcAverageNum from "../functions/CalcAverageNum";

export const CalcGrowthRateAdjust = (
  minRate: number,
  maxRate: number,
  growthRate: number
): number => {
  minRate = minRate / 100;
  maxRate = maxRate / 100;
  const fixGrowthRate =
    (minRate ?? 0) > growthRate
      ? minRate ?? 0
      : (maxRate ?? 0) < growthRate
      ? maxRate ?? 0
      : Math.round(growthRate * 10) / 10;

  return fixGrowthRate;
};

export const CalcGrowthRate = (sales: number[] | undefined): number => {
  return sales
    ? CalcAverageNum(sales?.slice(3)) /
        (CalcAverageNum(sales?.slice(0, 9)) || 1)
    : 1;
};
