import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { memo, VFC } from "react";
import { useApiGetWithCache } from "../../hooks/api/useApiGetWithCache";
import { DrawingModel } from "../../models/drawing_model";
import DrawingSVG from "./DrawingSVG";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  drawingModel: DrawingModel;
  primaryRegion: string;
}
const DrawingModal: VFC<Props> = memo((props) => {
  const queryParameters: Paths.GetModels.QueryParameters = {
    parts: props.drawingModel.parts,
    spec_code: props.drawingModel.specCode,
    region: props.drawingModel.region.join("y"), // y is param splitter
    primary_region: props.primaryRegion,
    locale: props.drawingModel.locale || "en-US",
  };
  const { data, loading, error } =
    useApiGetWithCache<Paths.GetModels.Responses.$200>(
      "makmate",
      "models",
      { queryStringParameters: queryParameters },
      props.drawingModel.model
    );
  if (error) {
    props.setOpen(false);
    return <Typography>Data Not Found</Typography>;
  }
  const handleClose = () => props.setOpen(false);
  return (
    <Dialog
      open={props.isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-describedby="alert-dialog-slide-description"
      fullWidth={true}
      maxWidth="lg"
    >
      <DialogTitle>{props.drawingModel?.model}</DialogTitle>
      <DialogContent sx={{ height: "70vh" }}>
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <>
            <Box style={{ position: "absolute", zIndex: 1 }} width="95%">
              <img src={data?.layer1} alt="layer1" />
            </Box>
            <DrawingSVG
              url={data!.layer2}
              drawingNo={data!.drawing_no}
              color={data!.color}
            />
          </>
        )}
      </DialogContent>
    </Dialog>
  );
});

export default DrawingModal;
