export const LocalStorageGet = <T>(key: string): T | null => {
  const json = localStorage.getItem(key);
  if (json === "") {
    return null;
  }
  try {
    const obj: T = JSON.parse(json || "{}");
    return obj;
  } catch (error) {
    return null;
  }
};
