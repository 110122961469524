import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Alert,
} from "@mui/material";
import { memo, useEffect, useState, VFC } from "react";
import { SubmitHandler, useForm, Resolver } from "react-hook-form";
import { useRecoilState } from "recoil";
import SignUpAsync from "../../../functions/auth/SignUpAsync";
import { useApiPost } from "../../../hooks/api/useApiPost";
import { SnackbarState } from "../../../stores/SnackbarState";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

type userType = Paths.GetMasterdata.Responses.$200["users"][0] & {
  password?: string;
};
type companiesType = Paths.GetMasterdata.Responses.$200["companies"];

interface Props {
  user: userType | undefined;
  companies: companiesType;
  mode?: "update" | "create";
  handleRefresh: () => void;
}

const Regions = [
  "AR",
  "CL",
  "CO",
  "MX",
  "EC",
  "PA",
  "DM",
  "US",
  "PE",
  "BR",
  "DE",
  "AU",
];

const UserForm: VFC<Props> = memo((props) => {
  const [user, setUser] = useState<userType>();
  const [dirty, setDirty] = useState(false);
  const [primaryRegions, setPrimaryRegions] = useState<string[]>([]);
  const [showPassword, setShowPassword] = useState(false);
  const [, setSnackbar] = useRecoilState(SnackbarState);
  const [errMessage, setErrMessage] = useState("");

  const schema = yup.object().shape({
    email: yup.string().email(),
  });

  const { register, handleSubmit, reset, formState: { errors },} = useForm<userType>({
    resolver: yupResolver(schema) as unknown as Resolver<userType, object>,
    defaultValues: user,
  });
  const putRequestAsync = useApiPost<
    Paths.UpdateUser.Responses.$200 | Paths.UpdateUser.Responses.Default
  >("user");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const mode = props.mode || "update";
  const readOnly = mode === "update";
  const defaultPrimaryRegionArray = props.user?.regions ?? [""];
  const primaryRegionArray = defaultPrimaryRegionArray;

  useEffect(() => {
    setErrMessage("");
    setPrimaryRegions(primaryRegionArray);
    setUser({ ...props.user!, password: "" });
    reset(props.user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user]);

  useEffect(() => {
    setDirty(false);
  }, [mode]);

  // Get From Server
  const companies = props.companies?.map((company) => company.code);
  const onSubmit: SubmitHandler<userType> = (data) => {
    setErrMessage("");
    if (mode === "create") {
      SignUpAsync({
        username: data.email,
        password: data.password!,
        attributes: {
          email: data.email,
          given_name: data.given_name,
          family_name: data.family_name,
        },
      })
        .then((res) => {
          const createUser = { ...data, sub: res.userSub };
          putRequestAsync({ body: createUser })
            .then((res) => {
              setSnackbar({
                open: true,
                message: res.message,
                severity: res.code === 200 ? "success" : "error",
              });
              if (res.code === 200) {
                reset();
              }
            })
            .catch((e) => setErrMessage(e.toString()));
          setDirty(false);
          props.handleRefresh();
        })
        .catch((e) => setErrMessage(e.toString()));
    } else {
      putRequestAsync({ body: data })
        .then((res) => {
          setSnackbar({
            open: true,
            message: res.message,
            severity: res.code === 200 ? "success" : "error",
          });
          props.handleRefresh();
        })
        .catch((e) => setErrMessage(e.toString()));
      setDirty(false);
    }
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChangeCompany = (event: SelectChangeEvent) => {
    setUser({ ...user!, company_code: event.target.value as string });
    setDirty(true);
  };

  const handleChangeRegions = (event: SelectChangeEvent) => {
    const regions =
      typeof event.target.value === "string"
        ? event.target.value.split(",")
        : event.target.value;
    setPrimaryRegions(regions);
    let primaryRegion : string = user!.primary_region;
    if (regions.length === 0 || !regions.includes(user!.primary_region)) {
      primaryRegion = "";
    }
    setUser({
      ...user!,
      regions: regions,
      primary_region: primaryRegion,
    });
    regions.length !== 0 && primaryRegion !== "" ? setDirty(true) : setDirty(false);
  };

  const handleChangePrimaryRegion = (event: SelectChangeEvent) => {
    setUser({
      ...user!,
      primary_region: event.target.value,
    });
    event.target.value !== "" ? setDirty(true) : setDirty(false);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      padding={3}
      boxShadow={1}
      borderRadius={1}
      bgcolor="paper"
    >
      <Stack spacing={3}>
        <FormControl required>
          <InputLabel id="company-label">Company</InputLabel>
          <Select
            labelId="company-label"
            label="Company"
            value={user?.company_code || ""}
            {...register("company_code")}
            onChange={handleChangeCompany}
            required
          >
            {companies?.map((company) => (
              <MenuItem key={company} value={company}>
                {company}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {mode === "create" && (
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Temporary Password*
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              required
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Temporary Password*"
              {...register("password")}
            />
          </FormControl>
        )}
        <FormControl>
          <TextField
            required
            label="Email"
            {...register("email")}
            disabled={readOnly}
            error={"email" in errors}
            helperText={errors.email?.message}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <FormControl>
          <TextField
            required
            type="text"
            label="Given Name"
            {...register("given_name")}
            disabled={readOnly}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <FormControl>
          <TextField
            required
            label="Family Name"
            {...register("family_name")}
            disabled={readOnly}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <FormControl required>
          <InputLabel id="regions-label">Regions</InputLabel>
          <Select
            labelId="regions-label"
            label="Regions"
            multiple
            value={(user?.regions || []) as any}
            {...register("regions")}
            onChange={handleChangeRegions}
          >
            {Regions.sort().map((region) => (
              <MenuItem key={region} value={region}>
                {region}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl required disabled={primaryRegions.length <= 0}>
          <InputLabel id="regions-label">PrimaryRegion</InputLabel>
          <Select
            labelId="regions-label"
            label="PrimaryRegion"
            value={(user?.primary_region || "") as any}
            {...register("primary_region")}
            onChange={handleChangePrimaryRegion}
          >
            {primaryRegions.sort().map((region) => (
              <MenuItem key={region} value={region}>
                {region}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ display: "none" }}>
          <TextField type="hidden" label="Sub" {...register("sub")} />
        </FormControl>
        {errMessage.length > 0 && <Alert severity="error">{errMessage}</Alert>}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 2, mb: 2 }}
          disabled={!dirty}
        >
          {mode === "update" ? "Update" : "Create"}
        </Button>
      </Stack>
    </Box>
  );
});

export default UserForm;
