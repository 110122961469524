import useSWR from "swr";
import { API } from "aws-amplify";
import { ApiRequest } from "../../models/util/api_request_model";

const API_NAME = "apie413e623";

export const useApiGetWithCache = <T>(
  basePath: "stm" | "makmate",
  path:
    | "user"
    | "master_data"
    | "stockmovements"
    | "stockmovement_details"
    | "stockmovement_details_header"
    | "parts"
    | "models"
    | "suggest_quantities"
    | "memorandum"
    | "alternates"
    | "engineering_change"
    | "suggest_qty"
    | "vendor"
    | "weights"
    | "std_devs"
    | "machine_sales_growth"
    | "sales_average_difference"
    | "create_excel_progress",
  request: ApiRequest,
  pathParameter?: string,
  condition: boolean = true
) => {
  const url = pathParameter
    ? `/${basePath}/${path}/${pathParameter}`
    : `/${basePath}/${path}`;

  const fetcher = async (): Promise<T> => {
    return (await API.get(API_NAME, url, request)) as Promise<T>;
  };
  const { data, error, mutate } = useSWR<T>(
    condition ? url : null,
    fetcher,
    request.swrOption
  );

  return { data, error, loading: !error && !data, url, mutate };
};
