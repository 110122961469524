import * as React from "react";
import Card, { CardProps } from "@mui/material/Card";

const PaperCard = ({ className, ...props }: CardProps) => (
  <Card
    {...props}
    classes={{ root: className }}
    sx={{
      background: "#FAFAFA",
      height: 570,
      boxShadow: "rgba(0, 0, 0, 0.2) 1px 1px 1px 1px;",
    }}
  />
);

export default PaperCard;
