import { TextField } from "@mui/material";
import React, { VFC } from "react";
import { UseFormRegisterReturn } from "react-hook-form/dist/types/form";

interface Props {
  id: string;
  label: string;
  register: UseFormRegisterReturn;
  required?: boolean;
  error?: boolean;
  type?: React.HTMLInputTypeAttribute;
  helperText?: string;
}

const CreateFormTextField: VFC<Props> = (props) => {
  return (
    <TextField
      autoComplete={props.id}
      required={props.required}
      fullWidth
      id={props.id}
      label={props.label}
      variant="standard"
      {...props.register}
      error={props.error}
      type={props.type}
      helperText={props.helperText}
    />
  );
};

export default CreateFormTextField;
