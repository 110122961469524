
import { LocalStorageGet } from "./LocalStorageGet";
import { LocalStorageSet } from "./LocalStorageSet";
import { LocalStorageDelete } from "./LocalStorageDelete";

const key = "web-stm-sortparam-storage213vfv2";

export const SortStateSet = (param:string) => {
  LocalStorageSet({ key: key, obj: param });
};

export const SortStateGet = () => {
  return LocalStorageGet<string>(key);
};

export const SortStateReset = () => {
  LocalStorageDelete(key);
}