import { Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useApiPost } from "../../../hooks/api/useApiPost";
import { SnackbarState } from "../../../stores/SnackbarState";
import { useSWRConfig } from "swr";
import UploadFileButton from "../UploadFileButton";

const UploadWeight = () => {
  const [weightDataKey, setWeightDataKey] = useState("");
  const [snackbar, setSnackbar] = useRecoilState(SnackbarState);
  const { mutate } = useSWRConfig();
  const postRequestAsync =
    useApiPost<Paths.CreateWeights.Responses.$200>("weights");
  useEffect(() => {
    if (weightDataKey.length > 0) {
      const body: Paths.CreateWeights.Parameters.CreateWeightsRequest = {
        master_data_key: weightDataKey,
      };
      postRequestAsync({ body }).then((r) => {
        setSnackbar({
          ...snackbar,
          open: true,
          message: r.message,
          severity: r.code === 200 ? "success" : "error",
        });
        mutate("/stm/weights");
        setWeightDataKey("");
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weightDataKey]);
  return (
    <Grid
      container
      sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
      mb={2}
    >
      <Grid item xs={12} md={4} pl={2}>
        <TextField
          fullWidth
          id="weight_data_key"
          label="Choose Weight File"
          variant="standard"
          value={weightDataKey.replace(/^.*[\\/]/, "")}
        />
      </Grid>
      <Grid item xs={10} md={1}>
        <UploadFileButton
          message="Browse"
          setKey={setWeightDataKey}
          id={"weight_upload"}
        />
      </Grid>
    </Grid>
  );
};

export default UploadWeight;
