import { useState } from "react";
import { Auth, Storage } from "aws-amplify";
import { User } from "../../models/user_model";
import convertCsvToExcel from "./convertCsvToExcel";

interface UploadResponse {
  key: string;
  error: any;
  loading: boolean;
  progress: number;
  uploadDataAsync: (file: File) => Promise<string>;
}

const useUploadFile = (): UploadResponse => {
  const [key, setKey] = useState("");
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const uploadDataAsync = async (
    file: File,
    level: "public" | "protected" | "private" = "public"
  ): Promise<string> => {
    setLoading(true);
    try {
      if (file.name.split(".").pop() === "csv") {
        file = await convertCsvToExcel(file);
      }
      const user = (await Auth.currentUserInfo()) as User;
      if (Object.keys(user).length === 0) {
        var err = new Error("User does not found");
        setError(err);
        setLoading(false);
        return "err";
      }

      const s3Key = `${user.attributes.sub}/${new Date()
        .toISOString()
        .replaceAll(":", "")
        .replaceAll("-", "")}/${file.name}`;
      const result = await Storage.put(s3Key, file, {
        level: level,
        // resumable: true,
        completeCallback: (event) => {
          setLoading(false);
          setKey(event.key || "");
          return;
        },
        progressCallback: (progress) => {
          console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
          setProgress((progress.loaded / progress.total) * 100);
        },
        errorCallback: (err: any) => {
          setError(err);
          setLoading(false);
          return;
        },
      });
      setLoading(false);
      const key = level === "public" ? `public/${result.key}` : result.key; // If publish level is public, the key returned does not have public preffix as s3 key.
      setKey(key);
      return key;
    } catch (error) {
      setError(error);
      return "";
    }
  };
  return { key, error, loading, progress, uploadDataAsync };
};

export default useUploadFile;
