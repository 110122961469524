import { FilteredDetails } from "../../stores/FiltererDetailsState";
import { LocalStorageGet } from "./LocalStorageGet";
import { LocalStorageSet } from "./LocalStorageSet";

const key = "web-stm-filterdetail-storage213vfv2";

export const FilterDetailsSet = (filterDetails: FilteredDetails) => {
  LocalStorageSet({ key: key, obj: filterDetails });
};

export const FilterDetailsGet = () => {
  return LocalStorageGet<FilteredDetails>(key);
};
