import { API } from "aws-amplify";
import { useCallback } from "react";
import { ApiRequest } from "../../models/util/api_request_model";

const API_NAME = "apie413e623";
const BASE_PATH = "stm";

export const useApiPost = <T>(
  path:
    | "create"
    | "create_po"
    | "suggest_qty"
    | "user"
    | "company"
    | "stockmovement_status"
    | "memorandum"
    | "alternates"
    | "alternate"
    | "detail"
    | "vendor"
    | "weights"
    | "weight"
    | "std_devs"
    | "machine_sales_growth"
    | "sales_average_difference"
    | "ocf_forecast"
    | "suggest_quantities"
    | "suggest_qty_transaction"
    // bulk
    | "growth"
    | "print",
  pathParameter?: string,
  isBulk?: boolean
) => {
  var url = "";
  if (isBulk) {
    url = `/bulk/${path}`;
  } else {
    url = pathParameter
      ? `/${BASE_PATH}/${path}/${pathParameter}`
      : `/${BASE_PATH}/${path}`;
  }

  const postRequestAsync = useCallback(
    async (request: ApiRequest) => {
      return API.post(API_NAME, url, request) as Promise<T>;
    },
    [url]
  );
  return postRequestAsync;
};
