import { SelectRow } from "../../stores/SelectRowState";
import { LocalStorageGet } from "./LocalStorageGet";
import { LocalStorageSet } from "./LocalStorageSet";

const key = "web-stm-rowid-storage213vfv2";

export const SelectRowStateSet = (row:SelectRow) => {
  LocalStorageSet({ key: key, obj: row });
};

export const SelectRowStateGet = () => {
  return LocalStorageGet<SelectRow>(key);
};
