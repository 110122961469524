import React, { memo, VFC } from "react";
import SuggestQtyForm from "../../molecules/ItemDetail/SuggestQtyForm";

interface suggestQty {
  qty: number;
  dirty: boolean;
}
interface Props {
  headerId: string;
  id: number;
  vendor: string;
  item: string;
  air: suggestQty;
  setAir: (v: suggestQty) => void;
  savedAir: number;
  sea: suggestQty;
  setSea: (v: suggestQty) => void;
  savedSea: number;
  price: number;
  isOnlySea: boolean;
  isApplyGrowth: boolean;
}
const SuggestQty: VFC<Props> = memo((props) => {
  return <SuggestQtyForm {...props} />;
});

export default SuggestQty;
