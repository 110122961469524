import { Box, Grid, Grow, Typography, Tooltip } from "@mui/material";
import React, { memo, VFC, useState } from "react";
import AirplaneWhiteIcon from "../../atoms/AirplaneWhiteIcon";
import BoatWhiteIcon from "../../atoms/BoatWhiteIcon";
import CalcAverage from "../../../functions/CalcAverage";
import LoadingBackdrop from "../../molecules/LoadingBackdrop";
import CalcLayerQty from "../../../functions/CalcLayerQty";

interface Props {
  detail?: Paths.GetStockMovementDetails.Responses.$200["details"][0];
  isOnlySea: boolean;
  growthRate: number;
}

const ItemInformation: VFC<Props> = memo((props) => {
  const { detail, isOnlySea, growthRate } = props;
  const [checked] = useState(true);
  const numberFormatToLocaleStr = (num: number, digit: number) => {
    var v = num || 0;
    return Number(v.toFixed(digit)).toLocaleString();
  };

  const numberFormat = (num: number, digit: number) => {
    var v = num || 0;
    return v === 0 ? "" : v.toFixed(digit);
  };

  return !detail ? (
    <LoadingBackdrop loading />
  ) : (
    <Grid container spacing={0} maxWidth="100%">
      <Grid item xs={12} sm={4}>
        <Grid item xs={12}>
          <Grow in={checked === true} {...(checked ? { timeout: 1000 } : {})}>
            <Box
              sx={{
                backgroundColor:
                  String(detail.status).includes("D") ||
                  (detail.alternate && detail.alternate.includes("[To]"))
                    ? "rgba(10,10,10,0.5)"
                    : "#FFF4CC",
                color: "rgba(2,53,53,1)",
                width: "100%",
                height: "14vh",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                wordWrap: "break-word",
                px: 2,
              }}
            >
              <Grid item xs zeroMinWidth>
                <Typography
                  fontSize={detail.item.length <= 15 ? 28 : 20}
                  fontWeight="bold"
                  align="center"
                >
                  {detail.item}
                </Typography>
                <Typography
                  title={detail.item_description || ""}
                  fontSize={(detail.item_description?.length || 1) <= 26 ? 15 : 13}
                  sx={{ 
                    textWrap: "nowrap", 
                    overflow: "hidden", 
                    textOverflow: "ellipsis" 
                  }}
                >
                  {detail.item_description || ""}
                </Typography>
                <Typography
                  fontSize={
                    (detail.memo?.length || 1) > 30
                      ? 10
                      : (detail.memo?.length || 1) <= 30 &&
                        (detail.memo?.length || 1) > 10
                      ? 12
                      : 16
                  }
                  boxSizing="border-box"
                  align="center"
                >
                  {!detail.memo ? "" : "Memo: " + detail.memo}
                </Typography>
              </Grid>
            </Box>
          </Grow>
        </Grid>
        <Grid item xs={12}>
          <Grow in={checked === true} {...(checked ? { timeout: 1000 } : {})}>
            <Box
              sx={{
                backgroundColor: "rgba(2,53,53,1)",
                color: "#FAFAFA",
                width: "100%",
                height: "14vh",
                display: "flex",
                alignItems: "center",
                wordWrap: "break-word",
                p: 2,
              }}
            >
              <Grid item xs={5}>
                <Typography fontSize={13} boxSizing="border-box" align="left">
                  Planner : {detail.planner_code}
                </Typography>
                <Typography fontSize={13} boxSizing="border-box" align="left">
                  Status : {detail.status}
                </Typography>
                <Typography fontSize={13} align="left">
                  Weight : {numberFormat(detail.weight || 0, 4)}
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography fontSize={13} boxSizing="border-box" align="left">
                  Flag : {detail.flag || ""}
                </Typography>
                <Typography fontSize={13} boxSizing="border-box" align="left">
                  Vendor: {detail.vendor}
                </Typography>
                <Typography fontSize={13} boxSizing="border-box" align="left">
                  Price : {numberFormat(detail.price || 0, 2)}
                </Typography>
              </Grid>
            </Box>
          </Grow>
        </Grid>
        <Grid item xs={12}>
          <Grow in={checked === true} {...(checked ? { timeout: 1000 } : {})}>
            <Box
              sx={{
                backgroundColor: "rgba(2,53,53,0.8)",
                color: "#FAFAFA",
                width: "100%",
                height: "7vh",
                display: "flex",
                alignItems: "center",
                wordWrap: "break-word",
                p: 2,
              }}
            >
              <Grid item xs={5}>
                <Typography fontSize={11} align="left">
                  Location : {detail.location || ""}
                </Typography>
                <Typography fontSize={11} align="left">
                  Category : {detail.category}
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography
                  fontSize={(detail.alternate?.length || 1) > 16 ? 10 : 11}
                  align="left"
                >
                  Alternate : {detail.alternate || ""}
                </Typography>
                <Typography fontSize={11} align="left">
                  Stdev Rate : {(detail.stdev_rate || 0) / 100 || ""}
                </Typography>
              </Grid>
            </Box>
          </Grow>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={3}>
        <Grid item xs={12}>
          <Grow in={checked === true} {...(checked ? { timeout: 1000 } : {})}>
            <Box
              sx={{
                backgroundColor: "rgba(1,89,88,1)",
                color: "#FAFAFA",
                width: "100%",
                height: "7vh",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                p: 1,
              }}
            >
              <Grid item xs zeroMinWidth>
                <Typography fontSize={13} align="center">
                  Lead Time
                </Typography>
                <Typography noWrap fontSize={15} align="center">
                  {isOnlySea ? "" : <AirplaneWhiteIcon />}
                  {isOnlySea ? "" : detail.ltm_air || 0}
                  <BoatWhiteIcon />
                  {detail.ltm_sea || 0}
                </Typography>
              </Grid>
            </Box>
          </Grow>
        </Grid>
        <Grid item xs={12}>
          <Grow in={checked === true} {...(checked ? { timeout: 1000 } : {})}>
            <Box
              sx={{
                backgroundColor: "rgba(1,89,88,0.9)",
                color: "#FAFAFA",
                width: "100%",
                height: "7vh",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                p: 1,
              }}
            >
              <Grid item xs zeroMinWidth>
                <Typography fontSize={13} align="center">
                  Min Stock Month
                </Typography>
                <Typography noWrap fontSize={15} align="center">
                  {isOnlySea ? "" : <AirplaneWhiteIcon />}
                  {isOnlySea ? "" : detail.msm_air || 0}
                  <BoatWhiteIcon />
                  {detail.msm_sea || 0}
                </Typography>
              </Grid>
            </Box>
          </Grow>
        </Grid>
        <Grid item xs={12}>
          <Grow in={checked === true} {...(checked ? { timeout: 1000 } : {})}>
            <Box
              sx={{
                backgroundColor: "rgba(1,89,88,0.8)",
                color: "#FAFAFA",
                width: "100%",
                height: "7vh",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                p: 1,
              }}
            >
              <Grid item xs={(detail.dmb_air || 1) >= 1000 ? 5 : 6}>
                <Typography fontSize={13} align="center">
                  SMB
                </Typography>
                <Typography noWrap fontSize={15} align="center">
                  {numberFormatToLocaleStr(detail.smb || 0, 1)}
                </Typography>
              </Grid>
              <Grid item xs={(detail.dmb_air || 1) >= 1000 ? 7 : 6}>
                <Typography fontSize={13} align="center">
                  DMB
                </Typography>
                <Typography
                  noWrap
                  fontSize={(detail.dmb_air || 1) >= 100 ? 11 : 15}
                  align="center"
                >
                  {isOnlySea ? "" : <AirplaneWhiteIcon />}
                  {isOnlySea
                    ? ""
                    : numberFormatToLocaleStr(detail.dmb_air || 0, 1)}
                  <BoatWhiteIcon />
                  {numberFormatToLocaleStr(detail.dmb_sea || 0, 1)}
                </Typography>
              </Grid>
            </Box>
          </Grow>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={6}>
              <Grow
                in={checked === true}
                {...(checked ? { timeout: 1000 } : {})}
              >
                <Box
                  sx={{
                    backgroundColor: "rgba(1,89,88,0.7)",
                    color: "#FAFAFA",
                    width: "100%",
                    height: "7vh",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    p: 1,
                  }}
                >
                  <Grid item xs zeroMinWidth>
                    <Typography fontSize={13} align="center">
                      Seasonality
                    </Typography>
                    <Typography noWrap fontSize={15} align="center">
                      {detail.seasonality_code || 0}
                    </Typography>
                  </Grid>
                </Box>
              </Grow>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grow
                in={checked === true}
                {...(checked ? { timeout: 1000 } : {})}
              >
                <Box
                  sx={{
                    backgroundColor:
                      detail.suggest_air % (detail.moq || 1) === 0 &&
                      detail.suggest_sea % (detail.moq || 1) === 0
                        ? "rgba(1,89,88,0.7)"
                        : "red",
                    color: "#FAFAFA",
                    width: "100%",
                    height: "7vh",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    p: 1,
                  }}
                >
                  <Grid item xs zeroMinWidth>
                    <Typography fontSize={13} align="center">
                      MOQ: {detail.moq || ""}
                    </Typography>
                  </Grid>
                </Box>
              </Grow>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grow
                in={checked === true}
                {...(checked ? { timeout: 1000 } : {})}
              >
                <Box
                  sx={{
                    backgroundColor: "rgba(1,89,88,0.6)",
                    color: "#FAFAFA",
                    width: "100%",
                    height: "7vh",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    p: 1,
                  }}
                >
                  <Grid item xs zeroMinWidth>
                    <Typography fontSize={12} align="center">
                      {detail.planner_code !== "P" &&
                      detail.layer &&
                      detail.layer > 0
                        ? "Layer: " + (detail.layer || "")
                        : ""}
                    </Typography>
                    <Typography fontSize={12} align="center">
                      {detail.planner_code !== "P" &&
                      detail.pallet &&
                      detail.pallet > 0
                        ? "Pallet: " + (detail.pallet || "")
                        : ""}
                    </Typography>
                  </Grid>
                </Box>
              </Grow>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grow
                in={checked === true}
                {...(checked ? { timeout: 1000 } : {})}
              >
                <Box
                  sx={{
                    backgroundColor: detail.planner_code === "P"
                      ? "rgba(1,89,88,0.6)"
                      : detail.suggest_sea % (detail.layer || 1) === 0
                      ? "rgba(1,89,88,0.6)"
                      : "orange",
                    color: "#FAFAFA",
                    width: "100%",
                    height: "7vh",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    p: 1,
                  }}
                >
                  <Grid item xs zeroMinWidth>
                    <Typography fontSize={13} align="center">
                      {detail.is_apply_growth
                        ? "G.Rate: Y"
                        : detail.planner_code === "P" &&
                          detail.suggest_sea !== 0 &&
                          !String(detail.status).includes("D") &&
                          !(
                            detail.alternate &&
                            detail.alternate.includes("[To]")
                          ) &&
                          growthRate !== 1.0
                        ? "G.Rate: "
                        : detail.layer && detail.layer <= 1
                        ? ""
                        : CalcLayerQty(detail.suggest_sea, detail.layer || 0) >
                          0
                        ? "Layer Sug.: " +
                          (CalcLayerQty(
                            detail.suggest_sea,
                            detail.layer || 0
                          ) || "")
                        : ""}
                    </Typography>
                  </Grid>
                </Box>
              </Grow>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={2.5}>
        <Grid item xs={12}>
          <Grow in={checked === true} {...(checked ? { timeout: 1000 } : {})}>
            <Box
              sx={{
                backgroundColor: "rgba(0,143,140,1)",
                color: "#FAFAFA",
                width: "100%",
                height: "14vh",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                p: 1,
              }}
            >
              <Grid item xs zeroMinWidth>
                <Typography fontSize={22} align="center">
                  Stock
                </Typography>
                <Typography
                  fontWeight="bold"
                  noWrap
                  fontSize={30}
                  align="center"
                >
                  {numberFormatToLocaleStr(detail.stock || 0, 1)}
                </Typography>
              </Grid>
            </Box>
          </Grow>
        </Grid>
        <Grid item xs={12}>
          <Grow in={checked === true} {...(checked ? { timeout: 1000 } : {})}>
            <Box
              sx={{
                backgroundColor: "rgba(0,143,140,0.8)",
                color: "#FAFAFA",
                width: "100%",
                height: "7vh",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                p: 1,
              }}
            >
              <Grid item xs zeroMinWidth>
                <Typography fontSize={13} align="center">
                  MTD Sales
                </Typography>
                <Typography noWrap fontSize={15} align="center">
                  {numberFormatToLocaleStr(detail.mtd_sales || 0, 1)}
                </Typography>
              </Grid>
            </Box>
          </Grow>
        </Grid>
        <Grid item xs={12}>
          <Grow in={checked === true} {...(checked ? { timeout: 1000 } : {})}>
            <Box
              sx={{
                backgroundColor: "rgba(0,143,140,0.6)",
                color: "#FAFAFA",
                width: "100%",
                height: "14vh",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                p: 1,
              }}
            >
              <Grid item xs zeroMinWidth>
                <Typography fontSize={13} align="center">
                  Sales Av
                </Typography>
                <Typography noWrap fontSize={15} align="center">
                  12M : {CalcAverage(detail.sales)}
                </Typography>
                <Typography noWrap fontSize={15} align="center">
                  6M : {CalcAverage(detail.sales?.slice(6))}
                </Typography>
                <Typography noWrap fontSize={15} align="center">
                  3M : {CalcAverage(detail.sales?.slice(9))}
                </Typography>
              </Grid>
            </Box>
          </Grow>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={2.5}>
        <Grid item xs={12}>
          <Grow in={checked === true} {...(checked ? { timeout: 1000 } : {})}>
            <Box
              sx={{
                backgroundColor:
                  (detail.back_order || 0) > 0 ? "#FF4858" : "rgba(12,171,168,1)",
                color: "#FAFAFA",
                width: "100%",
                height: "14vh",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                p: 1,
              }}
            >
              <Grid item xs zeroMinWidth>
                <Typography fontSize={22} align="center">
                  BO
                </Typography>
                <Typography
                  fontWeight="bold"
                  noWrap
                  fontSize={30}
                  align="center"
                >
                  {numberFormatToLocaleStr(detail.back_order || 0, 1)}
                </Typography>
              </Grid>
            </Box>
          </Grow>
        </Grid>
        <Grid item xs={12}>
          <Grow in={checked === true} {...(checked ? { timeout: 1000 } : {})}>
            <Box
              sx={{
                backgroundColor: "rgba(12,171,168,0.9)",
                color: "#FAFAFA",
                width: "100%",
                height: "7vh",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                p: 1,
              }}
            >
              <Tooltip title={detail.note1 || ""}>
                <Grid item xs zeroMinWidth>
                  <Typography fontSize={13} align="center">
                    Note1
                  </Typography>
                  <Typography noWrap fontSize={15} align="center">
                    {detail.note1 || ""}
                  </Typography>
                </Grid>
              </Tooltip>
            </Box>
          </Grow>
        </Grid>
        <Grid item xs={12}>
          <Grow in={checked === true} {...(checked ? { timeout: 1000 } : {})}>
            <Box
              sx={{
                backgroundColor: "rgba(12,171,168,0.8)",
                color: "#FAFAFA",
                width: "100%",
                height: "7vh",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                p: 1,
              }}
            >
              <Tooltip title={detail.note2 || ""}>
                <Grid item xs zeroMinWidth>
                  <Typography fontSize={13} align="center">
                    Note2
                  </Typography>
                  <Typography fontSize={15} align="center">
                    {detail.note2 || ""}
                  </Typography>
                </Grid>
              </Tooltip>
            </Box>
          </Grow>
        </Grid>
        <Grid item xs={12}>
          <Grow in={checked === true} {...(checked ? { timeout: 1000 } : {})}>
            <Box
              sx={{
                backgroundColor: "rgba(12,171,168,0.7)",
                color: "#FAFAFA",
                width: "100%",
                height: "7vh",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                p: 1,
              }}
            >
              <Tooltip title={detail.note3 || ""}>
                <Grid item xs zeroMinWidth>
                  <Typography fontSize={13} align="center">
                    Note3
                  </Typography>
                  <Typography fontSize={15} align="center">
                    {detail.note3 || ""}
                  </Typography>
                </Grid>
              </Tooltip>
            </Box>
          </Grow>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default ItemInformation;
