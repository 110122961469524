const ColorGradation = (steps: number): string[] => {
  if (steps === 0) {
    return [];
  }
  // makita blue          rgb(0, 140, 162)
  const MAKITA_BLUE = { red: 0, green: 140, blue: 162 };
  // complementary color  rgb(162, 22, 0)
  const COMP_COLOR = { red: 166, green: 0, blue: 255 };

  const diff = {
    red: (MAKITA_BLUE.red - COMP_COLOR.red) / steps,
    green: (MAKITA_BLUE.green - COMP_COLOR.green) / steps,
    blue: (MAKITA_BLUE.blue - COMP_COLOR.blue) / steps,
  };
  return [...Array(steps)].map(
    (_, i) =>
      `rgba(
        ${(MAKITA_BLUE.red - diff.red * i).toFixed(0)},
        ${(MAKITA_BLUE.green - diff.green * i).toFixed(0)},
        ${(MAKITA_BLUE.blue - diff.blue * i).toFixed(0)}, 0.8)`
  );
};
export default ColorGradation;
