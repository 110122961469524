import { TableCell, TableRow } from "@mui/material";
import React, { VFC } from "react";

interface Props {
  rowId: number;
  rowHeader: string;
  values: number[];
  ltm_air?: number;
  ltm_sea?: number;
  isOnlySea?: boolean;
}

const ForecastTableRow: VFC<Props> = (props) => {
  return (
    <TableRow>
      <TableCell
        width="55"
        padding="none"
        size="small"
        component="th"
        scope="row"
      >
        {props.rowHeader}
      </TableCell>
      {props.values.map((v, i) => (
        <TableCell
          padding="none"
          size="small"
          align="center"
          key={`${props.rowHeader}-${props.rowId}-${i}`}
          sx={{
            bgcolor:
              i === props.ltm_air
                ? props.isOnlySea
                  ? ""
                  : "rgba(0,0,200,0.1)"
                : i === props.ltm_sea
                ? "rgba(0,200,0,0.12)"
                : "",
            fontWeight: props.isOnlySea
              ? i === props.ltm_sea
                ? "bold"
                : ""
              : i === props.ltm_air || i === props.ltm_sea
              ? "bold"
              : "",
          }}
        >
          {v ? v.toLocaleString() : 0}
        </TableCell>
      ))}
    </TableRow>
  );
};

export default ForecastTableRow;
