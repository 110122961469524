import { Box, Button, FormControl, Stack, TextField } from "@mui/material";
import { memo, useEffect, useState, FC } from "react";
import { FieldError, SubmitHandler, useForm, Resolver } from "react-hook-form";
import { useRecoilState } from "recoil";
import { useApiPut } from "../../../hooks/api/useApiPut";
import { useApiPost } from "../../../hooks/api/useApiPost";
import { SnackbarState } from "../../../stores/SnackbarState";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

  type alternate = Paths.GetAlternates.Responses.$200["alternates"][0];

interface Props {
    alternate: alternate | undefined;
    mode?: "update" | "create";
    alternates?: Paths.GetAlternates.Responses.$200["alternates"];
    handleRefresh: () => void;
  }

const AlternateForm: FC<Props> = memo((props) => {
  const [alternate, setAlternate] = useState<alternate>();
  const schema = yup
    .object({
      from_item: yup.string().required(),
      from_qty: yup.number().required(),
      to_item: yup.string().required(),
      to_qty: yup.number().required(),
    })
    .test("from_qty_range_test", "", (obj) => {
      if (1 <= obj.from_qty && obj.from_qty <= 9) {
        return true;
      }
      return new yup.ValidationError("Please enter a number between 1 and 9", null, "from_qty");
    })
    .test("to_qty_range_test", "", (obj) => {
      if (1 <= obj.to_qty && obj.to_qty <= 9) {
        return true;
      }
      return new yup.ValidationError("Please enter a number between 1 and 9", null, "to_qty");
    })
    .test("same_item_test", "", (obj) => {
      if (mode === "create") {
        if (obj.from_item !== obj.to_item) {
          return true;
        }
        return new yup.ValidationError("The same item is entered in both From Item and To Item", null, "to_item");
      }
      return true;
    })
    .test("item_duplicated_test", "", (obj) => {
      if (mode === "create") {
        if (!props.alternates?.map((m) => m.from_item + m.to_item).includes(obj.from_item + obj.to_item)) {
          return true;
        }
        return new yup.ValidationError("Alternate Items is duplicated", null, "to_item");
      }
      return true;
    })
    .test("qty_duplicated_test", "", (obj) => {
      if (mode === "update") {
        const record = props.alternates?.find((f) => f.from_item + f.to_item === obj.from_item + obj.to_item)
        if (record?.from_qty !== obj.from_qty || record?.to_qty !== obj.to_qty) {
          return true;
        } else if (record?.from_qty === obj.from_qty) {
          return new yup.ValidationError("Alternate Items Qty is duplicated", null, "from_qty");
        } else if (record?.to_qty === obj.to_qty) {
          return new yup.ValidationError("Alternate Items Qty is duplicated", null, "to_qty");
        }
      }
      return true;
    });
  const { register, handleSubmit, reset, formState: { errors, isValid } } = useForm<alternate>({
    resolver: yupResolver(schema) as unknown as Resolver<alternate, object>,
    mode: "onChange",
    defaultValues: alternate,
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSnackbar] = useRecoilState(SnackbarState);
  const mode = props.mode || "update";
  const postRequestAsync =
    useApiPost<Paths.CreateAlternate.Responses.$200>("alternate");
  const putRequestAsync =
    useApiPut<Paths.UpdateAlternate.Responses.$200>("alternate", String(alternate?.id));
  const isSelected = props.alternate?.created !== "";
  const isUpdateMode = mode === "update";

  const onSubmit: SubmitHandler<alternate> = (data) => {
    if (mode === "create") {
      const body: Paths.CreateAlternate.Parameters.CreateAlternate = {
        from_item: data.from_item,
        from_qty: data.from_qty,
        to_item: data.to_item,
        to_qty: data.to_qty,
      };
      postRequestAsync({ body })
        .then((res) => {
          setSnackbar({
            open: true,
            message: res.message,
            severity: res.code === 200 ? "success" : "error",
          });
          props.handleRefresh();
          setAlternate({ id: 0, from_item: "", from_qty: 1, to_item: "", to_qty: 1,created: "" });
        })
        .catch(() => {
          setSnackbar({
            open: true,
            severity: "error",
            message: "[ERROR]Alternate for item is not created.",
          });
        });
    } else {
      const body: Paths.UpdateAlternate.Parameters.UpdateAlternate = {
        from_item: data.from_item,
        from_qty: data.from_qty,
        to_item: data.to_item,
        to_qty: data.to_qty,
      };
      putRequestAsync({ body })
        .then((res) => {
          setSnackbar({
            open: true,
            message: res.message,
            severity: res.code === 200 ? "success" : "error",
          });
          props.handleRefresh();
        })
        .catch(() => {
          setSnackbar({
            open: true,
            severity: "error",
            message: "[ERROR]Alternate for item is not updated.",
          });
        });
    }
  };
  useEffect(() => {
    setAlternate(props.alternate);
    reset(props.alternate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.alternate]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      padding={8}
      boxShadow={1}
      borderRadius={1}
      bgcolor="paper"
    >
      <Stack spacing={3}>
        <FormControl>
          <TextField
            required
            type="text"
            disabled={isUpdateMode}
            label="From Item"
            error={"from_item" in errors}
            helperText={(errors["from_item"] as FieldError)?.message}
            {...register("from_item")}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <FormControl>
          <TextField
            required
            type="number"
            disabled={isUpdateMode && !isSelected}
            label="From Qty"
            error={"from_qty" in errors}
            helperText={(errors["from_qty"] as FieldError)?.message}
            {...register("from_qty")}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ step: "1", min: 1}}
          />
        </FormControl>

        <FormControl>
          <TextField
            required
            type="text"
            disabled={isUpdateMode}
            label="To Item"
            error={"to_item" in errors}
            helperText={(errors["to_item"] as FieldError)?.message}
            {...register("to_item")}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <FormControl>
          <TextField
            required
            type="number"
            disabled={isUpdateMode && !isSelected}
            label="To Qty"
            error={"to_qty" in errors}
            helperText={(errors["to_qty"] as FieldError)?.message}
            {...register("to_qty")}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ step: "1", min: 1}}
          />
        </FormControl>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 2, mb: 2 }}
          disabled={!isValid}
        >
          {mode === "update" ? "Update" : "Create"}
        </Button>
      </Stack>
    </Box>
  );
});

export default AlternateForm;