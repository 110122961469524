import { Box, Button, FormControl, Stack, TextField } from "@mui/material";
import { memo, useEffect, useState, FC } from "react";
import { FieldError, SubmitHandler, useForm, Resolver } from "react-hook-form";
import { useRecoilState } from "recoil";
import { useApiPut } from "../../../hooks/api/useApiPut";
import { useApiPost } from "../../../hooks/api/useApiPost";
import { SnackbarState } from "../../../stores/SnackbarState";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

  type weight = Paths.GetWeights.Responses.$200["weights"][0];

interface Props {
    weight: weight | undefined;
    mode?: "update" | "create";
    weights?: Paths.GetWeights.Responses.$200["weights"];
    handleRefresh: () => void;
  }

const WeightForm: FC<Props> = memo((props) => {
  const [weight, setWeight] = useState<weight>();
  const validateWeight = function (this: any) {
    if (props.mode === "update") {
      const { weight } = this.parent;
      return weight !== props.weight?.weight;
    }
    return true;
  };
  const validateItem = function (this: any) {
    if (props.mode === "create") {
      const { item } = this.parent;
      const record = props.weights?.find((f) => f.item === item)
      return record === undefined;
    }
    return true;
  };
  const schema = yup.object().shape(
    {
      weight: yup.number().required("This field is required.")
      .test(
        "weight_validation",
        "",
        validateWeight
      ),
      item: yup.string().required("This field is required.")
      .test(
        "item_validation",
        "The item you entered already exists.",
        validateItem
      )
    }
  );
  const { register, handleSubmit, reset, formState: { errors, isValid } } = useForm<weight>({
    resolver: yupResolver(schema) as unknown as Resolver<weight, object>,
    mode: "onChange",
    defaultValues: weight,
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSnackbar] = useRecoilState(SnackbarState);
  const mode = props.mode || "update";
  const postRequestAsync =
    useApiPost<Paths.CreateWeight.Responses.$200>("weight");
  const putRequestAsync =
    useApiPut<Paths.UpdateWeight.Responses.$200>("weight", String(weight?.id));
  const isSelected = props.weight?.created !== "";
  const isUpdateMode = mode === "update";

  const onSubmit: SubmitHandler<weight> = (data) => {
    if (mode === "create") {
      const body: Paths.CreateWeight.Parameters.CreateWeight = {
        item: data.item,
        weight: Number(data.weight),
      };
      postRequestAsync({ body })
        .then((res) => {
          setSnackbar({
            open: true,
            message: res.message,
            severity: res.code === 200 ? "success" : "error",
          });
          props.handleRefresh();
          setWeight({ id: 0, item: "", weight: 0.0000, created: "" });
        })
        .catch(() => {
          setSnackbar({
            open: true,
            severity: "error",
            message: "[ERROR]Weight for item is not created.",
          });
        });
    } else {
      const body: Paths.UpdateWeight.Parameters.UpdateWeight = {
        weight: Number(data.weight),
      };
      putRequestAsync({ body })
        .then((res) => {
          setSnackbar({
            open: true,
            message: res.message,
            severity: res.code === 200 ? "success" : "error",
          });
          props.handleRefresh();
        })
        .catch(() => {
          setSnackbar({
            open: true,
            severity: "error",
            message: "[ERROR]Weight for item is not updated.",
          });
        });
    }
  };
  useEffect(() => {
    setWeight(props.weight);
    reset(props.weight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.weight]);

  const handleChangeWeight = (value:string) => {
    setWeight({ ...props.weight!, weight: Number(value) ? Number(value) : 0.0 })
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      padding={8}
      boxShadow={1}
      borderRadius={1}
      bgcolor="paper"
    >
      <Stack spacing={3}>
        <FormControl>
          <TextField
            required
            type="text"
            disabled={isUpdateMode}
            label="Item"
            error={"item" in errors}
            helperText={(errors["item"] as FieldError)?.message}
            {...register("item")}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>

        <FormControl>
          <TextField
            required
            label="Weight"
            {...register("weight")}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={isUpdateMode && !isSelected}
            type="number"
            inputProps={{ step: "0.00001", min: 0}}
            onChange={(e) => handleChangeWeight(e.target.value)}
          />
        </FormControl>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 2, mb: 2 }}
          disabled={!isValid}
        >
          {mode === "update" ? "Update" : "Create"}
        </Button>
      </Stack>
    </Box>
  );
});

export default WeightForm;