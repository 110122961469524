import { MenuItem, Select,SelectChangeEvent } from "@mui/material";
import { GridApi } from "@mui/x-data-grid-pro";
import React, { memo, useCallback, VFC } from "react";

interface Props {
  id: number;
  api: GridApi;
  field: string;
  value: any;
}

const SelectFlag: VFC<Props> = memo((props) => {
  const { id, api, field, value } = props;
  const flags = ["A", "B", "C", "D", "E", ""];
  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      api.setEditCellValue({ id, field, value: event.target.value });
      api.stopCellEditMode({ id, field });
      event.stopPropagation();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [api, field, id]
  );
  return (
    <Select value={value || ""} onChange={handleChange}>
      {flags.map((v) => (
        <MenuItem key={v} value={v}>
          {v}
        </MenuItem>
      ))}
    </Select>
  );
});
export function RenderSelectFlagEditCell(params: any) {
  return <SelectFlag {...params} />;
}
