const CalcLayerQty = (suggestQty: number, layer: number): number => {
  const layerSuggestQty = suggestQty / layer;
  if (suggestQty <= 0) {
    return 0;
  } else if (suggestQty < layer) {
    return layer;
  } else {
    return suggestQty % (layer || 1) === 0
      ? suggestQty
      : Math.round(layerSuggestQty) * layer;
  }
};

export default CalcLayerQty;
