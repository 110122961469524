import ExcelJS from "exceljs";

const convertCsvToExcel = async(file:File): Promise<File> => {
 const rows = await getRowsFromCsvAsync(await file.text());
 const wb = new ExcelJS.Workbook();
 const ws = wb.addWorksheet('Sheet1');
 rows.forEach(row => ws.addRow(row));
 const blob =  new Blob([await wb.xlsx.writeBuffer()], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
 return new File([blob], file.name.replace('.csv','.xlsx'), {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
};

const getRowsFromCsvAsync = async(data:string): Promise<string[][]> =>{
  return await data.split('\r\n').map((row) => row.split(','))
};

export default convertCsvToExcel;
