import { SignUpParams } from "@aws-amplify/auth";
import { Auth } from "aws-amplify";
import { SignUpResult } from "../../models/user_model";

interface req extends SignUpParams {
  attributes: {
    email: string;
    given_name: string;
    family_name: string;
  };
}

const SignUpAsync = async (req: req): Promise<SignUpResult> => {
  return await Auth.signUp(req);
};

export default SignUpAsync;
