import { API } from "aws-amplify";
import { ApiRequest } from "../../models/util/api_request_model";

type request = Omit<ApiRequest, "swrOption">;

const API_NAME = "apie413e623";
const BASE_PATH = "makmate";

export const useApiGetMakmateAsync = <T>(path: "documents") => {
  const fetchAsync = async (
    request: request,
    pathParameters?: string
  ): Promise<T> => {
    const url = pathParameters
      ? `/${BASE_PATH}/${path}/${pathParameters}`
      : `/${BASE_PATH}/${path}`;
    return API.get(API_NAME, url, request);
  };
  return fetchAsync;
};
