import { Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useApiPost } from "../../../hooks/api/useApiPost";
import { SnackbarState } from "../../../stores/SnackbarState";
import { useSWRConfig } from "swr";
import UploadFileButton from "../UploadFileButton";

const UploadAlternate = () => {
  const [alternateDataKey, setAlternateDataKey] = useState("");
  const [snackbar, setSnackbar] = useRecoilState(SnackbarState);
  const { mutate } = useSWRConfig();
  const postRequestAsync =
    useApiPost<Paths.CreateAlternates.Responses.$200>("alternates");
  useEffect(() => {
    if (alternateDataKey.length > 0) {
      const body: Paths.CreateAlternates.Parameters.CreateAlternatesRequest = {
        master_data_key: alternateDataKey,
      };
      postRequestAsync({ body }).then((r) => {
        setSnackbar({
          ...snackbar,
          open: true,
          message: r.message,
          severity: r.code === 200 ? "success" : "error",
        });
        mutate("/stm/alternates");
        setAlternateDataKey("");
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alternateDataKey]);
  return (
    <Grid
      container
      sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
      mb={2}
    >
      <Grid item xs={12} md={4} pl={2}>
        <TextField
          fullWidth
          id="alternate_data_key"
          label="Choose Alternate File"
          variant="standard"
          value={alternateDataKey.replace(/^.*[\\/]/, "")}
        />
      </Grid>
      <Grid item xs={10} md={1}>
        <UploadFileButton
          message="Browse"
          setKey={setAlternateDataKey}
          id={"alternate_upload"}
        />
      </Grid>
    </Grid>
  );
};

export default UploadAlternate;
