import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { FC } from "react";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { useMutateDetail } from "../../../hooks/api/mutate/useMutateDetail";
import { useRecoilState } from "recoil";
import { UserState } from "../../../stores/UserState";
import HasUpdateAuthority from "../../../functions/HasUpdateAuthority";
import { useApiPost } from "../../../hooks/api/useApiPost";
import { SnackbarState } from "../../../stores/SnackbarState";
import DownloadWithPresignUrl from "../../../functions/DownloadWithPresignUrl";
import * as yup from "yup";
import { useForm, Controller, SubmitHandler, Resolver, FieldError } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

type request = Paths.CreatePO.Parameters.PoDetail;
interface Props {
  headerId: string;
  type: "AIR" | "SEA";
  vendors: string[];
  icon: React.ReactNode;
  setLoading: (v: boolean) => void;
}

const DownloadPOForm: FC<Props> = ({
  headerId,
  type,
  vendors,
  icon,
  setLoading,
}) => {
  const mutate = useMutateDetail(headerId);
  const [user] = useRecoilState(UserState);
  const hasUpdateAuth = HasUpdateAuthority(user?.is_user_update, user?.company_code);
  const schema = yup
    .object({
      vendor: yup.string().required(),
      po_name: yup.string(),
    })
    .test("po_name_validation", "", (obj) => {
      if(obj.po_name?.length === 0) {
        return false;
      }
      if (obj?.po_name !== undefined && obj.po_name.length <= 15) {
        return true;
      }
      return new yup.ValidationError("PO No. should be in 15 words or less", null, "po_name");
    });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSnackbar] = useRecoilState(SnackbarState);
  const postRequestAsync = useApiPost<Paths.CreatePO.Responses.$200>(
    "create_po",
    headerId
  );
  const onClickDownload: SubmitHandler<request> = React.useCallback(
    async (data) => {
      const body: Paths.CreatePO.Parameters.PoDetail = {
        vendor: data.vendor,
        po_type: type,
        po_name: data.po_name,
      };
      setSnackbar({ open: false, message: "" });
      setLoading(true);
      postRequestAsync({ body })
        .then((res) => {
          DownloadWithPresignUrl(res.presigned_url, `${data.po_name}.csv`);
          setSnackbar({
            open: true,
            message: "PO is issued!",
            severity: "success",
          });
        })
        .catch((err) => {
          setSnackbar({
            open: true,
            message: "cannot create purchase order.",
            severity: "error",
          });
          return;
        })
        .finally(() => {
          setLoading(false);
          reset();
          mutate();
        });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [headerId]
    );

  const { register, reset, handleSubmit, control, formState: { errors, isValid } } = useForm<request>({
    resolver: yupResolver(schema) as unknown as Resolver<request, object>,
    defaultValues: { vendor: "", po_name: "" },
    mode: "onChange",
  });
  return (
    <Grid container>
      <Grid item xs={12} md={12} pt={2} pl={2}>
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Typography>{type}</Typography>
          {icon}
        </Grid>
      </Grid>
      <Grid container item xs={12} md={12} pl={2} onSubmit={handleSubmit(onClickDownload)}>
        <Grid item xs={4} md={4}>
          <FormControl variant="standard" sx={{ minWidth: 80 }}>
            <InputLabel id={`label-id-${type.toLowerCase()}`}>Vendor</InputLabel>
            <Controller
              name="vendor"
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, onChange, value } }) => (
                <Select
                  labelId={`label-id-${type.toLowerCase()}`}
                  {...register("vendor")}
                  inputRef={ref}
                  value={value}
                  onChange={onChange}
                  label="Vendor"
                  id={"vendor"}
                  disabled={hasUpdateAuth}
                  error={"vendor" in errors}
                  defaultValue={""}
                >
                  {vendors.map((v) => (
                    <MenuItem key={v} value={v}>
                      {v}
                    </MenuItem>
                  ))}
                </Select>
             )}
             />
          </FormControl>
        </Grid>
        <Grid item xs={8} md={8}>
          <FormControl variant="standard">
            <TextField
              label={`PO No. for ${type}`}
              disabled={hasUpdateAuth}
              id={"po_name"}
              required
              {...register("po_name")}
              defaultValue=''
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="large"
                      disabled={!isValid}
                      color="primary"
                      onClick={handleSubmit(onClickDownload)}
                    >
                      <CloudDownloadIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              variant="standard"
              fullWidth
              error={"po_name" in errors}
              helperText={(errors["po_name"] as FieldError)?.message}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DownloadPOForm;
