import { Fab, Grid } from "@mui/material";
import { useMemo, useState } from "react";
import { useApiGetWithCache } from "../../hooks/api/useApiGetWithCache";
import LoadingBackdrop from "../molecules/LoadingBackdrop";
import UserForm from "../organisms/UserMaintenance/UserForm";
import UserList from "../organisms/UserMaintenance/UserList";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { useSWRConfig } from "swr";

const UserMaintenance = () => {
  const { data, loading } =
    useApiGetWithCache<Paths.GetMasterdata.Responses.$200>(
      "stm",
      "master_data",
      {}
    );

  const [targetUserSub, setTargetUserSub] = useState("");
  const [mode, setMode] = useState<"update" | "create">("update");
  const { mutate } = useSWRConfig();
  const targetUser = useMemo(
    () =>
      data?.users.find((f) => f.sub === targetUserSub) || {
        sub: "",
        given_name: "",
        family_name: "",
        email: "",
        company_code: "",
        primary_region: "",
        regions: [] as string[],
        is_user_update: false,
      },
    [data, targetUserSub]
  );
  const onClickRow = (sub: string) => {
    setTargetUserSub(sub);
    setMode("update");
  };
  const onClickCreate = () => {
    setTargetUserSub("");
    setMode("create");
  };
  const handleRefresh = () => {
    mutate("/stm/user");
    mutate("/stm/master_data");
  };
  return loading || !data ? (
    <LoadingBackdrop loading={loading} />
  ) : (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={7} minHeight={800}>
          <UserList
            users={data!.users}
            onClickRow={onClickRow}
            handleRefresh={handleRefresh}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <UserForm
            user={targetUser}
            mode={mode}
            companies={data!.companies}
            handleRefresh={handleRefresh}
          />
        </Grid>
      </Grid>
      <Fab
        color="primary"
        aria-label="new"
        sx={{
          position: "fixed",
          bottom: "96px",
          right: "16px",
        }}
        onClick={() => onClickCreate()}
      >
        <PersonAddAltIcon />
      </Fab>
    </>
  );
};

export default UserMaintenance;
