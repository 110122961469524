import { CalcRoundByMoq } from "../functions/CalcRoundByMoq";

const ForecastMonthLength = 15;

function calcStandardStock(slice: number[]): number {
  if (slice.length === 0) {
    return 1; // return 1 because standard stock is used as dividing value.
  }
  let total = 0;
  for (let v of slice) {
    total += v;
  }
  let moving_average = Math.round((total / slice.length) * 10) / 10;
  return moving_average;
}

const getSalesList = (
  seasonalityCode: number,
  salesHistory: number[],
  salesForecast: number[],
  bo: number
): number[] => {
  const salesListStartIndex = [3, 6, 12].includes(seasonalityCode)
    ? 12 - seasonalityCode + 1
    : 0;

  var sliceSalesHistory = salesHistory.slice(salesListStartIndex);
  sliceSalesHistory[sliceSalesHistory.length - 1] += bo;

  // 過去と未来を結合
  return [...sliceSalesHistory, ...salesForecast];
};

function getStandardStockList(
  seasonalityCode: number,
  salesList: number[]
): number[] {
  let standardStock: number[] = new Array(ForecastMonthLength);
  for (let i = 0; i < standardStock.length; i++) {
    if (i === 0) {
      standardStock[i] = calcStandardStock(salesList.slice(0, seasonalityCode));
    } else if (i === 14) {
      standardStock[i] = calcStandardStock(salesList.slice(i));
    } else {
      standardStock[i] = calcStandardStock(
        salesList.slice(i, i + seasonalityCode)
      );
    }
  }

  return standardStock;
}

const getStockForecast = (
  mtdSales: number,
  stock: number,
  bo: number,
  salesForecast: number[],
  purchaseForecast: number[],
  ltmAIR: number,
  ltmSEA: number,
  suggestAIR: number,
  suggestSEA: number
) => {
  let stockForecast: number[] = new Array(ForecastMonthLength);
  for (let i = 0; i < salesForecast.length; i++) {
    const stockPreForecast =
      stockForecast[i - 1] - salesForecast[i] + purchaseForecast[i];
    switch (i) {
      case 0:
        stockForecast[i] =
          mtdSales + stock - bo - salesForecast[i] + purchaseForecast[i];
        break;
      case ltmAIR:
        stockForecast[i] = stockPreForecast + suggestAIR;
        break;
      case ltmSEA:
        stockForecast[i] = stockPreForecast + suggestSEA;
        break;
      default:
        stockForecast[i] = stockPreForecast;
        break;
    }
  }

  return stockForecast;
};

type CalcForecastType = {
  // 画面上で腑可能
  isOnlySea: boolean;
  bo: number;
  mtdSales: number;
  stock: number;
  salesHistory: number[];
  ltmAIR: number;
  ltmSEA: number;
  msmSea: number;
  moq: number;
  ocfForecast: number[];
  isDiscon: boolean;
  layer: number;
  seasonalityCode: number;
  // 画面上で更新可能
  suggestAIR: number;
  suggestSEA: number;
  purchase: number[];
  sales: number[];
};

const CalcForecast = (
  // 画面上で腑可能
  {
    isOnlySea,
    bo,
    mtdSales,
    stock,
    salesHistory,
    ltmAIR,
    ltmSEA,
    msmSea,
    moq,
    ocfForecast,
    isDiscon,
    layer,
    seasonalityCode,
    // 画面上で更新可能
    suggestAIR,
    suggestSEA,
    purchase,
    sales,
  }: CalcForecastType
) => {
  // 何か月分コラム表示するか
  const monthLengthAfterLtmSEA = ForecastMonthLength - ltmSEA;

  // standard stock 目標とする在庫数量
  const salesList = getSalesList(seasonalityCode, salesHistory, sales, bo);
  const standardStocks = getStandardStockList(seasonalityCode, salesList);

  // ltmSeaより前の値を保持する(ltmSea以降は仮の値を登録する)
  const stockForecasts = getStockForecast(
    mtdSales,
    stock,
    bo,
    sales,
    purchase,
    ltmAIR,
    ltmSEA,
    suggestAIR,
    suggestSEA
  );

  // DMB求める計算式
  const dmbForecasts = standardStocks?.map((salesAv) =>
    Math.round(salesAv * msmSea)
  );
  const additional: number[] = Array(ForecastMonthLength).fill(0);
  additional[ltmAIR] = isOnlySea ? 0 : suggestAIR;
  additional[ltmSEA] = suggestSEA;

  for (let i = 1; i < monthLengthAfterLtmSEA; i++) {
    var ocfIndex = i - 1;
    const ocf = ocfForecast[ocfIndex] ?? -1;

    var afterLtmSeaIndex = ltmSEA + i;
    const res =
      dmbForecasts[afterLtmSeaIndex] +
      sales[afterLtmSeaIndex] -
      purchase[afterLtmSeaIndex] -
      stockForecasts[afterLtmSeaIndex - 1];

    var resultByMoq = CalcRoundByMoq(res, moq); // suggest qtyはMOQで丸める

    additional[afterLtmSeaIndex] =
      isDiscon && ocf < 0 // DisconかつOCFに設定がない場合forecastのltmMonth以降は0にする
        ? 0
        : ocf >= 0
        ? ocf
        : resultByMoq;

    // StockForecastを再計算する
    for (let i = 0; i < ForecastMonthLength; i++) {
      if (i === 0) {
        stockForecasts[i] =
          stock - bo + mtdSales - sales[i] + purchase[i] + additional[i];
      } else {
        stockForecasts[i] =
          stockForecasts[i - 1] - sales[i] + purchase[i] + additional[i];
      }
    }
  }

  const month: number[] = stockForecasts.map((stockForecast, i) => {
    var roundMovingAv = standardStocks[i];
    roundMovingAv = roundMovingAv > 0 ? roundMovingAv : 1;
    return Math.round((stockForecast * 10) / roundMovingAv) / 10; // 小数点1桁表示
  });

  return {
    stock: stockForecasts,
    additionalPurchase: additional,
    month: month,
  };
};

export default CalcForecast;
