import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { memo, useMemo, VFC } from "react";
import UserAvatar from "../UserAvatar";

interface Props {
  logs: Paths.GetSuggestQtyLogs.Responses.$200 | undefined;
  type: "AIR" | "SEA";
}

const numberFormat = (num: number) => {
  var v = num || 0;
  return Number(v.toFixed(1)).toLocaleString();
};

const SuggestQtyLogs: VFC<Props> = memo(({ logs, type }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = useMemo(() => (type === "AIR" ? logs?.air : logs?.sea), [logs]);
  return logs ? (
    <List
      sx={{
        width: "100%",
        maxWidth: 360,
        // bgcolor: "background.paper",
        position: "relative",
        overflow: "auto",
        maxHeight: 180,
        "& ul": { padding: 0 },
      }}
    >
      {data?.length === 0 ? (
        <ListItem alignItems="flex-start">
          <ListItemText primary={"Not changed."}></ListItemText>
        </ListItem>
      ) : (
        data?.map((sq, i) => (
          <Box key={i}>
            <ListItem alignItems="flex-start" sx={{ paddingTop: 0, paddingBottom: 0, }}>
              <ListItemAvatar>
                <UserAvatar
                  userSub={sq.create.user.sub}
                  userName={sq.create.user.name}
                  size={40}
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "noWrap",
                    }}
                  >
                    <Typography paddingLeft={1} noWrap color={"black"}>
                      {`${numberFormat(sq.before_qty)} → ${numberFormat(
                        sq.after_qty
                      )}`}
                    </Typography>
                  </Grid>
                }
                secondary={
                  <Typography variant="caption" display="block" color={"gray"}>
                    {new Date(sq.create.date).toLocaleDateString(
                      navigator.language,
                      {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      }
                    )}
                  </Typography>
                }
              />
            </ListItem>
            <Divider variant="inset" component="li" />
          </Box>
        ))
      )}
    </List>
  ) : (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress color="primary" />
    </Box>
  );
});

export default SuggestQtyLogs;
