import { memo, VFC } from "react";
import { Route, Routes } from "react-router-dom";
import { HeaderLayout } from "../components/templates/HeaderLayout";
import { privateRoutes } from "./PrivateRouter";

export const Router: VFC = memo(() => {
  return (
    <Routes>
      {privateRoutes.map((route) => (
        <Route
          key={route.path}
          path={`${route.path}`}
          element={<HeaderLayout>{route.children}</HeaderLayout>}
        />
      ))}
      <Route path="*" element={<div>error</div>} />
    </Routes>
  );
});
