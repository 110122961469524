import { Fab, Grid } from "@mui/material";
import { useMemo, useState } from "react";
import { useApiGetWithCache } from "../../hooks/api/useApiGetWithCache";
import { useSWRConfig } from "swr";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import UploadWeight from "../organisms/WeightMaintenance/UploadWeight";
import WeightTable from "../organisms/WeightMaintenance/WeightTable";
import WeightForm from "../organisms/WeightMaintenance/WeightForm";

const WeightMaintenance = () => {
  const [weightId, setWeightId] = useState(0);
  const { data, loading } = useApiGetWithCache<Paths.GetWeights.Responses.$200>(
    "stm",
    "weights",
    { swrOption: { refreshInterval: 4000 } }
  );
  const targetWeight = useMemo(
    () =>
      data?.weights?.find((f) => f.id === weightId) || {
        id: 0,
        item: "",
        weight: 0.0000,
        created: "",
      },
    [data, weightId]
  );
  const [mode, setMode] = useState<"update" | "create">("update");
  const { mutate } = useSWRConfig();
  const onClickRow = (id: number) => {
    setWeightId(id);
    setMode("update");
  };
  const onClickCreate = () => {
    setWeightId(0);
    setMode("create");
  };
  const handleRefresh = () => {
    mutate("/stm/weights");
  };
  return (
    <>
      <UploadWeight />
      <Grid container spacing={1}>
        <Grid item xs={12} md={7} maxHeight={800}>
          <WeightTable weights={data?.weights} loading={loading} onClickRow={onClickRow} handleRefresh={handleRefresh}/>
        </Grid>
        <Grid item xs={12} md={5}>
          <WeightForm mode={mode} weight={targetWeight} weights={data?.weights} handleRefresh={handleRefresh}/>
        </Grid>
      </Grid>
      <Fab
        color="primary"
        aria-label="new"
        sx={{
          position: "fixed",
          bottom: "96px",
          right: "16px",
        }}
        onClick={() => onClickCreate()}
      >
        <AddBusinessIcon />
      </Fab>
    </>
  );
};

export default WeightMaintenance;
